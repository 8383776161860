import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';

import NavBar from "../NavBar/NavBar";
import filterAdd from "../../images/icons/filter-add.png";
import userI from "../../images/icons/user.png";
import rolesI from "../../images/icons/roles.png";
import logs from "../../images/icons/logs.png"
import { AiOutlineSearch } from "react-icons/ai";

import "./Events.scss"
import { Link } from "react-router-dom";
import EventRows from "./EventsRows";
import axios from "axios";


const Events = () => {
    document.title = "Events - POWERGrid Cloud"

    const [events, setEvents] = useState([])
    const [pageCount, setPageCount] = useState(1)
    const [currentPage, setCurrentPage] = useState(0)
    const [isFetching, setIsFetching] = useState(false)
    const [totalDataCount, setTotalDataCount] = useState()
    const [orderWay, setOrderWay] = useState("desc")
    const [displaySize, setDisplaySize] = useState(20)
    const [searchValue, setSearchValue] = useState("")
    const [isNotFound, setIfNotFound] = useState(false)

    let cancelToken;

    if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Cancelling the previous req")
    }

    cancelToken = axios.CancelToken.source()

    useEffect(() => {
        setIsFetching(true)
        /*  http://116.203.191.150:3000/get_events?order=Timestamp.${orderWay}&limit=${displaySize}&offset=${currentPage * displaySize}&${searchValue && `ID=ilike.${searchValue}`} */
        axios.get(`http://116.203.191.150:3000/get_events?limit=${displaySize}&order=timestamp.${orderWay}&limit=${displaySize}&offset=${currentPage * displaySize}&${searchValue && `ID=ilike.*${searchValue}`}`,
            {
                headers: {
                    "Prefer": "count=exact"
                }
            },
            {
                cancelToken: cancelToken.token
            }
        )
            .then(response => {
                if (response.headers) {
                    try {
                        let totalItems = response?.headers['content-range']?.split("-")[1]
                        totalItems = totalItems.split("/")[1]
                        setPageCount(Math.ceil(totalItems / displaySize))
                        setTotalDataCount(totalItems)
                    } catch (error) {
                        console.log(error)
                    }

                }
                const events = response.data
                if (!events.length) {
                    setIfNotFound(true)
                } else {
                    setIfNotFound(false)
                }

                if (events) {
                    events.forEach(element => {
                        element.selected = false
                    });
                    setEvents(events)
                }
            })
            .catch(error => {
                if (currentPage !== 0) {
                    setCurrentPage(0)
                }
                console.error("error found", error)
            })
            .then(() => {
                setIsFetching(false)
            })
    }, [currentPage, orderWay, displaySize, searchValue])


    const onOrderChange = () => {
        if (orderWay === "desc") {
            setOrderWay("asc")
        } else {
            setOrderWay("desc")
        }
    }

    return (
        <>
        <NavBar />
        <main className="wrapper">
            <div className="page-content">
                <div className="wallbox-area">
                    <div className="pages-title">
                        <div className="h2">All Events</div>
                        <span>View all events in one table</span>
                    </div>
                    <div className="user__content">
                        <div className="user__data">
                            <ul className="panel_section">
                                <li>
                                    <span>Panel Selection</span>
                                </li>
                                <li>
                                    <Link to="/events" className="active"><img alt="" src={userI} /> Events</Link>
                                </li>
                                <li>
                                    <Link to="/powerbrain" ><img alt="" src={rolesI} /> POWERBrain</Link>
                                </li>
                                <li>
                                    <Link to="/mobilelogs" ><img alt="" src={logs} /> MobileLogs</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="user__data">
                            <div className="search-filter">
                                <form action="">
                                    <input type="text" onChange={e => setSearchValue(e.target.value)} placeholder="Search By Entire ID..." />
                                    <button onClick={e => e.preventDefault()} type="submit"><AiOutlineSearch /></button>
                                </form>
                                <div className="sort-date">
                                    Sort by:
                                    <Form.Select aria-label="Default select example">
                                        <option>Timestamp</option>
                                    </Form.Select>
                                </div>
                                <div className="sort-date">
                                    Order way:
                                    <Form.Select defaultValue={"Descending"} onChange={onOrderChange} aria-label="Default select example">
                                        <option>Ascending</option>
                                        <option>Descending</option>
                                    </Form.Select>
                                </div>
                                <div className="sort-date">
                                    Display size:
                                    <Form.Select defaultValue={20} onChange={e => setDisplaySize(e.target.value)} aria-label="Default select example">
                                        <option value={10}>10</option>
                                        <option value={20}>20</option>
                                        <option value={50}>50</option>
                                        <option value={100}>100</option>
                                    </Form.Select>
                                </div>
                                <div className="filter">
                                    Filter <img alt="" src={filterAdd} />
                                </div>
                                {/* <ul className="sort_check">
                                    <li>
                                        <div className="check">
                                            <span className="close">&#10006;</span>
                                            <span>User: Jonas Mossler</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="check">
                                            <span className="close">&#10006;</span>
                                            <span>Konto: Geschäftlich</span>
                                        </div>
                                    </li>
                                </ul> */}
                            </div>
                            <div className="wallbox-tabl">
                                {
                                    isNotFound && !isFetching ?
                                        <h5 h5 className="text-center mt-5">Result Not found...</h5>
                                        :
                                        <EventRows
                                            events={events}
                                            pageCount={pageCount}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            isFetching={isFetching}
                                            totalDataCount={totalDataCount}
                                            displaySize={displaySize}
                                        />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        </>
    );
};


export default Events;
