import React, { useState } from "react";
import logoCloud from '../../images/logo/logoCloud.png';
import logo2 from '../../images/logo/logo2.png';
import LoginSlider from "../Login/LoginSlider";
import "../Login/Login.scss";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import Cookies from 'js-cookie'
import { Link } from "react-router-dom";

const ResetPassword = () => {
    document.title = "Reset Password - POWERGrid Cloud"
    const [password, SetPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [isProcess, setIsProcess] = useState(false)
    const [message, setMessage] = useState("")

    const history = useHistory()
    const params = useParams()


    const handleSubmit = e => {
        setIsProcess(true)
        e.preventDefault()
        if (confirmPassword === password) {
            setMessage("")

            const resetPasswordInfo = new URLSearchParams(Object.entries({
                new_pass: password,
                confirm_new_pass: password
            })).toString();
            axios.post(`${process.env.REACT_APP_USER_BACKEND_API_URL}/users/reset_password/${params?.token}`, resetPasswordInfo, {
                headers: {
                    Authorization: "Bearer " + Cookies.get("access_token")
                }
            })
                .then(function (response) {
                    setMessage(response.data)
                })
                .catch(function (error) {
                    console.log(error)
                })
                .then(function () {
                    setIsProcess(false)
                })

        } else {
            setIsProcess(false)
            setMessage("Password Not matched")
        }

    }

    return (
        <>
            <div className="container-fluid p-0">
                <div className="login-area">
                    <div className="login-form">
                        <div className="logo-head">
                            <div className="logo-2">
                                <img src={logoCloud} alt="" />
                            </div>
                            <div className="h4">Reset your password</div>
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label className="pass"><span>*</span> Enter new password</label>
                                    <input onChange={e => SetPassword(e.target.value)} required type="password" />
                                </div>
                                <div className="form-group">
                                    <label className="pass"><span>*</span> Confirm Passwort</label>
                                    <input onChange={e => setConfirmPassword(e.target.value)} required type="password" />
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="subBtn"><i className="fa-solid fa-unlock"></i> {isProcess ? "Loading..." : "Set Password"}</button>
                                </div>
                            </form>
                            <div className="message">
                                <p className="mt-2" style={{ color: "gray", fontWeight: "bold" }}>{message === "Password was successfully reset. Please check your Email" ? <><p>Password was successfully reset click here to </p> <Link style={{ color: "#F49945", fontWeight: "800" }} to="/login">Login</Link></> : message}</p>
                            </div>
                        </div>
                        <div className="logo-footer">
                            <img src={logo2} alt="" />
                        </div>
                    </div>
                    <div className="login-slid ">
                        <LoginSlider />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ResetPassword;
