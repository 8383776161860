import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';
import Cookies from "js-cookie";


import NavBar from "../NavBar/NavBar";
import filterAdd from "../../images/icons/filter-add.png";
import userI from "../../images/icons/user.png";
import rolesI from "../../images/icons/roles.png";
import logs from "../../images/icons/logs.png"
import { AiOutlineSearch } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";


import "./PowerBrain.scss"
import { Link } from "react-router-dom";
import axios from "axios";
import PowerBrainRows from "./PowerBrainRows";

const PowerBrain = () => {
    document.title = "POWERBrain Events - POWERGrid Cloud"

    const [powerBrains, setPowerBrains] = useState([])
    const [pageCount, setPageCount] = useState(1)
    const [currentPage, setCurrentPage] = useState(1)
    const [isFetching, setIsFetching] = useState(false)
    const [totalDataCount, setTotalDataCount] = useState()
    const [searchValue, setSearchValue] = useState("")
    const [displaySize, setDisplaySize] = useState(20)
    const [sorting, setSorting] = useState("created_at")
    const [orderWay, setOrderWay] = useState("desc")

    const onOrderChange = () => {
        if (orderWay === "desc") {
            setOrderWay("asc")
        } else {
            setOrderWay("desc")
        }
    }

    const { state, pathname } = useLocation();
    const history = useHistory();

    const getEventList = () => {
        setIsFetching(true)
        axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/powergrid_events?order=${sorting}&order_way=${orderWay}&search_string=${searchValue}&page=${currentPage}&page_size=${displaySize}`, {
            headers: {
                Authorization: "Bearer " + Cookies.get("access_token"),
            },
        })
            .then(response => {
                setIsFetching(false)
                const events = response?.data?.data
                if (response?.data?.total_count > 0) {
                    events.forEach(element => {
                        element.selected = false
                    });
                    setTotalDataCount(response?.data?.total_count)
                    setPageCount(Math.ceil(response?.data?.total_count / displaySize))
                    setPowerBrains(events)
                } else {
                    setTotalDataCount(response?.data?.total_count)
                }
            })
            .catch(error => {
                console.error("error found", error)
                setIsFetching(false)
            })
            .then(() => {
                setIsFetching(false)
            })
    }

    useEffect(() => {
        var search_string = "";
        if (searchValue) {
            search_string = searchValue
            if (state?.wallbox){
                history.replace(pathname, null);    
            }
        } else if (state?.wallbox) {
            search_string = state?.wallbox
        }
        setSearchValue(search_string)
        getEventList()
    }, [currentPage, sorting, orderWay, searchValue, displaySize])


    return (
        <>
            <NavBar />
            <main className="wrapper">
                <div className="page-content">
                    <div className="wallbox-area">
                        <div className="pages-title">
                            <div className="h2">POWERBrain</div>
                            <span>View all POWERBrain in one table</span>
                        </div>
                        <div className="user__content">
                            <div className="user__data">
                                <ul className="panel_section">
                                    <li>
                                        <span>Panel Selection</span>
                                    </li>
                                    <li>
                                        <Link to="/events"><img alt="" src={userI} /> Events</Link>
                                    </li>
                                    <li>
                                        <Link to="/powerbrain" className="active"><img alt="" src={rolesI} /> POWERBrain</Link>
                                    </li>
                                    <li>
                                        <Link to="/mobilelogs" ><img alt="" src={logs} /> MobileLogs</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="user__data">
                                <div className="search-filter">
                                    <form action="">
                                        <input type="text" value={searchValue} onChange={e => setSearchValue(e.target.value)} placeholder="Search By Entire ID..." />
                                        <button onClick={e => e.preventDefault()} type="submit"><AiOutlineSearch /></button>
                                    </form>
                                    <div className="sort-date">
                                        Sort by:
                                        <Form.Select onChange={(e) => setSorting(e.target.value)} aria-label="Default select example">
                                            <option value="created_at">Created At</option>
                                            <option value="id">ID</option>
                                        </Form.Select>
                                    </div>
                                    <div className="sort-date">
                                        Order way:
                                        <Form.Select defaultValue={"Descending"} onChange={onOrderChange} aria-label="Default select example">
                                            <option>Ascending</option>
                                            <option>Descending</option>
                                        </Form.Select>
                                    </div>
                                    <div className="sort-date">
                                        Display size:
                                        <Form.Select defaultValue={20} onChange={e => setDisplaySize(e.target.value)} aria-label="Default select example">
                                            <option value={10}>10</option>
                                            <option value={20}>20</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                        </Form.Select>
                                    </div>
                                    <div className="filter">
                                        Filter <img alt="" src={filterAdd} />
                                    </div>
                                </div>
                                <div className="wallbox-tabl">
                                    {
                                        totalDataCount == 0 ? 
                                        <h5 className="text-center mt-5">
                                            Result Not found...
                                        </h5> :
                                        <PowerBrainRows
                                            events={powerBrains}
                                            pageCount={pageCount}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            isFetching={isFetching}
                                            totalDataCount={totalDataCount}
                                            displaySize={displaySize}
                                        />
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default PowerBrain;