import React, { useEffect, useState } from "react";
import NavBar from "../../NavBar/NavBar";
import Cookies from 'js-cookie'
import { Link } from "react-router-dom";
import axios from "axios";
import Form from "react-bootstrap/Form";
import { TailSpin } from "react-loader-spinner";


const SyncWallboxes = () => {
    document.title = "SyncWallboxes - POWERGrid Cloud"
    const [originServerList, setOriginServerList] = useState(["Server 149", "Server 150"])
    const [targetServerList, setTargetServerList] = useState(["Server 149", "Server 150"])
    const [syncWallboxLogs, setSyncWallboxLogs] = useState([])
    const [isFetching, setIsFetching] = useState(false)
    const [message, setMessage] = useState("")
    const [currentEmail, setCurrentEmail] = useState("")

    const onSelectOriginServer = (e) => {
        const ServerList = ["Server 149", "Server 150"]
        const filteredList = ServerList.filter(server => {return server !== e.target.value})
        setTargetServerList(filteredList)
    }

    const onSelectTargetServer = (e) => {
        const ServerList = ["Server 149", "Server 150"]
        const filteredList = ServerList.filter(server => {return server !== e.target.value})
        setOriginServerList(filteredList)
    }

    function syncWallbox(){
        if (currentEmail === "christoph.zillner@powerjames.com" || currentEmail === "tarunsengar1987@gmail.com"){
            setIsFetching(true)
            axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/sync_wallbox`,
            {
                headers: {
                    Authorization: "Bearer " + Cookies.get("access_token"),
                },
            }
        )
        .then(response => {
            setIsFetching(false)
            if (response?.data?.success){
                if (response?.data?.data?.length > 0){
                    setSyncWallboxLogs(response?.data?.data)
                    setMessage("")
                } else {
                    setSyncWallboxLogs([])
                    setMessage("Did not found any wallbox")
                }
            }

        })
            .catch(error => {
                setIsFetching(false)
                console.error("error found", error)
                setSyncWallboxLogs([])
            })
        } else {
            setMessage("You don't have permission to sync wallbox")
        }

    }

    useEffect(()=>{
        setCurrentEmail(Cookies.get("user_email"))
    })


    return (
        <>
            <NavBar />
            <main className="wrapper">
                <div className="page-content">
                    <div className="wallbox-area">
                        <div className="pages-title">
                            <div className="h2">All Documents</div>
                            <span>View all documents</span>
                        </div>
                        <div className="user__content">
                            <div className="user__data">
                                <ul className="panel_section">
                                    <li>
                                        <span>APIs Selection</span>
                                    </li>
                                    <li>
                                        <Link to="/docs/sim2serial"> Sim2Serial</Link>
                                    </li>
                                    <li>
                                        <Link to="/docs/serial2sim"> Serial2Sim</Link>
                                    </li>
                                    <li>
                                        <Link to="/docs/Serial2Code"> Serial2Code</Link>
                                    </li>
                                    <li>
                                        <Link to="/docs/Code2Serial">Code2Serial</Link>
                                    </li>
                                    <li>
                                        <Link to="/docs/syncwallboxes" className="active">SyncWallboxes</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="user__data">
                                <div className="mt-5 mr-5">
                                    <h6 className="text-left mt-5">Sync Wallboxes from 149 to 150</h6>
                                </div>
                                <div className="box_group"  style={{margin: "40px"}}>
                                    <label style={{marginBottom: "10px"}}>Origin Database</label>
                                    <Form.Select
                                    aria-label="Default select example"
                                    style={{width:"350px", borderRadius: "20px"}}
                                    onChange={onSelectOriginServer}
                                    disabled={true}
                                    >
                                        {/* <option>--Select Server--</option> */}
                                        <option>Server 149</option>

                                        {/* {originServerList.map((list)=>
                                        // <option>{list}</option>
                                        )} */}
                                    </Form.Select>
                                </div>
                                <div className="box_group"  style={{margin: "40px"}}>
                                    <label style={{marginBottom: "10px"}}>Target Database</label>
                                    <Form.Select
                                    aria-label="Default select example"
                                    style={{width:"350px", borderRadius: "20px"}}
                                    onChange={onSelectTargetServer}
                                    disabled={true}
                                    >
                                        <option>Server 150</option>

                                        {/* {targetServerList.map((list)=>
                                        // <option>{list}</option>
                                        )} */}
                                    </Form.Select>
                                    <span>
                                        <button className="btn-yellpow" onClick={syncWallbox}>Sync</button>
                                    </span>
                                    <div className="message">
                                        <p className="mt-2" style={{ color: "gray", fontWeight: "bold" }}>{message}</p>
                                    </div>
                                </div>
                                <div className="wallbox-tabl">
                                    {
                                        isFetching ? 
                                        <div className="router-loading-spinner">
                                            <TailSpin width={50} height={50} color="#002060" />
                                        </div> :
                                        syncWallboxLogs?.length < 1 ?
                                            <h5 className="text-center mt-5"></h5>
                                        :
                                        <div className="table-componet">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="tab__content">
                                                    <table className="table box-table">
                                                        {/* <thead>
                                                            <tr>
                                                                <th scope="col">ID</th>
                                                                <th scope="col" style={{paddingLeft:"150px"}}>Wallbox Serial</th>
                                                                <th scope="col" style={{paddingRight:"300px"}}>Count</th>
                                                            </tr>
                                                        </thead> */}
                                                        <tbody>{
                                                            
                                                            syncWallboxLogs?.map((data_dict, index) => {
                                                                return(
                                                                    <tr key={index} className="m-10">
                                                                        <td style={{padding: "28px 28px 10px 30p"}}>New Wallbox found: {data_dict?.serial}<br/>{data_dict?.serial} - {data_dict?.success ? 'Wallbox Added on target database' : 'Wallbox not added on target database'}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                            }
                            
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};


export default SyncWallboxes;
