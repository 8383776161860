import React, { useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import NavBar from '../NavBar/NavBar';
import Rectangle from "../../images/Rectangle.png";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

const AddWallbox = () => {

    const [serialNumber, setSerialNumber] = useState("")
    const [hardwareInfo, setHardwareInfo] = useState("")
    const [firmwareVersion, setFirmwareVersion] = useState("")
    const [sim, setSim] = useState("")
    const [isProcess, setIsProcess] = useState(false)
    const [message, setMessage] = useState("")

    const history = useHistory();
    const getWallboxList = () => {
        history.push("/");
    }

    const handleSubmit = (e) => {
        setIsProcess(true);
        e.preventDefault();

        const dataInfo = {
            serial_number: serialNumber,
            hardware_info: hardwareInfo,
            firmware_version: firmwareVersion,
            sim: sim
        };

        axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/wallbox`, dataInfo, {
            headers: {
                Authorization: "Bearer " + Cookies.get("access_token")
            }
        })
        .then(function (response) {
            if (response.data.success) {
                getWallboxList();
            } else {
                setMessage(response?.data.message);
                toast.error(message, {autoClose:3000})
            }
        })
        .catch(function (error) {
            console.log(error)
        })
        .then(function () {
            setIsProcess(false)
        })
    };
    return (
        <>
        <NavBar />
        <main className="wrapper">
            <div className="page-content">
                <div className="wallbox-area">
                    <div className="pages-title-list">
                        <ul>
                            <li>
                                <span>
                                    <Link to="/">
                                    All Wallbox
                                    </Link>
                                </span>
                            </li>
                            <li>
                                <span>Add Wallboxes</span>
                            </li>
                        </ul>
                    </div>
                    <div className="user__content">
                        <div className="user__data">
                            <div className="wallbox-tabl">
                                <div className="table-componet">
                                    <div className="details_cont">
                                        <div className="wallbox_details" style={{ position: "relative" }}>
                                            <div className="wallbox_details_content1">
                                                <form onSubmit={handleSubmit}>
                                                    <div className="box_group">
                                                        <label>Serial Number</label>
                                                        <input type="text" onChange={e => setSerialNumber(e.target.value)} required/>
                                                    </div>
                                                    <div className="box_group">
                                                        <label>Hardware Info</label>
                                                        <input type="text" onChange={e => setHardwareInfo(e.target.value)} required/>
                                                    </div>
                                                    <div className="box_group">
                                                        <label>Firmware Version</label>
                                                        <input type="text" onChange={e => setFirmwareVersion(e.target.value)} required/>
                                                    </div>
                                                    <div className="box_group">
                                                        <label>Sim</label>
                                                        <input type="text" onChange={e => setSim(e.target.value)} required/>
                                                    </div>
                                                    <button className="btn-yellpow">Submit</button>
                                                </form>
                                                <div className="box_right_img1">
                                                    <img alt="" src={Rectangle} />
                                                </div> 
                                            </div> 
                                        </div>
                                    </div>                          
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        </>
    );
}

export default AddWallbox;