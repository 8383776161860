import React, { useEffect, useState } from "react";
import NavBar from "../NavBar/NavBar";
import { Link } from "react-router-dom";
import reset from "../../images/icons/update.png";
import pp1 from "../../images//pp1.png";
import AllActivities from "./UserSingle/AllActivities";
import axios from "axios";
import Cookies from 'js-cookie'
import { TailSpin } from "react-loader-spinner";
import { FaAngleLeft } from "react-icons/fa";
import editImg from "../../images/edit-img-icon.svg";

const UserProfile = () => {
    
    document.title = "User Profile - POWERGrid Cloud"
    const [user, setUser] = useState({})
    const [imageBinary, setImageBinary] = useState();
    const [userProfile, setuserProfile] = useState();

    const { FullName, Phone, Email, Confirmed} = user;

    const profileImage = (e) => {
        e.preventDefault();
        let imageFile = new FormData();
        imageFile.append("image_file", imageBinary)

        axios.post(`${process.env.REACT_APP_USER_BACKEND_API_URL}/users/profile_image/${user.ID}`, imageFile, {
            headers: {
                Authorization: "Bearer " + Cookies.get("access_token"),
                "Content-Type": "multipart/form-data",
            }
        })
        .then(function (response) {
            if (response.data.success) {
                getUserInfo();
                setImageBinary(null) 
                window.location.reload(true)
            }
        })
        .catch(function (error) {
            console.log(error)
        })
    }

    const getUserInfo = () => {
        axios.get(`${process.env.REACT_APP_USER_BACKEND_API_URL}/users/user_info`, {
        headers: {
            authorization: "Bearer " + Cookies.get("access_token")
        }
        })
        .then(response => {
            if (response.data) {
                const user_data = response?.data?.data;
                setUser(user_data)
            }
        })
        .catch(error => {
            console.error("Got Error", error)
        })
    };

    const handleReset = (e, Email) => {
    e.preventDefault()
    const askToConfirm = window.confirm(`Are you sure want to Reset password of ${Email}`)
    if (askToConfirm) {

        const resetPasswordInfo = new URLSearchParams(Object.entries({
        Email: Email
        })).toString();
        axios.post(`${process.env.REACT_APP_USER_BACKEND_API_URL}/users/forgot_password`, resetPasswordInfo, {
        headers: {
            Authorization: "Bearer " + Cookies.get("access_token")
        }
        })
        .then(function (response) {
            window.alert(response.data)
        })
        .catch(function (error) {
            console.log(error)
        })
    }
    }

    const handleFileSelected = (e) => {
        const files = e.target.files[0];
        setImageBinary(files);
        var oFReader = new FileReader();
        oFReader.readAsDataURL(document.getElementById("uploadImage").files[0]);

        oFReader.onload = function (oFREvent) {
            document.getElementById("uploadPreview").src = oFREvent.target.result;
        };
    };

    useEffect(() => {
        getUserInfo();
    }, [])

    return(
        <>
        <NavBar setUserInfo={getUserInfo}/>
        <main className="wrapper">
            <div className="page-content">
                <div className="wallbox-area">
                    <div className="pages-title-list">
                        <ul>
                            <li>
                            <Link to="/">User Profile</Link>
                            </li>
                            <li>
                            <span>{FullName}</span>
                            </li>
                        </ul>
                    </div>

                    <div className="wallbox_single_content">
                        <div className="single_all_list">
                            <ul className="box_progress">
                                <li>
                                    <Link to="/">
                                    <FaAngleLeft /> Back to Wallboxes
                                    </Link>
                                </li>
                                <li>
                                    <span>Users Functions</span>
                                </li>
                                <li>
                                    <Link onClick={(e) => handleReset(e, Email)} to="#">
                                    <img alt="" src={reset} /> Reset Password
                                    </Link>
                                </li>
                                <li>
                                    
                                </li>
                            </ul>
                        </div>
                        <div className="details_cont" style={{ position: "relative" }}>
                            {!user.Email ?
                            <div className="router-loading-spinner">
                                <TailSpin width={25} height={25} color="#002060" />
                            </div> 
                            :
                            <div className="wallbox_details">
                                <div className="h5">User Details</div>
                                <div className="wallbox_details_content">
                                <form action="">
                                    <div className="box_group">
                                    <label>Name</label>
                                    <input type="text" defaultValue={FullName} readOnly />
                                    </div>
                                    <div className="box_group">
                                    <label> Phone </label>
                                    <input type="text" defaultValue={Phone} readOnly />
                                    </div>
                                    <div className="box_group">
                                    <label>E-mail</label>
                                    <input type="text" defaultValue={Email} readOnly />
                                    </div>
                                    <div className="box_group">
                                    <label>Address</label>
                                    <input type="text" readOnly />
                                    </div>
                                    <div className="box_group">
                                    <label>Company Name</label>
                                    <input type="text" readOnly />
                                    </div>
                                    <div className="box_group">
                                    <label>Company Address</label>
                                    <input type="text" readOnly />
                                    </div>
                                </form>
                                <form onSubmit={profileImage}>
                                    <div className="box_right_img">
                                    <div className="edit-icon">
                                        <img alt="" onChange={handleFileSelected} src={editImg} />
                                        </div>
                                        <img id="uploadPreview" alt="" onChange={handleFileSelected} src={user.image ? `data:image/png;base64, ${user.image}` : pp1}/>
                                        <input id="uploadImage" type="file" name="myfile" onChange={handleFileSelected} />

                                        {/* <button className="btn btn-outline-danger" onChange={profileImage}>Upload Image</button> */}
                                        
                                        {
                                        imageBinary ? 
                                        <button className="btn-yellpow3">Submit</button>
                                        :
                                        ""
                                        }
                                        <span>Status</span>
                                        <span className={Confirmed === "active" ? "Running" : "inactive"}>{Confirmed}</span>
                                    </div>
                                </form>
                                </div>
                            </div>
                            }
                            
                        </div>
                        <div className="allActivities">
                            <AllActivities />
                        </div>
                    </div>
                </div>
            </div>
        </main>
        </>
    );
}

export default UserProfile;