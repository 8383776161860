import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";

const UserWallbox = () => {

    const [userInWallbox, setUserInWallbox] = useState([])

    const params = useParams();
    const { wallboxId } = params;

    const getUserWallbox = () => {
        axios
        .get(`${process.env.REACT_APP_BACKEND_API_URL}/user_in_wallbox/${wallboxId}`, {
          headers: {
            authorization: "Bearer " + Cookies.get("access_token"),
          },
        })
        .then((response) => {
          if (response.data.success) {
            setUserInWallbox(response.data.data);
          }
        })
        .catch((error) => {
          console.error("Got Error", error);
        });
    };

    useEffect(() => {
        getUserWallbox();
    }, [wallboxId]);

    return (
        <div className='userBox'>
            <div className='userTable_con'>
                <table className="table box-table">
                    <thead>
                        <tr>
                            <th scope="col" className="user_name">User Email</th>
                            <th scope="col" className="roles">Role</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userInWallbox?.map((users, index)=> {
                            return (
                                <tr key={index}>
                                    <td className="name"><span>{users.email}</span></td>
                                    <td className="role"><span>{users.roles}</span></td>
                                </tr>
                            )
                        })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default UserWallbox;