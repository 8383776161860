import React, { useState } from 'react';
import search from "../../images/icons/search.png"
import events from "../../images/icons/events.png"
import update from "../../images/icons/update.png"
import { BsChevronDoubleRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import { TailSpin } from 'react-loader-spinner';
import { useHistory } from "react-router-dom";
import { getPermission } from '../../utils';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const WallBoxes = ({ isFetcing, totalWallBoxCount, pageCount, currentPage, setCurrentPage, allWallBoxes, setAllWallBoxes, onOrderChange, orderWay, displaySize }) => {
    const [masterChecked, setMasterChecked] = useState(false)
    const [selectedList, setSelectedList] = useState([])

    const onMasterCheck = (e) => {
        let tempList = allWallBoxes;
        // Check/ UnCheck All Items
        tempList.map((user) => (user.selected = e.target.checked));

        //Update State
        setMasterChecked(e.target.checked)
        setAllWallBoxes(tempList)
        setSelectedList(allWallBoxes.filter((e) => e.selected))
    }

    const onItemCheck = (e, item) => {
        let tempList = allWallBoxes;
        tempList.map((user) => {
            if (user.id === item.id) {
                user.selected = e.target.checked;
            }
            return user;
        });

        //To Control Master Checkbox State
        const totalItems = allWallBoxes?.length;
        const totalCheckedItems = tempList.filter((e) => e.selected)?.length;

        // Update State
        setMasterChecked(totalItems === totalCheckedItems)
        setAllWallBoxes(tempList)
        setSelectedList(allWallBoxes.filter((e) => e.selected))
    }

    const getSelectedRows = () => {
        setSelectedList(allWallBoxes.filter((e) => e.selected))
    }

    return (
        <div className="table-componet">
            <div className="row">
                <div className="col-md-12">
                    <div className="tab__content">
                        <table className="table box-table">
                            <thead>
                                <tr>
                                    <th scope="col">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={masterChecked}
                                            id="mastercheck"
                                            onChange={(e) => onMasterCheck(e)}
                                        />
                                    </th>
                                    <th scope="col">ID</th>

                                    <th scope="col" className="serialT">Wallbox Serial</th>

                                    <th scope="col" className="FirmwareT">Firmware Version</th>

                                    <th scope="col" className="id">
                                        <span className="id">Date installed</span>
                                    </th>

                                    <th scope="col" className="functionsT">Functions </th>
                                    <th scope="col" className="ownerT">Owner</th>
                                    <th scope="col" className="statusT">Status</th>
                                    <th scope="col" className="actionsT">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    allWallBoxes?.length && !isFetcing ? allWallBoxes.map((wallbox, index) => (
                                        <SingleRow onItemCheck={onItemCheck} key={index} wallBox={wallbox} />
                                    ))
                                    :
                                    <tr>
                                        <td>
                                            <div className="router-loading-spinner">
                                            <TailSpin width={50} height={50} color="#002060" />
                                            </div>
                                        </td>
                                    </tr>
                                }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="pagination-content">
                <ul>
                    {/* {
                        [...Array(pageCount).keys()].map(avc => <li className={currentPage === avc + 1 && "active"} onClick={() => setCurrentPage(avc + 1)}>{avc + 1}</li>)
                    }
                    {
                        currentPage !== pageCount && <li onClick={() => setCurrentPage(currentPage < pageCount ? currentPage + 1 : currentPage)}><BsChevronDoubleRight /></li>
                    } */}
                    <Stack spacing={2}>
                        <Pagination onChange={(e, pn) => setCurrentPage(pn)} count={pageCount} />
                    </Stack>

                </ul>
                <div className="pageRight">
                    <span>Showing {allWallBoxes?.length} of {totalWallBoxCount} items</span>
                </div>
            </div>
        </div>
    );
};


const SingleRow = ({ onItemCheck, wallBox }) => {
    const { id, serial_number, firmware_version, created_at, owner, status, functions_list, owner_id, owner_name } = wallBox
    const history = useHistory();
    const UpdateWallbox = () => {
        history.push("/updatewallbox", {serial_number: serial_number});
    }

    const WallboxUser = () => {
        if (owner_id){
            history.push(`/wallboxuser/${owner_id}`)
        }
    }

    const checkWallboxUpdate = getPermission("wallbox_update")

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

    let functionList = functions_list ? functions_list.split(",") : []

    return (
        <tr className={wallBox.selected ? "selected" : ""}>
            <th scope="row">
                <input
                    type="checkbox"
                    checked={wallBox.selected}
                    className="form-check-input"
                    id="rowcheck{user.id}"
                    onChange={(e) => onItemCheck(e, wallBox)}
                />
            </th>
            <td><span>{id}</span></td>
            <td className="wallboxSerial"><span><Link to={`/wallbox/${serial_number}`}>{serial_number}</Link></span></td>
            <td className="FirmwareVersion"><span>v {firmware_version}</span></td>
            <td className="Dateinstalled"><span>{created_at}</span></td>
            <td className="Functions">
                <span>
                    {
                        functionList.map((func, index) => <button key={index} className={func}>{func}</button>)
                    }

                </span>
            </td>
            <td className="Owner" onClick={WallboxUser} style={{cursor: "pointer"}}><span>{owner.length > 1 ? owner : owner_name || "Unknown User"}</span></td>
            <td className="Stat"><span className={status}>{capitalizeFirstLetter(status)}</span></td>
            <td className="Actions">
                <span>
                    <button onClick={()=>history.push(`/powerbrain`, {wallbox: serial_number})}><img src={events} alt="" /> Events</button>
                    {checkWallboxUpdate == true ?
                    <button onClick={UpdateWallbox} ><img src={update} alt="" /> Update</button>
                    :
                    null
                    }
                    <button><img src={search} alt="" /></button>
                </span>
            </td>
        </tr>
    )
}

export default WallBoxes;