import React, { useState } from "react";
import Home from "../../images/nav/home.png";
import ChargeEvents from "../../images/nav/charge_events.png";
import User from "../../images/nav/user.png";
import Event from "../../images/nav/events.png";
import { Dropdown } from "react-bootstrap";
import "./navBar.scss";
import bar from "../../images/bar.png";
import logo from "../../images/logo/logo1.png";
import userIcon from "../../images/pp1.png";
import setting from "../../images/nav/settings.png";
import Document from "../../images/nav/document.png";
import releasenotes from "../../images/nav/releasenotes.png";
import charge_transaction from "../../images/nav/charge_transaction.png";
import notification from "../../images/nav/notification.png"
import firmware from "../../images/nav/firmware.png"
import { Link, NavLink } from "react-router-dom";
import useAuth from "../../Hooks/useAuth";
import { useHistory } from "react-router-dom";
import getUserPermissions from '../../utils'
import { useEffect } from "react";
import measurement from "../../images/nav/measurement.png"
import userpermission from "../../images/nav/userpermission.png";
import { getUserInfo } from './../../utils';
import Cookies from "js-cookie";
import statistics from "../../images/nav/statistics.png"
import carprofile from "../../images/nav/carprofile1.png"

getUserInfo();
getUserPermissions();

const NavBar = () => {
  const [sidebar, setSidebar] = useState(false);
  // const [userInfo, setUserInfo] = useState({});
  const [userImage, setUserImage] = useState('');

  const showSidebar = () => setSidebar(!sidebar);
  const exPath = document?.location?.pathname;

  const history = useHistory();
  const { user, handleSignOut } = useAuth();

  const updateProfile = () => {
    history.push("/userprofile");
  }

  const onSignOut = () => {
    Cookies.remove("user_permissions")
  }

  useEffect(() => {
    setUserImage(localStorage.getItem("user_image"))
  }, [])


  return (
    <>
      <div className="navbar-area ">
        <div className="navleft">
          <button onClick={showSidebar} className="navToggle">
            <img src={bar} alt="" />
          </button>
          <Link to="/">
            <img src={logo} alt="" />
          </Link>
        </div>
        <div className="navright ">
          <Dropdown align="end" className="navUser" id="dropdown-menu-align-end">
            <span className="Uname">{user.Email || user}</span>{" "}
            <Dropdown.Toggle>
            <img src={userImage && userImage != 'null' ? `data:image/png;base64, ${userImage}` : userIcon} alt="" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={updateProfile}>My Profile</Dropdown.Item>
              <Dropdown.Item onClick={() => {
                handleSignOut(history)
                onSignOut()
              }}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      <nav className={sidebar ? "sidebarNav active" : "sidebarNav"}>
        <ul className="nav-menu-items" onClick={showSidebar}>
          <li className="nav-text">
            <NavLink
              to="/"
              className={
                exPath === "/" || exPath === "/addwallbox" || exPath === "/updatewallbox" || exPath === "/wallbox/:wallboxId" ? "navSelected" : ""
              }
            >
              <img src={Home} alt="" />
              <span className="text">Dashboard</span>
            </NavLink>
          </li>
          <li className="nav-text">
            <NavLink
              to="/chargeEvents"
              className={exPath === "/chargeEvents" ? "navSelected" : ""}
            >
              <img src={ChargeEvents} alt="" />
              <span className="text">Charge Events</span>
            </NavLink>
          </li>

          <li className="nav-text">
            <NavLink
              to="/users"
              className={
                exPath === "/users" ||
                exPath === "/user_single" ||
                exPath === "/roles" ||
                exPath === "/permissions"
                  ? "navSelected"
                  : ""
              }
            >
              <img src={User} alt="" />
              <span className="text">User</span>
            </NavLink>
          </li>

          <li className="nav-text">
            <NavLink
              to="/events"
              className={
                exPath === "/powerbrain" || exPath === "/events" || exPath === "/mobilelogs"
                  ? "navSelected"
                  : ""
              }
            >
              <img src={Event} alt="" />
              <span className="text">User</span>
            </NavLink>
          </li>
          <li className="nav-text">
            <NavLink
              to="/statisticsdashboard"
              className={
                exPath === "/statisticsdashboard" ||
                exPath === "/statisticsdashboard"
                  ? "navSelected"
                  : ""
              }
            >
              <img src={statistics} alt="" />
              <span className="text">User</span>
            </NavLink>
          </li>
          <li className="nav-text">
            <NavLink
              to="/docs/sim2serial"
              className={
                exPath === "/docs/sim2serial" || exPath === "/docs/serial2sim" || exPath === "/docs/Serial2Code" || exPath === "/docs/Code2Serial" || exPath === "/docs/syncWallbox"
                  ? "navSelected"
                  : ""
              }
            >
              <img src={Document} alt="" />
              <span className="text">User</span>
            </NavLink>
          </li>

          <li className="nav-text">
            <NavLink
              to="/releasenotes"
              className={
                exPath === "/releasenotes" ||
                exPath === "/releasenotes"
                  ? "navSelected"
                  : ""
              }
            >
              <img src={releasenotes} alt="" />
              <span className="text">User</span>
            </NavLink>
          </li>
          {/* { showBillingService ? */}
          <li className="nav-text">
            <NavLink
              to="/billingservice"
              className={
                exPath === "/billingservice" || exPath === "/billingservice"
                  ? "navSelected"
                  : ""
              }
            >
              <img src={charge_transaction} alt="" />
              <span className="text">User</span>
            </NavLink>
          </li>
          {/* : ''} */}
          <li className="nav-text">
            <NavLink
              to="/CustomersMessages"
              className={
                exPath === "/CustomersMessages" ||
                exPath === "/CustomersMessages"
                  ? "navSelected"
                  : ""
              }
            >
              <img src={notification} alt="" />
              <span className="text">User</span>
            </NavLink>
          </li>
          <li className="nav-text">
            <NavLink
              to="/firmware"
              className={
                exPath === "/firmware" || exPath === "/addfirmware" || exPath === "/group" || exPath === "/addgroup"
                  ? "navSelected"
                  : ""
              }
            >
              <img src={firmware} alt="" />
              <span className="text">User</span>
            </NavLink>
          </li>
          <li className="nav-text">
            <NavLink
              to="/measurement"
              className={
                exPath === "/measurement" ||
                exPath === "/addmeasurement"
                  ? "navSelected"
                  : ""
              }
            >
              <img src={measurement} alt="" />
              <span className="text">User</span>
            </NavLink>
          </li>
          <li className="nav-text">
            <NavLink
              to="/listcarprofile"
              className={
                exPath === "/listcarprofile"
                  ? "navSelected"
                  : ""
              }
            >
              <img src={carprofile} alt="" />
              <span className="text">User</span>
            </NavLink>
          </li>
          <li className="nav-text">
            <NavLink
              to="/rolePermission"
              className={
                exPath === "/rolePermission" ||
                exPath === "/userRole"
                  ? "navSelected"
                  : ""
              }
            >
              <img src={userpermission} alt="" />
              <span className="text">User</span>
            </NavLink>
          </li>
        </ul>

        {/* <ul className="bottomList">
          <li>
            <Link to="/">
              <img src={setting} alt="" />
            </Link>
          </li>
        </ul> */}
      </nav>
    </>
  );
};

export default NavBar;