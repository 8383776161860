import React, { useState } from "react";
import { TailSpin } from "react-loader-spinner";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { Link } from "react-router-dom";


const MobileLogsRows = ({ events, pageCount, currentPage, setCurrentPage, isFetching, totalDataCount, displaySize }) => {
    const [List, setList] = useState(events)
    const [MasterChecked, setMasterChecked] = useState(false)
    const [SelectedList, setSelectedList] = useState([])

    function onMasterCheck(e) {
        let tempList = List;
        tempList.map((user) => (user.selected = e.target.checked));
        setMasterChecked(e.target.checked)
        setList(tempList)
        setSelectedList(List.filter((e) => e.selected))
    }

    function onItemCheck(e, item) {
        let tempList = List;
        tempList.map((user) => {
            if (user.id === item.id) {
                user.selected = e.target.checked;
            }
            return user;
        });

        const totalItems = List.length;
        const totalCheckedItems = tempList.filter((e) => e.selected).length;

        setMasterChecked(totalItems === totalCheckedItems)
        setList(tempList)
        setSelectedList(List.filter((e) => e.selected))
    }

    return (
        <>
            <div className="table-componet">
                <div className="row">
                    <div className="col-md-12">
                        <div className="tab__content">
                            <table className="table box-table user_table">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={MasterChecked}
                                                id="mastercheck"
                                                onChange={(e) => onMasterCheck(e)}
                                            />
                                        </th>
                                        <th scope="col" className="serialT">ID</th>
                                        <th scope="col" className="serialT">timestamp</th>
                                        <th scope="col" className="FirmwareT">Logs</th>
                                        <th scope="col" className="Energy_T">Email</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        events.length > 0?
                                        events.map((event, index) => <SingleRow key={index} onItemCheck={onItemCheck} event={event} />) 
                                        :
                                        isFetching == true ?
                                        <tr className="router-loading-spinner">
                                            <td>
                                            <div className="router-loading-spinner">
                                                <TailSpin width={50} height={50} color="#002060" />
                                            </div>
                                            </td>
                                        </tr>
                                        :
                                        <tr>
                                            <td>
                                                <h5 className="text-center mt-5">Result Not found...</h5>
                                            </td>
                                        </tr> 
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="pagination-content">
                    <ul>
                        <Stack spacing={2}>
                            <Pagination onChange={(e, pn) => setCurrentPage(pn)} count={pageCount} />
                        </Stack>
                    </ul>
                    <div className="pageRight">
                        <span>Showing {events?.length} of {totalDataCount} items</span>
                    </div>
                </div>
            </div>
        </>
    )
}


const SingleRow = ({ event, onItemCheck }) => {

    return (
        <tr className={event.selected ? "selected" : ""}>
            <th scope="row">
                <input
                    type="checkbox"
                    checked={event.selected}
                    className="form-check-input"
                    id="rowcheck{user.id}"
                    onChange={(e) => onItemCheck(e, event)}
                />
            </th>
            <td className="td_event-id"><span>{event.id}</span></td>
            <td className="td_event-timestamp"><span>{event.timestamp ? event.timestamp : "null"}</span></td>
            <td className="logs"><span>{event?.logs || "Not Found"}</span></td>
            <td className="reset_pass td_event-id-user"><span>{event.email || "unknown"}</span></td>
        </tr>
    );
};

export default MobileLogsRows;