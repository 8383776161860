import React, { useEffect, useState } from "react";
import NavBar from "../NavBar/NavBar";
import { FaAngleLeft } from "react-icons/fa";
import reset from "../../images/icons/update.png";
import billing from "../../images/icons/billing.png";
import Cookies from 'js-cookie'
import { Link, useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import { TailSpin } from "react-loader-spinner";
import pp1 from "../../images//pp1.png";

const WallboxUser = () => {
  document.title = "Wallbox User - POWERGrid Cloud"
  const history = useHistory();
  const [user, setUser] = useState({})
  const param = useParams();
  const {user_id} = param;

  useEffect(() => {
    if (user_id) {
      axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/user_detail/${user_id}`, {
        headers: {
          authorization: "Bearer " + Cookies.get("access_token")
        }
      })
        .then(response => {
          if (response.data) {
            setUser(response.data.data)
          }
        })
        .catch(error => {
          console.error("Got Error", error)
        })
    }
  }, [user_id])


  const { Email } = user;

  const handleReset = (e, Email) => {
    e.preventDefault()
    const askToConfirm = window.confirm(`Are you sure want to Reset password of ${Email}`)
    if (askToConfirm) {

      const resetPasswordInfo = new URLSearchParams(Object.entries({
        Email: Email
      })).toString();
      axios.post(`${process.env.REACT_APP_USER_BACKEND_API_URL}/users/forgot_password`, resetPasswordInfo, {
        headers: {
          Authorization: "Bearer " + Cookies.get("access_token")
        }
      })
        .then(function (response) {
          window.alert(response.data)
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }

  const handleEditUser = () => {
    history.push(`/editwallboxuser/${user_id}`);
  }

  return (
    <>
      <NavBar />
      <main className="wrapper">
        <div className="page-content">
          <div className="wallbox-area">
            <div className="pages-title-list">
              <ul>
                <li>
                  <Link to="/users">All Users</Link>
                </li>
                <li>
                  <span>{user.FullName}</span>
                </li>
              </ul>
            </div>

            <div className="wallbox_single_content">
              <div className="single_all_list">
                <ul className="box_progress">
                  <li>
                    <Link to="/">
                      <FaAngleLeft /> Back to Wallboxes 
                    </Link>
                  </li>
                  <li>
                    <span>Users Functions</span>
                  </li>
                  <li>
                    <Link onClick={() => handleEditUser()} to="#">
                      <img alt="" src={reset} /> Edit User Details
                    </Link>
                  </li>
                  <li>
                    <Link onClick={(e) => handleReset(e, Email)} to="#">
                      <img alt="" src={reset} /> Reset Password
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <img alt="" src={billing} /> Billing Details
                    </Link>
                  </li>
                </ul>
                <ul className="box_progress">
                  {/* <li>Wallbox Progress</li> */}
                </ul>
              </div>
              <div className="details_cont" style={{ position: "relative" }}>
                {/* {!user.Email ? <div className="router-loading-spinner">
                  <TailSpin width={25} height={25} color="#002060" />
                </div> : */}
                  <div className="wallbox_details">
                    <div className="h5">User Details</div>
                    <div className="wallbox_details_content">
                      <form action="">
                        <div className="box_group">
                          <label>Name</label>
                          <input type="text" defaultValue={user.FullName} readOnly />
                        </div>
                        <div className="box_group">
                          <label> Phone </label>
                          <input type="text" defaultValue={user.PhoneNumber} readOnly />
                        </div>
                        <div className="box_group">
                          <label>E-mail</label>
                          <input type="text" defaultValue={user.Email} readOnly />
                        </div>
                        <div className="box_group">
                          <label>Address</label>
                          <input type="text" defaultValue={user.Address} readOnly />
                        </div>
                        <div className="box_group">
                          <label>Company Name</label>
                          <input type="text" defaultValue={user.CompanyName} readOnly />
                        </div>
                      </form>
                      <div className="box_right_img">
                        <img alt="" src={pp1} />
                        {/* <span>Status</span>
                        <span className={Confirmed === "active" ? "Running" : "inactive"}>{Confirmed}</span> */}
                      </div>
                    </div>
                  </div>
                {/* } */}
                <div className="user_wallbox">
                  <div className="h5">Roles in Environments</div>
                  <form action="">
                    <input type="search" placeholder="Search Product..." />
                  </form>
                </div>
              </div>
              <div className="allActivities">
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default WallboxUser;
