import React, { useState, useEffect } from "react";
import NavBar from "../NavBar/NavBar";
import "./Group.scss";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import Cookies from 'js-cookie';
import update from "../../images/icons/update.png";
import Delete from "../../images/icons/bin.png";
import { TailSpin } from "react-loader-spinner";
import SetFirmwareForm from "./SetFimwareVersionForm/SetFirmwareForm";
import { getPermission } from "../../utils";

const Group = () => {
  document.title = "Groups - POWERGrid Cloud";
  const [data, setData] = useState([]);
  const [isFetcing, setIsFetching] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [groupId, setGroupId] = useState("");

  const checkAddGroup = getPermission("add_group")
  const checkUpdateGroup = getPermission("update_group")
  const checkDeleteGroup = getPermission("delete_group")
  const checkSetFirmwareVersion = getPermission("set_firmware_version")
  const groupHistory = useHistory();
  const AddGroup = () => {
    groupHistory.push("/addgroup");
  }

  const groupList = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/group`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("access_token"),
      },
    })
    .then(function(response){
      if(response?.data?.success){
        setIsFetching(true);
        setData([...response.data.data]);
        setIsFetching(false);
      } else{
        setData([]);
        setIsFetching(false);
      }
    })
    .catch(function(error){
      console.log(error);
    })
  };

  const getGroupDataById = (id) => {
    groupHistory.push("/addgroup", {update: true, GroupId: id});
  }

  const deleteGroup = (deleteEntry) => {
    const askToConfirm = window.confirm(
      `Are you sure want to Delete this Entry`
    );
    if (askToConfirm) {
      axios
        .delete(
          `${process.env.REACT_APP_BACKEND_API_URL}/group/${deleteEntry}`,
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("access_token"),
            },
          }
        )
        .then(function (response) {
          window.alert(response?.data.message);
          groupList();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  const setFirmwareVersion = (group_id) => {
    setGroupId(group_id)
    setModalShow(true)
  }

  const handleFirmwareSubmit = (firmwareData) => {
    axios
    .get(
      `${process.env.REACT_APP_BACKEND_API_URL}/set_target_version_to_group`,
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("access_token"),
        },
        params: {
          "group_id": groupId,
          "firmware_version": firmwareData.version
        }
      }
    )
    .then(function (response) {
      setModalShow(false)
      window.alert(response?.data.message);
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  useEffect(() => {
    groupList();
  }, []);

  return (
    <>
      <NavBar />
      <main className="wrapper">
        <div className="page-content">
          <div className="wallbox-area">
            <div className="pages-title">
              <div className="h2">Groups</div>
              <span>View All Groups</span>
            </div>

            <div className="search-filter">
              <div style={{ width: "90px" }} scope="col" className="serialT">
                {checkAddGroup == true ?
                <button
                className="btn-yellpow"
                onClick={AddGroup}>Add Group</button>
                :
                null
                }
              </div>
            </div>
            <div className="table-componet" style={{ display: "contents" }}>
              <div className="col-md-12">
                <div className="col-md-12">
                  <div className="tab__content mt-2">
                    <table className="table box-table">
                      <thead>
                        <tr>
                          <th
                            style={{ width: "150px" }}   

                            scope="col"
                            className="serialT"
                          >
                            <h6>
                              <b> ID</b>
                            </h6>
                          </th>
                          <th scope="col" className="serialT">
                            <h6>
                              <b> Group Name</b>
                            </h6>
                          </th>
                          <th scope="col" className="serialT">
                            <h6>
                              <b> Firmware version</b>
                            </h6>
                          </th>
                          {checkUpdateGroup || checkDeleteGroup == true ?
                          <th scope="col" className="serialT">
                            <h6>
                              <b> Actions</b>
                            </h6>
                          </th>
                          :
                          null
                          }
                          <th
                            style={{ width: "58px" }}
                            scope="col"
                            className="serialT"
                          ></th>
                        </tr>
                      </thead>
                      <tbody>
                      {data.length > 0 ?
                        (
                          data?.map((item, i) => {
                            return (
                              <tr key={i} style={{ fontSize: "13px" }} className="border-bottom">
                                <td>{item?.id}</td>
                                <td>{item?.name}</td>
                                <td>
                                  {checkSetFirmwareVersion == true ?
                                  <button
                                        className="firmwareButton"
                                        onClick={() =>
                                          setFirmwareVersion(
                                            item?.id
                                          )
                                        }
                                      >
                                        Set firmware version
                                      </button>
                                      :
                                      null
                                    }
                                </td>
                                <td className="Actions">
                                    {checkUpdateGroup == true ?
                                      <button
                                        className="actionButton"
                                        onClick={() =>

                                          getGroupDataById(
                                            item?.id
                                          )
                                        }
                                      >
                                        <img alt="" src={update}  /> Update
                                      </button>
                                      :
                                      null
                                    }
                                    
                                    {checkDeleteGroup == true ?
                                      <button
                                        className="actionButton"
                                        style={{ margin: "5px" }}
                                        onClick={() => deleteGroup(item?.id)} 
                                      >
                                        <img alt="" src={Delete} /> Delete
                                      </button>
                                      :
                                      null
                                    }
                                    <button></button>
                                </td>
                              </tr>
                            );
                          })
                        )
                        :
                        isFetcing == true ?
                        (
                          <tr>
                            <td>
                              <div className="router-loading-spinner">
                                <TailSpin width={50} height={50} color="#002060" />
                              </div>
                            </td>
                          </tr>
                        )
                        :
                        <h5 className="text-center mt-5">Result Not found...</h5>
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SetFirmwareForm
          show={modalShow}
          onHide={() => setModalShow(false)}
          // data={message}
          callBackSubmit={handleFirmwareSubmit}
        />
      </main>
    </>
  );
};

export default Group;