import { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";

function AddPermissionForm(props) {
  const [permissionData, setPermissionData] = useState({});
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (props?.data) {
      setPermissionData(props.data);
    }
  }, [props]);

  const handleRegister = (e) => {
    e.preventDefault();
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div
          className="login-form"
          style={{ height: "auto", padding: "0 20px" }}
        >
          <div className="logo-head">
            {/* <div className="logo-2">
              <img src={logoCloud} alt="" />
            </div> */}
            <div className="h4">Add a new permission </div>
            <form onSubmit={handleRegister}>
              <div className="form-group">
                <label className="emails">
                  <span>*</span> App
                </label>
                <input
                  required
                  onChange={(e) =>
                    setPermissionData({
                      ...permissionData,
                      App: e.target.value,
                    })
                  }
                  type="text"
                  // value={permissionData?.app}
                />
              </div>
              <div className="form-group">
                <label className="FirstName">
                  <span>*</span> AddIn
                </label>
                <input
                  required
                  onChange={(e) =>
                    setPermissionData({
                      ...permissionData,
                      AddIn: e.target.value,
                    })
                  }
                  type="text"
                  // value={permissionData?.wallbox_serial_number}
                />
              </div>
              <div className="form-group">
                <label className="FirstName">
                  <span>*</span> Component
                </label>
                <input
                  required
                  onChange={(e) =>
                    setPermissionData({
                      ...permissionData,
                      Component: e.target.value,
                    })
                  }
                  type="text"
                  // value={chargeTransactionData?.wallbox_serial_number}
                />
              </div>
              <div className="form-group">
                <label className="FirstName">
                  <span>*</span> Permission Name
                </label>
                <input
                  required
                  onChange={(e) =>
                    setPermissionData({
                      ...permissionData,
                      PermissionName: e.target.value,
                    })
                  }
                  type="text"
                  // value={chargeTransactionData?.wallbox_serial_number}
                />
              </div>
              <div className="form-group">
                <label className="FirstName">
                  <span>*</span> Permission Value
                </label>
                <input
                  required
                  onChange={(e) =>
                    setPermissionData({
                      ...permissionData,
                      PermissionValue: e.target.value,
                    })
                  }
                  type="text"
                  // value={chargeTransactionData?.wallbox_serial_number}
                />
              </div>
              <div className="form-group">
                <button
                  type="submit"
                  className="subBtn"
                  onClick={() => props.callBackSubmit(permissionData)}
                >
                  <i className="fa-solid fa-unlock"></i> Submit
                </button>
              </div>
            </form>
            <div className="message">
              <p className="mt-2" style={{ color: "gray", fontWeight: "bold" }}>
                {message}
              </p>
            </div>
          </div>
          {/* <div className="logo-footer mt-5">
            <img src={logo2} alt="" />
          </div> */}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default AddPermissionForm;
