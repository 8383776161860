import React, { useEffect, useState } from "react";
import axios from "axios";
import NavBar from "../../NavBar/NavBar";
import Cookies from 'js-cookie'
import { TailSpin } from "react-loader-spinner";
import update from "../../../images/icons/update.png";
import Delete from "../../../images/icons/bin.png";
import { useHistory } from "react-router-dom";

const ListCarProfile = () => {

    document.title = "Car Profile - POWERGrid Cloud"

    const [data, setData] = useState([]);
    // const [user, setUser] = useState();
    const [isFetcing, setIsFetching] = useState(true);

    const history = useHistory();
    const AddCarProfile = () => {
        history.push("/addcarprofile");
    }

    const getCarProfileList = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/car`, {
            headers: {
              Authorization: "Bearer " + Cookies.get("access_token"),
            },
        })
        .then(function (response) {
        if (response?.data) {
            setIsFetching(true);
            setData([...response.data]);
            setIsFetching(false);
        } else {
            setData([]);
            setIsFetching(false);
        }
        })
        .catch(function (error) {
        console.log(error);
        });
    };

    // const getUserDetailById = () => {
    //     const userId = data.map((item)=>item.created_by)
    //     axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/user_detail/${userId}`, {
    //         headers: {
    //             Authorization: "Bearer " + Cookies.get("access_token"),
    //     },
    //     })
    //     .then(function (response) {
    //         if (response?.data) {
    //             setUser(response?.data.data);
    //         }
    //     })
    //     .catch(function (error) {
    //         console.log(error);
    //     });
    // };

    const deleteCarProfile = (deleteEntry) => {
        const askToConfirm = window.confirm(
          `Are you sure want to Delete this Entry`
        );
        if (askToConfirm) {
          axios
            .delete(
              `${process.env.REACT_APP_BACKEND_API_URL}/car/${deleteEntry}`,
              {
                headers: {
                  Authorization: "Bearer " + Cookies.get("access_token"),
                },
              }
            )
            .then(function (response) {
              window.alert("Successfully Deleted");
              getCarProfileList();
            })
            .catch(function (error) {
              console.log(error);
            });
        }
    };

    const getCarProfileDataById = (id) => {
        history.push("/addcarprofile", {update: true, carProfileId: id});
    }

    useEffect(() => {
        getCarProfileList();
    },[]);

    return (
        <>
        <NavBar />
        <main className="wrapper">
            <div className="page-content">
                <div className="wallbox-area">
                    <div className="pages-title">
                        <div className="h2">Car Profile</div>
                        <span>View all Car Profiles</span>
                    </div>
                    <div className="search-filter">
                        <div style={{ width: "160px" }} scope="col" className="serialT">
                            <button
                            className="btn-yellpow"
                            onClick={AddCarProfile}
                            >
                                Add CarProfile
                            </button>
                        </div>           
                    </div>
                    <div className="table-componet" style={{ display: "contents" }}>
                        <div className="col-md-12">
                            <div className="col-md-12">
                                <div className="tab__content mt-2">
                                    <table className="table box-table">
                                        <thead>
                                            <tr>
                                                <th
                                                scope="col"
                                                className="serialT"
                                                >
                                                    <h6>
                                                        <b> ID</b>
                                                    </h6>
                                                </th>
                                                {/* <th scope="col" className="serialT">
                                                    <h6>
                                                    <b> Identity</b>
                                                    </h6>
                                                </th> */}
                                                {/* <th scope="col" className="serialT">
                                                    <h6>
                                                    <b> Domain</b>
                                                    </h6>
                                                </th> */}
                                                <th scope="col" className="serialT">
                                                    <h6>
                                                    <b> Company Car</b>
                                                    </h6>
                                                </th>
                                                {/* <th scope="col" className="serialT">
                                                    <h6>
                                                    <b> Box Serial Number</b>
                                                    </h6>
                                                </th> */}
                                                <th scope="col" className="serialT">
                                                    <h6>
                                                    <b> Licence Plate</b>
                                                    </h6>
                                                </th>
                                                {/* <th scope="col" className="serialT">
                                                    <h6>
                                                    <b> Certificate</b>
                                                    </h6>
                                                </th> */}
                                                <th scope="col" className="serialT">
                                                    <h6>
                                                    <b> Created By</b>
                                                    </h6>
                                                </th>
                                                <th scope="col" className="serialT">
                                                    <h6>
                                                    <b> Assigned To</b>
                                                    </h6>
                                                </th>
                                                <th scope="col" className="serialT">
                                                    <h6>
                                                    <b> Make</b>
                                                    </h6>
                                                </th>
                                                <th scope="col" className="serialT">
                                                    <h6>
                                                    <b> Model</b>
                                                    </h6>
                                                </th>
                                                <th scope="col" className="actionsT">
                                                    <h6>
                                                    <b> Actions</b>
                                                    </h6>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {data.length > 0 ?
                                            (
                                            data?.map((item, i) => {
                                                return (
                                                <tr key={i} style={{ fontSize: "13px" }}>
                                                    <td>{item?.id}</td>
                                                    {/* <td>{item?.identity}</td> */}
                                                    {/* <td>{item?.domain}</td> */}
                                                    <td>
                                                    <input className="form-check-input" type="checkbox" checked={item?.company_car ? true : false} id="check1"/>
                                                    </td>
                                                    {/* <td>{item?.box_serial_number}</td> */}
                                                    <td>{item?.license_plate}</td>
                                                    {/* <td>{item?.certificate}</td> */}
                                                    <td>{item?.created_by}</td>
                                                    <td>{item?.assigned_to}</td>
                                                    <td>{item?.make}</td>
                                                    <td>{item?.model}</td>
                                                    <td className="Actions">
                                                        <button onClick={() => getCarProfileDataById(item?.id)}>
                                                            <img alt="" src={update}  /> Update
                                                        </button>
                                                        
                                                        <button style={{ margin: "3px" }} onClick={() => deleteCarProfile(item?.id)}>
                                                            <img alt="" src={Delete} /> Delete
                                                        </button>
                                                        <button></button>
                                                    </td>
                                                </tr>
                                                );
                                            })
                                            )
                                            :
                                            isFetcing == true ?
                                            (
                                            <tr>
                                                <td>
                                                    <div className="router-loading-spinner">
                                                        <TailSpin width={50} height={50} color="#002060" />
                                                    </div>
                                                </td>
                                            </tr>
                                            )
                                            :
                                            <h5 className="text-center mt-5">Result Not found...</h5>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        </>
    );
};


export default ListCarProfile;