import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import WallboxCommentForm from "./WallboxCommentForm";
import { useHistory } from "react-router-dom";

const initState = { id: 0, comment: "", updated_at: 0 };

const WallboxComment = () => {

    const [data, setData] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [wallboxComment, setWallboxComment] = useState(initState);
    const [message, setMessage] = useState("")
    
    const params = useParams();
    const { wallboxId } = params;

    const getWallboxComment = () => {
        if (wallboxId) {
          axios
            .get(`${process.env.REACT_APP_BACKEND_API_URL}/wallbox_comment/${wallboxId}`, {
              headers: {
                authorization: "Bearer " + Cookies.get("access_token"),
              },
            })
            .then((response) => {
              if (response.data.data) {
                setData(response.data.data);
              }
            })
            .catch((error) => {
              console.error("Got Error", error);
            });
        }
    };


    const deleteRow = (deleteEntry) => {
        const askToConfirm = window.confirm(
          `Are you sure want to Delete this Entry`
        );
        if (askToConfirm) {
        axios
        .delete(
            `${process.env.REACT_APP_BACKEND_API_URL}/wallbox_comment?id=${deleteEntry}`,
            {
            headers: {
                Authorization: "Bearer " + Cookies.get("access_token"),
            },
            }
        )
        .then(function (response) {
            window.alert(response?.data?.message);
            getWallboxComment();
        })
        .catch(function (error) {
            console.log(error);
        });
    }
    };

    const getCommentById = (id) => {
    axios
        .get(`${process.env.REACT_APP_BACKEND_API_URL}/wallbox_comment_id/${id}`, {
        headers: {
            Authorization: "Bearer " + Cookies.get("access_token"),
        },
        })
        .then(function (response) {
        if (response?.data.data) {
            setWallboxComment(response.data.data);
            setModalShow(true);
        }
        })
        .catch(function (error) {
        console.log(error);
        });
    };

    const addComment = (data) => {
    let commentData = {}
    commentData["comment"] = data.comment
    commentData["serial_number"] = wallboxId
    axios
        .post(`${process.env.REACT_APP_BACKEND_API_URL}/wallbox_comment`, commentData, {
        headers: {
            Authorization: "Bearer " + Cookies.get("access_token"),
        },
        })
        .then(function (response) {
            setModalShow(false);
            if (response?.data.success) {
                getWallboxComment();
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    };

    const updateComment = (data) => {
    axios
        .put(`${process.env.REACT_APP_BACKEND_API_URL}/wallbox_comment`, data, {
        headers: {
            Authorization: "Bearer " + Cookies.get("access_token"),
        },
        })
        .then(function (response) {
            setModalShow(false);
            if (response?.data.success) {
                getWallboxComment();
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    };

    const handleSubmit = (data) => {
    if (data?.id) {
        updateComment(data);
    } else {
        addComment(data);
    }
    };
    
    useEffect(() => {
        getWallboxComment();
    }, [wallboxId]);

    return (
        <main>
        <div className='userBox'>
            <div className='userTable_con'>
                <table className="table box-table">
                    <thead>
                        <tr>
                            <th scope="col" className="user_name">Comment</th>
                            <th scope="col" className="updated_at">Updated At</th>
                            <td>
                                <button 
                                className="btn-yellpow" 
                                onClick={() => { setWallboxComment(initState); setModalShow(true); }}>
                                    Add Comment
                                </button>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((elem, index)=>{
                            return(
                                <tr key={index}>
                                    <td className="name"><span>{elem.comment}</span></td>
                                    <td className="updated_at"><span>{elem.updated_at}</span></td>
                                    <td className="edit"><button onClick={() =>
                                          getCommentById(
                                            elem?.id
                                          )
                                        }>
                                    <span ><AiFillEdit /></span></button></td>
                                    <td className="delete"><button onClick={() => deleteRow(elem?.id)}><span ><AiFillDelete /></span></button></td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
        <WallboxCommentForm
          show={modalShow}
          onHide={() => setModalShow(false)}
          data={wallboxComment}
          callbacksubmit={handleSubmit}
        />
      </main>
    )
}

export default WallboxComment;