import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';
import axios from "axios"
import Cookies from 'js-cookie'
import NavBar from "../NavBar/NavBar";
import filterAdd from "../../images/icons/filter-add.png";
import userI from "../../images/icons/user.png";
import rolesI from "../../images/icons/roles.png";
import ppermiI from "../../images/icons/ppermi.png";
import { AiOutlineSearch, AiOutlinePlus } from "react-icons/ai";
import { getPermission } from "../../utils";


import "./User.scss";
import { Link } from "react-router-dom";
import UserRows from "./UserRows";
import MyVerticallyCenteredModal from "./SignUpModal";


const User = () => {
    document.title = "User - POWERGrid Cloud"
    const [users, setUsers] = useState([])
    const [isFetcing, setIsFetching] = useState(true)
    const [modalShow, setModalShow] = React.useState(false);
    const [sorting, setSorting] = useState("ID")
    const [searchValue, setSearchValue] = useState("")

    const checkAddUser = getPermission("add_user")

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_USER_BACKEND_API_URL}/users/user_table?search_string=${searchValue}&order=${sorting}`, {
            headers: {
                Authorization: "Bearer " + Cookies.get("access_token")
            }
        })
            .then(function (response) {
                if (response.data) {
                    setUsers(response.data)
                }
            })
            .catch(function (error) {
                setIsFetching(false)
                console.log(error);
            })
            .then(() => {
                setIsFetching(false)
            })
    }, [sorting, searchValue])




    return (
        <>
            <NavBar />
            <main className="wrapper">
                <div className="page-content">
                    <div className="wallbox-area">
                        <div className="pages-title">
                            <div className="h2">All Users</div>
                            <span>View all users in one table</span>
                        </div>
                        <div className="user__content">
                            <div className="user__data">
                                <ul className="panel_section">
                                    <li>
                                        <span>Panel Selection</span>
                                    </li>
                                    <li>
                                        <Link to="/users" className="active"><img alt="" src={userI} /> Users</Link>
                                    </li>
                                    <li>
                                        <Link to="/roles" ><img alt="" src={rolesI} /> Roles</Link>
                                    </li>
                                    <li>
                                        <Link to="/permissions" ><img alt="" src={ppermiI} /> Permissions</Link>
                                    </li>
                                    <li className="additional_tt">
                                        <span>Additional Functions</span>
                                    </li>
                                    {checkAddUser == true ?
                                    <li>
                                        <button onClick={() => setModalShow(true)}><AiOutlinePlus />Add new User</button>
                                    </li>
                                    :
                                    null
                                    }
                                </ul>
                            </div>
                            <div className="user__data">
                                <div className="search-filter">
                                    <form action="">
                                        <input onChange={e => setSearchValue(e.target.value)} type="search" placeholder="Search Users..." />
                                        <button type="submit"><AiOutlineSearch /></button>
                                    </form>
                                    <div className="sort-date">
                                        Sort by:
                                        <Form.Select onChange={(e) => setSorting(e.target.value)} aria-label="Default select example">
                                            <option value="ID">ID</option>
                                            <option value="FirstName">Name</option>
                                        </Form.Select>
                                    </div>
                                    <div className="filter">
                                        Filter <img alt="" src={filterAdd} />
                                    </div>
                                    {/* <ul className="sort_check">
                                        <li>
                                            <div className="check">
                                                <span className="close">&#10006;</span>
                                                <span>User: Jonas Mossler</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="check">
                                                <span className="close">&#10006;</span>
                                                <span>Konto: Geschäftlich</span>
                                            </div>
                                        </li>
                                    </ul> */}
                                </div>
                                <div className="wallbox-tabl">
                                    <UserRows users={users} isFetcing={isFetcing} />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <MyVerticallyCenteredModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                />
            </main>
        </>
    );
};



export default User;
