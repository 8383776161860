import React from "react";

import search from "../../../images/icons/search.png";
import ster from "../../../images/icons/ster.png";
import full from "../../../images/icons/full.png";

import { BsChevronDoubleRight } from "react-icons/bs";

import { Link } from "react-router-dom";
import { Tab, Nav } from "react-bootstrap";

const Users = [
    {
        id: 1,
        selected: false,
        wallboxSerial: "POJ-103949543129",
        Activity: "Pinned Tasks",
        Date: "2021-10-26",
        Time: "07:58:30",
        Description: "Julian Leudesdorff ",
        Tasks: "pinned 7 tasks ",
    },
    {
        id: 2,
        selected: false,
        wallboxSerial: "POJ-103949543129",
        Activity: "Pinned Tasks",
        Date: "2021-10-26",
        Time: "07:58:30",
        Description: "Julian Leudesdorff ",
        Tasks: "pinned 7 tasks ",
    },
    {
        id: 3,
        selected: false,
        wallboxSerial: "POJ-103949543129",
        Activity: "Pinned Tasks",
        Date: "2021-10-26",
        Time: "07:58:30",
        Description: "Julian Leudesdorff ",
        Tasks: "pinned 7 tasks ",
    },
    {
        id: 4,
        selected: false,
        wallboxSerial: "POJ-103949543129",
        Activity: "Pinned Tasks",
        Date: "2021-10-26",
        Time: "07:58:30",
        Description: "Julian Leudesdorff ",
        Tasks: "pinned 7 tasks ",
    },
    {
        id: 5,
        selected: false,
        wallboxSerial: "POJ-103949543129",
        Activity: "Pinned Tasks",
        Date: "2021-10-26",
        Time: "07:58:30",
        Description: "Julian Leudesdorff ",
        Tasks: "pinned 7 tasks ",
    },
    {
        id: 6,
        selected: false,
        wallboxSerial: "POJ-103949543129",
        Activity: "Pinned Tasks",
        Date: "2021-10-26",
        Time: "07:58:30",
        Description: "Julian Leudesdorff ",
        Tasks: "pinned 7 tasks ",
    },
    {
        id: 7,
        selected: false,
        wallboxSerial: "POJ-103949543129",
        Activity: "Pinned Tasks",
        Date: "2021-10-26",
        Time: "07:58:30",
        Description: "Julian Leudesdorff ",
        Tasks: "pinned 7 tasks ",
    },
    {
        id: 8,
        selected: false,
        wallboxSerial: "POJ-103949543129",
        Activity: "Pinned Tasks",
        Date: "2021-10-26",
        Time: "07:58:30",
        Description: "Julian Leudesdorff ",
        Tasks: "pinned 7 tasks ",
    },
    {
        id: 9,
        selected: false,
        wallboxSerial: "POJ-103949543129",
        Activity: "Pinned Tasks",
        Date: "2021-10-26",
        Time: "07:58:30",
        Description: "Julian Leudesdorff ",
        Tasks: "pinned 7 tasks ",
    },
    {
        id: 10,
        selected: false,
        wallboxSerial: "POJ-103949543129",
        Activity: "Pinned Tasks",
        Date: "2021-10-26",
        Time: "07:58:30",
        Description: "Julian Leudesdorff ",
        Tasks: "pinned 7 tasks ",
    },
    {
        id: 11,
        selected: false,
        wallboxSerial: "POJ-103949543129",
        Activity: "Pinned Tasks",
        Date: "2021-10-26",
        Time: "07:58:30",
        Description: "Julian Leudesdorff ",
        Tasks: "pinned 7 tasks ",
    },
    {
        id: 12,
        selected: false,
        wallboxSerial: "POJ-103949543129",
        Activity: "Pinned Tasks",
        Date: "2021-10-26",
        Time: "07:58:30",
        Description: "Julian Leudesdorff ",
        Tasks: "pinned 7 tasks ",
    },
    {
        id: 13,
        selected: false,
        wallboxSerial: "POJ-103949543129",
        Activity: "Pinned Tasks",
        Date: "2021-10-26",
        Time: "07:58:30",
        Description: "Julian Leudesdorff ",
        Tasks: "pinned 7 tasks ",
    },
    {
        id: 14,
        selected: false,
        wallboxSerial: "POJ-103949543129",
        Activity: "Pinned Tasks",
        Date: "2021-10-26",
        Time: "07:58:30",
        Description: "Julian Leudesdorff ",
        Tasks: "pinned 7 tasks ",
    },
    {
        id: 15,
        selected: false,
        wallboxSerial: "POJ-103949543129",
        Activity: "Pinned Tasks",
        Date: "2021-10-26",
        Time: "07:58:30",
        Description: "Julian Leudesdorff ",
        Tasks: "pinned 7 tasks ",
    },
    {
        id: 16,
        selected: false,
        wallboxSerial: "POJ-103949543129",
        Activity: "Pinned Tasks",
        Date: "2021-10-26",
        Time: "07:58:30",
        Description: "Julian Leudesdorff ",
        Tasks: "pinned 7 tasks ",
    },
    {
        id: 17,
        selected: false,
        wallboxSerial: "POJ-103949543129",
        Activity: "Pinned Tasks",
        Date: "2021-10-26",
        Time: "07:58:30",
        Description: "Julian Leudesdorff ",
        Tasks: "pinned 7 tasks ",
    },
    {
        id: 18,
        selected: false,
        wallboxSerial: "POJ-103949543129",
        Activity: "Pinned Tasks",
        Date: "2021-10-26",
        Time: "07:58:30",
        Description: "Julian Leudesdorff ",
        Tasks: "pinned 7 tasks ",
    },
    {
        id: 19,
        selected: false,
        wallboxSerial: "POJ-103949543129",
        Activity: "Pinned Tasks",
        Date: "2021-10-26",
        Time: "07:58:30",
        Description: "Julian Leudesdorff ",
        Tasks: "pinned 7 tasks ",
    },

];

// Sorting function
const sortedList = (data, order, item) => {
    if (order === 'up') {
        return data.sort((a, b) => (a[item] ? 1 : -1));
    }
    if (order === 'down') {
        return data.sort((a, b) => (a[item] ? -1 : 1));
    }
}

class Functions extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            List: Users,
            MasterChecked: false,
            SelectedList: [],
            show: false,
            currentSort: 'up'
        };
    }

    handleModal() {
        this.setState({ show: !this.state.show })
    }

    // Select/ UnSelect Table rows
    onMasterCheck(e) {
        let tempList = this.state.List;
        // Check/ UnCheck All Items
        tempList.map((user) => (user.selected = e.target.checked));

        //Update State
        this.setState({
            MasterChecked: e.target.checked,
            List: tempList,
            SelectedList: this.state.List.filter((e) => e.selected),
        });
    }

    // Update List Item's state and Master Checkbox State
    onItemCheck(e, item) {
        let tempList = this.state.List;
        tempList.map((user) => {
            if (user.id === item.id) {
                user.selected = e.target.checked;
            }
            return user;
        });

        //To Control Master Checkbox State
        const totalItems = this.state.List.length;
        const totalCheckedItems = tempList.filter((e) => e.selected).length;

        // Update State
        this.setState({
            MasterChecked: totalItems === totalCheckedItems,
            List: tempList,
            SelectedList: this.state.List.filter((e) => e.selected),
        });
    }

    // Event to get selected rows(Optional)
    getSelectedRows() {
        this.setState({
            SelectedList: this.state.List.filter((e) => e.selected),
        });
    }



    render() {
        return (
            <>

                <div className='activity_box'>
                    {/* <ul className="activity_LL">
                        <li className="active"><Link to='#'><img src={ster} /> All Events</Link></li>
                        <li><Link to='#'><img src={full} /> Charging Events</Link></li>
                    </ul> */}
                    <Tab.Container defaultActiveKey="first">
                        <Nav variant="pills" className="activity_LL">
                            <Nav.Item>
                                <Nav.Link eventKey="first"><img alt="" src={ster} /> All Events</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="second"><img alt="" src={full} /> Charging Events</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                                <div className="activity__content">
                                    <table className="table box-table">
                                        <thead>
                                            <tr>
                                                <th scope="col">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        checked={this.state.MasterChecked}
                                                        id="mastercheck"
                                                        onChange={(e) => this.onMasterCheck(e)}
                                                    />
                                                </th>
                                                <th scope="col" className="Timestamp_s">Timestamp</th>
                                                <th scope="col" className="Activity_s">Activity</th>
                                                <th scope="col" className="Description_s">Description </th>
                                                <th scope="col" className="Environment_s">Environment</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {sortedList(this.state.List, this.state.currentSort, 'id').map((user) => (
                                                <tr key={user.id} className={user.selected ? "selected" : ""}>
                                                    <th scope="row">
                                                        <input
                                                            type="checkbox"
                                                            checked={user.selected}
                                                            className="form-check-input"
                                                            id="rowcheck{user.id}"
                                                            onChange={(e) => this.onItemCheck(e, user)}
                                                        />
                                                    </th>
                                                    <td className="Timestamp"><span>{user.Date} <time>{user.Time}</time></span></td>
                                                    <td className="Activity"><span>{user.Activity}</span></td>
                                                    <td className="Description"><span><Link to='#'>{user.Description}</Link> <text>{user.Tasks}</text></span></td>
                                                    <td className="wallboxSerial"><span><Link to="/wallbox_single">{user.wallboxSerial}</Link></span></td>
                                                    <td className="search_btns">
                                                        <span>
                                                            <button><img alt="" src={search} /></button>
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <div className="activity__content">
                                    <table className="table box-table">
                                        <thead>
                                            <tr>
                                                <th scope="col">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        checked={this.state.MasterChecked}
                                                        id="mastercheck"
                                                        onChange={(e) => this.onMasterCheck(e)}
                                                    />
                                                </th>
                                                <th scope="col" className="Timestamp_s">Timestamp</th>
                                                <th scope="col" className="Activity_s">Activity</th>
                                                <th scope="col" className="Description_s">Description </th>
                                                <th scope="col" className="Environment_s">Environment</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {sortedList(this.state.List, this.state.currentSort, 'id').map((user) => (
                                                <tr key={user.id} className={user.selected ? "selected" : ""}>
                                                    <th scope="row">
                                                        <input
                                                            type="checkbox"
                                                            checked={user.selected}
                                                            className="form-check-input"
                                                            id="rowcheck{user.id}"
                                                            onChange={(e) => this.onItemCheck(e, user)}
                                                        />
                                                    </th>
                                                    <td className="Timestamp"><span>{user.Date} <time>{user.Time}</time></span></td>
                                                    <td className="Activity"><span>{user.Activity}</span></td>
                                                    <td className="Description"><span><Link to='#'>{user.Description}</Link> <text>{user.Tasks}</text></span></td>
                                                    <td className="wallboxSerial"><span><Link to="/wallbox_single">{user.wallboxSerial}</Link></span></td>
                                                    <td className="search_btns">
                                                        <span>
                                                            <button><img alt="" src={search} /></button>
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>

                    <div className="pagination-content">
                        <ul>
                            <li>1</li>
                            <li className="active">2</li>
                            <li>3</li>
                            <li>4</li>
                            <li><BsChevronDoubleRight /></li>
                        </ul>
                        <div className="pageRight">
                            <span>Showing 20 of 320 items</span>
                        </div>
                    </div>
                </div>


            </>
        );
    }
}

export default Functions;