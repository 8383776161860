import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';
import NavBar from "../NavBar/NavBar";
import filterAdd from "../../images/icons/filter-add.png";
import { AiOutlineSearch } from "react-icons/ai";
import "./wallbox.scss";
import WallBoxes from "./WallBoxes";
import axios from "axios";
import Cookies from 'js-cookie'
import { useHistory, useLocation } from "react-router-dom";
import { getPermission } from "../../utils";


const Wallbox = () => {
    document.title = "Dashboard - POWERGrid Cloud"

    const [isFetcing, setIsFetching] = useState(true)
    const [totalWallBoxCount, setTotalWallBoxCount] = useState(1)
    const [pageCount, setPageCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [allWallBoxes, setAllWallBoxes] = useState([])
    const [sorting, setSorting] = useState("created_at")
    const [orderWay, setOrderWay] = useState("desc")
    const [displaySize, setDisplaySize] = useState(20)
    const [searchValue, setSearchValue] = useState("")
    const [isNotFound, setIfNotFound] = useState(false)

    const history = useHistory();
    const AddWallbox = () => {
        history.push("/addwallbox");
    }

    const location = useLocation();
    const isTrueListState = location.state;

    const checkAddWallbox = getPermission("add_wallbox")

    const onOrderChange = () => {
        if (orderWay === "desc") {
            setOrderWay("asc")
        } else {
            setOrderWay("desc")
        }
    }

    const handleFormOnSubmit = (e) => {
        e.preventDefault()
    }

    let cancelToken;

    if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Cancelling the previous req")
    }

    cancelToken = axios.CancelToken.source()

    const wallboxDataInfo = () => {
        if (isTrueListState?.online_wallbox){
            axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/wallbox_info?page=${currentPage}&page_size=${displaySize}&order=${sorting}&order_way=${orderWay}&search_string=${searchValue}&search_list=is_true`, {
                headers: {
                    Authorization: "Bearer " + Cookies.get("access_token")
                }
            }
            )
            .then(response => {
                const data = response?.data;
                if (data?.success){
                    const walloxes = data?.data.data
                    const totalWallbox = data?.data?.total_count
                    if (totalWallBoxCount){
                        setTotalWallBoxCount(totalWallbox)
                        setPageCount(Math.ceil(totalWallbox / displaySize))
                    }

                    if (walloxes) {
                        setIsFetching(false)
                        walloxes.forEach(element => {
                            element.selected = false
                        });
                        setAllWallBoxes(walloxes)
                    }

                    if (walloxes.length === 0 && currentPage !== 1) {
                        setCurrentPage(1)
                    }

                    if (totalWallbox == 0 || totalWallbox == null) {
                        setIfNotFound(true)
                    } else {
                        setIfNotFound(false)
                    }
                } else {
                    setAllWallBoxes([])
                    setIfNotFound(true)
                    setIsFetching(false)
                }
            })
            .catch(error => {
                console.log(error)
            })
        }else{
            axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/wallbox_info?page=${currentPage}&page_size=${displaySize}&order=${sorting}&order_way=${orderWay}&search_string=${searchValue}`, {
                headers: {
                    Authorization: "Bearer " + Cookies.get("access_token")
                }
            }
            )
            .then(response => {
                const data = response?.data;
                if (data?.success){
                    const walloxes = data?.data.data
                    const totalWallbox = data?.data?.total_count
                    if (totalWallBoxCount){
                        setTotalWallBoxCount(totalWallbox)
                        setPageCount(Math.ceil(totalWallbox / displaySize))
                    }

                    if (walloxes) {
                        setIsFetching(false)
                        walloxes.forEach(element => {
                            element.selected = false
                        });
                        setAllWallBoxes(walloxes)
                    }

                    if (walloxes.length === 0 && currentPage !== 1) {
                        setCurrentPage(1)
                    }

                    if (totalWallbox == 0 || totalWallbox == null) {
                        setIfNotFound(true)
                    } else {
                        setIfNotFound(false)
                    }
                } else {
                    setAllWallBoxes([])
                    setIfNotFound(true)
                    setIsFetching(false)
                }
            })
            .catch(error => {
                console.log(error)
            })
        }
    }

    useEffect(()=>{
        wallboxDataInfo();
        setIsFetching(true)
    }, [currentPage, sorting, orderWay, displaySize, searchValue])

    return (
        <>
            <NavBar />
            <main className="wrapper">
                <div className="page-content">
                    <div className="wallbox-area">
                        <div className="pages-title">
                            <div className="h2">Wallboxes</div>
                            <span>Alle produzierten Artikel</span>
                        </div>

                        <div className="search-filter">

                            <form onSubmit={handleFormOnSubmit}>
                                <input onChange={e => setSearchValue(e.target.value)} type="text" placeholder="Search Wallboxes" />
                                <button type="submit"><AiOutlineSearch /></button>
                            </form>

                            <div className="sort-date">
                                Sort by:
                                <Form.Select onChange={(e) => setSorting(e.target.value)} aria-label="Default select example">
                                    <option value="created_at">Created At</option>
                                    <option value="id">ID</option>
                                    <option value="serial_number">Serial Number</option>
                                </Form.Select>
                            </div>

                            <div className="sort-date">
                                Display size:
                                <Form.Select defaultValue={20} onChange={e => setDisplaySize(e.target.value)} aria-label="Default select example">
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                </Form.Select>
                            </div>

                            <div className="sort-date">
                                Order way:
                                <Form.Select defaultValue={"Descending"} onChange={onOrderChange} aria-label="Default select example">
                                    <option>Ascending</option>
                                    <option>Descending</option>
                                </Form.Select>
                            </div>

                            <div className="filter">
                                Filter <img alt="" src={filterAdd} />
                            </div>
                            {checkAddWallbox == true?
                            <button className="btn-yellpow1" onClick={AddWallbox}>Add Wallbox</button>
                            :
                            null
                            }
                            {isTrueListState?.online_wallbox ?
                            <button className="btn-yellpow1" onClick={history.goBack}>Back</button>
                            :
                            null
                            }
                        </div>
                        <div className="wallbox-tabl">
                            {
                                isNotFound && !isFetcing ?
                                    <h5 className="text-center mt-5">Result Not found...</h5>
                                    :
                                    <WallBoxes
                                        isFetcing={isFetcing}
                                        setIsFetching={setIsFetching}
                                        totalWallBoxCount={totalWallBoxCount}
                                        setTotalWallBoxCount={setTotalWallBoxCount}
                                        pageCount={pageCount}
                                        setPageCount={setPageCount}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        allWallBoxes={allWallBoxes}
                                        setAllWallBoxes={setAllWallBoxes}
                                        onOrderChange={onOrderChange}
                                        orderWay={orderWay}
                                        displaySize={displaySize}
                                    />
                            }
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};


export default Wallbox;