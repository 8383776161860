import { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import logoCloud from "../../../images/logo/logoCloud.png";
import logo2 from "../../../images/logo/logo2.png";
import axios from "axios";
import Cookies from "js-cookie";
import Form from "react-bootstrap/Form";

function SetFirmwareForm(props) {
    const [firmwareData, setfirmwareData] = useState({
        id: 0,
        version: "",
    });
    const [message, setMessage] = useState("");
    const [versionList, setVersionList] = useState([])

    useEffect(() => {
        getFirmwareData();
        if (props?.data) {
            setfirmwareData(props.data);
        }
    }, [props]);

    const getFirmwareData = () => {
        axios
            .get(`${process.env.REACT_APP_BACKEND_API_URL}/firmware_info`, {
                headers: {
                    Authorization: "Bearer " + Cookies.get("access_token"),
                },
            })
            .then(function (response) {
                if (response?.data?.success) {
                    setVersionList([...response.data.data]);
                } else {
                    setVersionList([]);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };


    const handleRegister = (e) => {
        e.preventDefault();
    };

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div
                    className="login-form"
                    style={{ height: "auto", padding: "0 20px" }}
                >
                    <div className="logo-head">
                        <div className="logo-2">
                            <img src={logoCloud} alt="" />
                        </div>
                        <div className="h4">Set firmware version to group </div>
                        <form onSubmit={handleRegister}>
                            <div className="form-group">
                                <label className="text">
                                    <span>*</span> Firmware version
                                </label>
                                <Form.Select
                                    aria-label="Default select example"
                                    onChange={(event) => 
                                        setfirmwareData({
                                            ...firmwareData,
                                            version: event.target.value,
                                          })
                                    }
                                >
                                    {versionList.map((version, index) => (
                                        <option value={version?.id} key={index}>
                                            {version?.version}
                                        </option>
                                    ))}
                                </Form.Select>
                            </div>
                            <div className="form-group">
                                <button
                                    type="submit"
                                    className="subBtn"
                                    onClick={() => props.callBackSubmit(firmwareData)}
                                >
                                    <i className="fa-solid fa-unlock"></i> Submit
                                </button>
                            </div>
                        </form>
                        <div className="message">
                            <p className="mt-2" style={{ color: "gray", fontWeight: "bold" }}>
                                {message}
                            </p>
                        </div>
                    </div>
                    <div className="logo-footer mt-5">
                        <img src={logo2} alt="" />
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default SetFirmwareForm;
