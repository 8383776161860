import React, { useEffect, useState } from "react";
import NavBar from "../../NavBar/NavBar";
import { AiOutlineSearch } from "react-icons/ai";
import { Link } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";

import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

const Serial2Code = () => {
  document.title = "Serial2Code - POWERGrid Cloud";

  const [serialNumber, setSerialNumber] = useState("");
  const [events, setEvents] = useState({});
  const [ownerCode, setOwnerCode] = useState("")
  const [installerCode, setInstallerCode] = useState("")
  const [isCodeEditable, setIsCodeEditable] = useState(true);
  const [isProcess, setIsProcess] = useState(false)
  const [currentEmail, setCurrentEmail] = useState("")
  const [message, setMessage] = useState("")

  function fetchData() {
    axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/codes_wallbox?serial_number=${serialNumber}`,
        {
          headers: {
          Authorization: "Bearer " + Cookies.get("access_token"),
        },
        }
      )
      .then((response) => {
        setEvents(response.data);
      })
      .catch((error) => {
        console.error("error found", error);
      });
  }

  useEffect(() => {
    fetchData();
    setCurrentEmail(Cookies.get("user_email"))
  }, []);

  const submitForm = (e) => {
    e.preventDefault();
    fetchData();
    setIsCodeEditable(isCodeEditable)
  };

  const setCode = (e) => {
    e.preventDefault();
    setIsCodeEditable(!isCodeEditable)
  }

  const setCodeOne = (e) => {
    setEvents(prevState => {
      return {
        ...prevState,
        events: {
          ...prevState.events,
          code: e.target.value
        }
      }
  })
  }

  const updateCode =(e) => {
    e.preventDefault();
    const updateObject = {
      owner_code: ownerCode,
      installer_code: installerCode,
      serial_number: serialNumber
    }

    axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/set_code`, updateObject, {
        headers: {
          authorization: "Bearer PuUf.9\\fMR~LkLE_=bF*_,r9niN&$Z"
        }
      })
      .then(function (response) {
        if (response.data) {
          setIsCodeEditable(true)
          fetchData();
          toast.success('Code Updated Successfuly', {autoClose:3000})
        }else{
          setMessage(response.data.message)
          toast.error(message, {autoClose:3000})
        }
      })
      .catch(function (error) {
          console.log(error)
          setIsCodeEditable(true)
      })
      .then(function () {
          setIsProcess(false)
      })
  }
  return (
    <>
      <NavBar />
      <main className="wrapper">
        <div className="page-content">
          <div className="wallbox-area">
            <div className="pages-title">
              <div className="h2">All Documents</div>
              <span>View all documents</span>
            </div>
            <div className="user__content">
              <div className="user__data">
                <ul className="panel_section">
                  <li>
                    <span>APIs Selection</span>
                  </li>
                  <li>
                    <Link to="/docs/sim2serial"> Sim2Serial</Link>
                  </li>
                  <li>
                    <Link to="/docs/serial2sim"> Serial2Sim</Link>
                  </li>
                  <li>
                    <Link to="/docs/Serial2Code" className="active">Serial2Code</Link>
                  </li>
                  <li>
                    <Link to="/docs/Code2Serial">Code2Serial</Link>
                  </li>
                  {
                    currentEmail === "christoph.zillner@powerjames.com" || currentEmail === "tarunsengar1987@gmail.com" ?
                    <li>
                      <Link to="/docs/syncWallbox">Sync Wallbox</Link>
                    </li>
                    :
                    null
                  }
                  
                </ul>
              </div>
              <div className="user__data">
                <div className="mt-5 mr-5">
                  <h6 className="text-left mt-5"> Get Codes by Serial Number </h6>
                </div>

                <div className="search-filter">
                  <form onSubmit={submitForm} action="">
                    <input type="text" onChange={(e) => setSerialNumber(e.target.value)} placeholder="Search By Serial Number" />
                    <button type="submit"> <AiOutlineSearch /> </button>
                  </form>
                </div>
                <div className="wallbox-tabl">
                  {
                  events?.success === true ?
                  <div className="table-componet">
                  <div className="details_cont">
                    <div className="wallbox_details" style={{ position: "relative" }}>
                      <div className="wallbox_details_content">
                        <form>
                          <div className="box_group">
                                <label>Serial Number</label>
                                <td>{events.data.serial_number}</td>
                                {/* <input type="text" onChange={e => setSerialNumber(e.target.value)} /> */}
                            </div>
                            <div className="box_group">
                                <label>Owner Code</label>
                                {
                                  isCodeEditable ? 
                                  <input type="text" onChange={e => setOwnerCode(e.target.value)} value={events?.data.owner_code} readOnly={true}/>
                                  :
                                  <input type="text" onChange={e => setOwnerCode(e.target.value)} defaultValue={events?.data.owner_code} readOnly={false}/>
                                }
                                
                            </div>
                            <div className="box_group">
                                <label>Installer Code</label>
                                {
                                  isCodeEditable ? 
                                  <input type="text" onChange={e => setInstallerCode(e.target.value)} value={events?.data.installer_code} readOnly={true} />
                                  :
                                  <input type="text" onChange={e => setInstallerCode(e.target.value)} defaultValue={events?.data.installer_code} readOnly={false} />
                                }
                                
                            </div>
                            {/* <button className="btn-yellpow">Submit</button> */}
                            {

                              <span>
                                {
                                  isCodeEditable ?
                                    <button className="btn-yellpow" onClick={(e) => setCode(e)}>Edit Codes</button>
                                    :
                                    <button className="btn-yellpow" onClick={(e) => updateCode(e)}>Submit</button>
                                }
                              </span>
                            }
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                  :
                  <h5 className="text-center mt-5"> Result Not found... </h5>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Serial2Code;