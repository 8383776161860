import React, { useState } from 'react';
import search from "../../images/icons/search.png"
import update from "../../images/icons/update.png"
import { BsChevronDoubleRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import { TailSpin } from 'react-loader-spinner';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';



const ChargeEventRow = ({ chargingEvents, isFetcing, pageCount, totalChargeEventsCount, currentPage, setCurrentPage, displaySize }) => {
    const [List, setList] = useState(chargingEvents)
    const [MasterChecked, setMasterChecked] = useState(false)
    const [SelectedList, setSelectedList] = useState([])
    const [currentSort, SetCurrentSort] = useState('up')



    function onMasterCheck(e) {
        let tempList = List;
        // Check/ UnCheck All Items
        tempList.map((user) => (user.selected = e.target.checked));

        //Update State
        setMasterChecked(e.target.checked)
        setList(tempList)
        setSelectedList(List.filter((e) => e.selected))
    }

    function onItemCheck(e, item) {
        let tempList = List;
        tempList.map((user) => {
            if (user.id === item.id) {
                user.selected = e.target.checked;
            }
            return user;
        });

        //To Control Master Checkbox State
        const totalItems = List.length;
        const totalCheckedItems = tempList.filter((e) => e.selected).length;

        // Update State
        setMasterChecked(totalItems === totalCheckedItems)
        setList(tempList)
        setSelectedList(List.filter((e) => e.selected))
    }

    function getSelectedRows() {
        setSelectedList(List.filter((e) => e.selected))
    }

    const onSortChange = () => {
        let nextSort;

        if (currentSort === 'down') {
            nextSort = 'up'
        }
        else if (currentSort === 'up') {
            nextSort = 'down'
        }
        SetCurrentSort(nextSort)

    };

    return (
        <>
            <div className="table-componet">
                <div className="row">
                    <div className="col-md-12">
                        <div className="tab__content">
                            <table className="table box-table">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={MasterChecked}
                                                id="mastercheck"
                                                onChange={(e) => onMasterCheck(e)}
                                            />
                                        </th>
                                        <th scope="col">Event ID</th>
                                        <th scope="col" className="serialT">Wallbox Serial</th>
                                        <th onClick={onSortChange} scope="col" className="id">
                                            <span className="d-installed">Start Timestamp </span>
                                        </th>
                                        <th scope="col" className="FirmwareT">End Timestamp</th>
                                        <th scope="col" className="Energy_T">Duration </th>
                                        <th scope="col" className="Energy_T">Energy </th>
                                        <th scope="col" className="ownerT">User </th>
                                        <th scope="col" className="ownerT">Konto </th>
                                        <th scope="col" className="statusT">Status</th>
                                        <th scope="col" className="actionsT">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        chargingEvents.length && !isFetcing ? chargingEvents.map((event, index) => <SingleRow
                                            key={index}
                                            event={event}
                                            onItemCheck={onItemCheck}
                                        />) :
                                        <tr className="router-loading-spinner">
                                            <td>
                                                <TailSpin width={50} height={50} color="#002060" />
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="pagination-content">
                    <ul>
                        {/*  {
                            [...Array(pageCount).keys()].map(avc => <li className={currentPage === avc + 1 && "active"} onClick={() => setCurrentPage(avc + 1)}>{avc + 1}</li>)
                        }
                        {
                            currentPage !== pageCount && <li onClick={() => setCurrentPage(currentPage < pageCount ? currentPage + 1 : currentPage)}><BsChevronDoubleRight /></li>
                        } */}
                        <Stack spacing={2}>
                            <Pagination onChange={(e, pn) => setCurrentPage(pn)} count={pageCount} />
                        </Stack>
                    </ul>

                    <div className="pageRight">
                        <span>Showing {chargingEvents?.length} of {totalChargeEventsCount} items</span>
                    </div>

                </div>
            </div>

        </>
    );
};


const SingleRow = ({ event, onItemCheck }) => {
    const { id, serial_number, start_time, duration, energy, user, status, end_time, konto } = event;

    return (
        <tr className={event.selected ? "selected" : ""}>
            <th scope="row">
                <input
                    type="checkbox"
                    checked={event.selected}
                    className="form-check-input"
                    id="rowcheck{user.id}"
                    onChange={(e) => onItemCheck(e, event)}
                />
            </th>

            <td className="eventID"><span>{id}</span></td>
            <td className="wallboxSerial"><span><Link to={`/wallbox/${serial_number}`}>{serial_number}</Link></span></td>
            <td className="Date__Time"><span><time>{start_time}</time></span></td>
            <td className="Date__Time"><span><time>{end_time}</time></span></td>
            <td className="duration"><span>{duration}</span></td>
            <td className="duration"><span>{energy}</span></td>
            <td className="Owner"><span>{user}</span></td>
            <td className="Owner"><span>{konto}</span></td>
            <td className="Stat"><span className={status}>{status}</span></td>
            <td className="Actions">
                <span>
                    {
                        status?.toLowerCase() !== "Finished".toLowerCase() && <button><img alt="" src={update} /> Update</button>
                    }
                    <button><img alt="" src={search} /></button>
                </span>
            </td>
        </tr>
    )
}

export default ChargeEventRow;