import { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import logoCloud from "../../../images/logo/logoCloud.png";
import logo2 from "../../../images/logo/logo2.png";

function MessageForm(props) {
  const [messageData, setMessageData] = useState({
    id: 0,
    message: "",
    times_show: "",
  });
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (props?.data) {
      setMessageData(props.data);
    }
  }, [props]);

  const handleRegister = (e) => {
    e.preventDefault();
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div
          className="login-form"
          style={{ height: "auto", padding: "0 20px" }}
        >
          <div className="logo-head">
            <div className="logo-2">
              <img src={logoCloud} alt="" />
            </div>
            <div className="h4">Add a new message </div>
            <form onSubmit={handleRegister}>
              <div className="form-group">
                <label className="text">
                  <span>*</span> Message
                </label>
                <input
                  required
                  onChange={(e) =>
                    setMessageData({
                      ...messageData,
                      message: e.target.value,
                    })
                  }
                  type="text"
                  value={messageData?.message}
                />
              </div>
              <div className="form-group">
                <label className="text">
                  <span>*</span> Display times
                </label>
                <input
                  required
                  onChange={(e) =>
                    setMessageData({
                      ...messageData,
                      times_show: e.target.value,
                    })
                  }
                  type="number"
                  value={messageData?.times_show}
                />
              </div>
              <div className="form-group">
                <button
                  type="submit"
                  className="subBtn"
                  onClick={() => props.callBackSubmit(messageData)}
                >
                  <i className="fa-solid fa-unlock"></i> Register
                </button>
              </div>
            </form>
            <div className="message">
              <p className="mt-2" style={{ color: "gray", fontWeight: "bold" }}>
                {message}
              </p>
            </div>
          </div>
          <div className="logo-footer mt-5">
            <img src={logo2} alt="" />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default MessageForm;
