import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import axios from "axios";
import Cookies from "js-cookie";
import Chart from "chart.js/auto";
import ChargeEvents from "./ChargeEvents";

const ChartComponent = (props) => {
  const [labels, setLabels] = useState([]);
  const [data, setData] = useState([]);

  const state = {
    labels: [...labels],
    datasets: [
      {
        label: "Energy in KWh",
        fill: false,
        lineTension: 0.5,
        backgroundColor: "rgba(75,192,192,1)",
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 2,
        data: [...data],
      },
    ],
  };

  useEffect(() => {
    const access_token = Cookies.get("access_token");
    if (access_token) {
      /* http://116.203.191.150:5013 */
      axios
        .get(`${process.env.REACT_APP_BACKEND_API_URL}/chart_data?s_no=${props.id}`, {
          headers: {
          Authorization: "Bearer " + Cookies.get("access_token"),
        },
        })
        .then(function (response) {
          setLabels([...response.data.data.date]);
          setData([...response.data.data.energy]);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, []);
  return (
    <div>
    <div className="charts">
      <Line
        data={state}
        options={{
          title: {
            display: true,
            text: "Average Rainfall per month",
            fontSize: 20,
          },
          legend: {
            display: true,
            position: "right",
          },
        }}
      />
    </div>
    <div className="chargeEvents">
                <ChargeEvents id={props.id}/>
    </div>
    </div>
  );
};

export default ChartComponent;
