import React, { useState, useEffect } from "react";
import NavBar from "../NavBar/NavBar";
import axios from "axios";
import update from "../../images/icons/update.png";
import Delete from "../../images/icons/bin.png";
import BillingServiceForm from "./AddBillingService/AddBillingService";
import Cookies from 'js-cookie'
import "./BillingService.scss";
import { TailSpin } from "react-loader-spinner";
import { getPermission } from "../../utils";

import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

const initState = { id: 0, wallbox_serial_number: "", email: "" };

const BillingService = () => {
  document.title = "ChargeTransactionEmail - POWERGrid Cloud";
  const [data, setData] = useState([]);
  const [isFetcing, setIsFetching] = useState(true);
  const [isNotFound, setIsNotFound] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [chargeTransactionEmail, setChargeTransactionEmail] = useState(initState);
  const [message, setMessage] = useState("")

  const checkEmailAdd = getPermission("charge_transaction_add")
  const checkEmailUpdate = getPermission("charge_transaction_update")
  const checkEmailDelete = getPermission("charge_transaction_delete")

  useEffect(() => {
    getChargeTransactionEmail();
  }, []);

  const getChargeTransactionEmail = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_API_URL}/charge_transaction_email`, {
        headers: {
          Authorization: "Bearer " + Cookies.get("access_token"),
        },
      })
      .then(function (response) {
        setData([...response.data]);
        if (response?.data?.length > 0) {
          setIsFetching(true);
          setIsNotFound(false);
          setIsFetching(false);
        } else {
          setIsFetching(false);
          setIsNotFound(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const deleteRow = (deleteEntry) => {
    const askToConfirm = window.confirm(
      `Are you sure want to Delete this Entry`
    );
    if (askToConfirm) {
      axios
        .delete(
          `${process.env.REACT_APP_BACKEND_API_URL}/charge_transaction_email?id=${deleteEntry}`,
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("access_token"),
            },
          }
        )
        .then(function (response) {
          window.alert(response?.data?.message);
          getChargeTransactionEmail();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const getChargeTransactionEmailById = (id) => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_API_URL}/charge_transaction_email/${id}`, {
        headers: {
          Authorization: "Bearer " + Cookies.get("access_token"),
        },
      })
      .then(function (response) {
        if (response?.data) {
          setChargeTransactionEmail(response.data);
          setModalShow(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const saveAPI = (data) => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_API_URL}/charge_transaction_email`, data, {
        headers: {
          Authorization: "Bearer " + Cookies.get("access_token"),
        },
      })
      .then(function (response) {
        setModalShow(false);
        if (response?.data.status) {
          getChargeTransactionEmail();
          toast.success('Email Added Successfuly', {autoClose:3000})
        } else {
          // alert(response?.data.message);
          setMessage(response.data.message)
          toast.error(message, {autoClose:3000})
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const updateAPI = (data) => {
    axios
      .put(`${process.env.REACT_APP_BACKEND_API_URL}/charge_transaction_email`, data, {
        headers: {
          Authorization: "Bearer " + Cookies.get("access_token"),
        },
      })
      .then(function (response) {
        setModalShow(false);
        if (response?.data.status) {
          getChargeTransactionEmail();
          toast.success('Email Updated Successfuly', {autoClose:3000})
        } else {
          // alert(response?.data.message);
          setMessage(response.data.message)
          toast.error(message, {autoClose:3000})
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleSubmit = (data) => {
    if (data?.id > 0) {
      updateAPI(data);
    } else {
      saveAPI(data);
    }
  };

  return (
    <>
      <NavBar />
      <main className="wrapper">
        <div className="page-content">
          <div className="wallbox-area">
            <div className="pages-title">
              <div className="h2">Charge Transaction Email</div>
              <span>View all Charger Transaction Email</span>
            </div>
            <div className="search-filter">
              <div style={{ width: "120px" }} className="serialT">
                {checkEmailAdd === true ?
                <button
                  className="btn-yellpow"
                  onClick={() => {
                    setChargeTransactionEmail(initState);
                    setModalShow(true);
                  }}
                >
                  Add Item
                </button>
                :
                null
                }
              </div>
            </div>
            <div className="table-componet" style={{ display: "contents" }}>
              <div className="col-md-12">
                <div className="col-md-12">
                  <div className="tab__content mt-2">
                    <table className="table box-table">
                      <thead>
                        <tr>
                          <th
                            style={{ width: "150px" }}
                            className="serialT"
                          >
                            <h6>
                              <b> ID</b>
                            </h6>
                          </th>
                          <th className="serialT">
                            <h6>
                              <b> Email</b>
                            </h6>
                          </th>
                          <th className="serialT">
                            <h6>
                              <b> Serial Number</b>
                            </h6>
                          </th>
                          {checkEmailUpdate || checkEmailDelete === true ?
                          <th className="serialT">
                            <h6>
                              <b> Actions</b>
                            </h6>
                          </th>
                          :
                          null
                          }
                          <th
                            style={{ width: "58px" }}
                            className="serialT"
                          ></th>
                        </tr>
                      </thead>
                      <tbody>
                      {isFetcing === true ?
                        (
                        <tr>
                          <td>
                            <div className="router-loading-spinner">
                              <TailSpin width={50} height={50} color="#002060" />
                            </div>
                          </td>
                        </tr>
                        )
                        :
                        isNotFound === true ?
                        <h5 className="text-center mt-5">Result Not found...</h5>
                        : (
                          data?.map((item, i) => {
                            return (
                              <tr key={i} style={{ fontSize: "13px" }} className="border-bottom">
                                <td>{item?.id}</td>
                                <td>{item?.email}</td>
                                <td>{item?.wallbox_serial_number}</td>
                                <td className="Actions">
                                  {/* <span> */}
                                    {checkEmailUpdate === true ?
                                      <button
                                        className="actionButton"
                                        onClick={() =>
                                          getChargeTransactionEmailById(
                                            item?.id
                                          )
                                        }
                                      >
                                        <img alt="" src={update} /> Update
                                      </button>
                                      :
                                      null
                                    }
                                    {checkEmailDelete === true ?
                                      <button
                                        className="actionButton"
                                        style={{ margin: "5px" }}
                                        onClick={() => deleteRow(item?.id)}
                                      >
                                        <img alt="" src={Delete} /> Delete
                                      </button>
                                      :
                                      null
                                    }
                                    <button></button>
                                  {/* </span> */}
                                </td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BillingServiceForm
          show={modalShow}
          onHide={() => setModalShow(false)}
          data={chargeTransactionEmail}
          callBackSubmit={handleSubmit}
        />
      </main>
    </>
  );
};

export default BillingService;
