import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slid1 from '../../images/loginSlid/login-img1.png'
import Slid2 from '../../images/loginSlid/login-img2.png'
import Slid3 from '../../images/loginSlid/login-img3.png'
import Slid4 from '../../images/loginSlid/login-img4.png'

const SlidImg = [
    {
        id: '1',
        img: Slid1,
    },
    {
        id: '2',
        img: Slid2,
    },
    {
        id: '3',
        img: Slid3,
    },
    {
        id: '4',
        img: Slid4,
    },
];

const LoginSlider = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
    };

    return (
        <>
            <Slider {...settings}>
                {
                    SlidImg.map((list, index) => {
                        return (
                            <div className="loginSlid-item" id={list.id} key={index}>
                                <img alt="icon" src={list.img} />
                            </div>
                        )
                    })
                }

            </Slider>
        </>
    )
}

export default LoginSlider