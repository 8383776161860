
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import React, { useEffect, useState } from "react";
import Cookies from 'js-cookie';
import axios from "axios";

ChartJS.register(ArcElement, Tooltip, Legend);

const FirmwarePieChart = () => {
    const [version, setVersion] = useState([]);
    const [count, setCount] = useState([]);
    const [colors, setColors] = useState([]);
  
    const state = {
        labels: [...version],
        datasets: [
            {
            label: '# of Votes',
            data: [...count],
            backgroundColor: colors,
            borderColor: '#FFFFFF',
            borderWidth: 1,
            },
        ],
    };

    const options = {
        plugins: {
          legend: {
            position: 'right',
            rtl : true,
            labels: {
            //   usePointStyle: true,
            //   pointStyle: 'circle',
              padding: 20,
            }
          }
        },
    }
  
    useEffect(() => {
        const access_token = Cookies.get("access_token");
        if (access_token) {
            /* http://116.203.191.150:5013 */
            axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/firmware_count`, {
                headers: {
                    Authorization: "Bearer " + Cookies.get("access_token"),
                },
            })
            .then(function(response){
                if(response?.data?.data){
                    setVersion([...response.data.data.map((obj)=>obj.version)]);
                    setCount([...response.data.data.map((obj)=>obj.count)]);
                    for(let i=0; i<=response.data.data.length; i++){
                        const newColor = '#'+Math.floor(Math.random()*16777215).toString(16)
                        setColors(colors => [...colors, newColor] );
                    }
                } else{
                    setVersion([]);
                    setCount([])
                }
            })
            .catch(function(error){
                console.log(error);
            })
        }
    }, []);
    
    return (
      <div>
        <div className="charts">
            <Pie
            data={state}
            options={options}
            />
        </div>
      </div>
    );
  };
  
  export default FirmwarePieChart;
