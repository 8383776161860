import React, { useEffect, useState } from "react";

import NavBar from "../NavBar/NavBar";
import { FaAngleLeft } from "react-icons/fa";
import billing from "../../images/icons/billing.png";
import Delete from "../../images/icons/delete.png";
import Rectangle from "../../images/Rectangle.png";
import Form from "react-bootstrap/Form";
import UserWallbox from "./WallboxSingle/UserWallbox";
import AllActivities from "./WallboxSingle/AllActivities";
import update from "../../images/icons/update.png"
import { Link } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";
import { IoMdKey } from "react-icons/io";
import { useParams } from "react-router-dom";
import axios from "axios";
import { TailSpin } from "react-loader-spinner";
import Cookies from "js-cookie";
import "./WallboxSingle.scss";
import { useHistory } from "react-router-dom";
import { getPermission } from "../../utils";
import WallboxComment from "./WallboxComment";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GoogleMapReact from 'google-map-react';
import Marker from "../Marker/Marker";
toast.configure()

const WallboxSingle = () => {
  document.title = "Wallbox - POWERGrid Cloud";

  const [wallBox, setWallbox] = useState({});
  const [wallboxFirmwareVersion, setWallboxFirmwareVersion] = useState("");
  const [isEditFirmwareVersion, setIsEditFirmwareVersion] = useState(false);
  const [isFirmwareVersionEditable, setIsFirmwareVersionEditable] = useState(true);
  const [version, setVersion] = useState();
  const [data, setData] = useState([]);
  const [groups, setGroups] = useState([]);
  const [group, setGroup] = useState();
  const [isGroupEditable, setIsGroupEditable] = useState(false);
  const [address, setAddress] = useState("");
  const [isMap, setIsMap] = useState(false);
  const [wallboxDetails, setWallboxDetails] = useState({});
  const [addressLocation, setAddressLocation] = useState({
    center: {
      lat: 10.99835602,
      lng: 77.01502627
    },
    zoom: 11
  })

  const params = useParams();
  const { wallboxId } = params;

  const history = useHistory();
  const updateWallbox = () => {
    history.push("/updatewallbox", { serial_number: wallboxId });
  }

  const checkEditFirmware = getPermission("edit_firmware_version")
  const checkChangeGroup = getPermission("change_group")
  const checkAddComment = getPermission("add_comment")
  const checkWallboxUpdate = getPermission("wallbox_update")

  const handleChangeVersion = (event) => {
    setVersion(event.target.value);
  };

  const handleChangeGroup = (event) => {
    setGroup(event.target.value);
  };

  const getWallboxInfo = () => {
    if (wallboxId) {
      axios
        .get(`${process.env.REACT_APP_BACKEND_API_URL}/wallbox_info/${wallboxId}`, {
          headers: {
            authorization: "Bearer " + Cookies.get("access_token"),
          },
        })
        .then((response) => {
          if (response.data) {
            setWallbox(response.data);
            setWallboxDetails(response.data["wallbox"])
            getReverseGeocodingData(response.data.wallbox.location)
            setIsEditFirmwareVersion(response?.data?.is_edit_version);
          }
        })
        .catch((error) => {
          console.error("Got Error", error);
        });
    }
  };

  function getReverseGeocodingData(latlng) {
    // var latlng = new google.maps.LatLng(lat, lng);
    // This is making the Geocode request
    if (latlng){
      setIsMap(true)
      var geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ 'latLng': latlng }, (results, status) => {
        if (status !== window.google.maps.GeocoderStatus.OK) {
          alert(status);
        }
        // This is checking to see if the Geoeode Status is OK before proceeding
        if (status == window.google.maps.GeocoderStatus.OK) {
          // var address = (results[0].formatted_address);
          setAddress(results[0].formatted_address)
          setAddressLocation({
            center: {
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng(),
            },
            zoom: 15
          })
        }
      });
    } else {
      setIsMap(false)
    }

  }

  const { wallbox } = wallBox;
  
  let theDate;
  if (wallbox) {
    theDate = new Date(wallbox?.created_at).toLocaleDateString();
  }

  const setFirmwareVersion = (e) => {
    e.preventDefault();
    setIsFirmwareVersionEditable(!isFirmwareVersionEditable);
  };

  const setFirmwareVersionOne = (e) => {
    setWallbox((prevState) => {
      return {
        ...prevState,
        wallbox: {
          ...prevState.wallBox,
          version: e.target.value,
        },
      };
    });
  };

  const groupList = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/group`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("access_token"),
      },
    })
      .then(function (response) {
        if (response?.data?.success) {
          setGroups([...response.data.data]);
        } else {
          setData([]);
        }
      })
      .catch(function (error) {
        console.log(error);
      })
  };

  const submitGroup = (e) => {
    e.preventDefault();
    const groupItemData = {
      wallbox_id: wallboxId,
      group_id: group,
    };

    axios
      .post(`${process.env.REACT_APP_BACKEND_API_URL}/group_item`, groupItemData, {
        headers: {
          authorization: "Bearer " + Cookies.get("access_token"),
        },
      })
      .then((response) => {
        if (response.data) {
          setIsGroupEditable(false);
          window.location.reload(true)
          toast.success('Wallbox Group Changed Successfuly', { autoClose: 3000 })
        }
      })
      .catch((error) => {
        console.error("Got Error", error);
        setIsGroupEditable(false);
      });
  }

  const submitForm = (e) => {
    e.preventDefault();
    getWallboxInfo();
    setIsFirmwareVersionEditable(isFirmwareVersionEditable);
    setIsGroupEditable(isGroupEditable);
  };

  const postFirmwareVersion = (e) => {
    e.preventDefault();
    const updateObject = {
      version: version,
      serial_number: wallboxId,
    };

    axios
      .put(
        `${process.env.REACT_APP_BACKEND_API_URL}/wallbox_firmware_version`,
        updateObject,
        {
          headers: {
            Authorization: "Bearer " + Cookies.get("access_token"),
          },
        }
      )
      .then((response) => {
        if (response.data) {
          setIsFirmwareVersionEditable(true);
          window.location.reload(true)
          toast.success('Firmware Version Updated Successfuly', { autoClose: 3000 })
        }
      })
      .catch((error) => {
        console.error("Got Error", error);
        setIsFirmwareVersionEditable(true);
      });
  };

  const getFirmwareData = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_API_URL}/firmware_info`, {
        headers: {
          Authorization: "Bearer " + Cookies.get("access_token"),
        },
      })
      .then(function (response) {
        if (response?.data?.success) {
          setData([...response.data.data]);
        } else {
          setData([]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const setGroupEditable = (e) => {
    e.preventDefault()
    setIsGroupEditable(true)
  }

  useEffect(() => {
    getFirmwareData();
    getWallboxInfo();
    groupList();
  }, [wallboxId]);

  return (
    <>
      <NavBar />
      <main className="wrapper">
        <div className="page-content">
          <div className="wallbox-area">
            <div className="pages-title-list">
              <ul>
                <li>
                  <Link to="/">All Wallbox</Link>
                </li>
                <li>
                  <span>{wallboxId}</span>
                </li>
              </ul>
            </div>

            <div className="wallbox_single_content">
              <div className="single_all_list">
                <ul className="box_progress">
                  <li>
                    <Link to="/">
                      <FaAngleLeft /> All Wallbox
                    </Link>
                  </li>
                  <li>
                    <span>Wallbox Functions</span>
                  </li>
                  {checkWallboxUpdate == true ?
                    <li onClick={updateWallbox}>
                      <Link to="">
                        <img alt="" src={update} /> Update Wallbox
                      </Link>
                    </li>
                    :
                    null
                  }
                  <li>
                    <Link to="">
                      <img alt="" src={billing} /> Billing Details
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <img alt="" src={Delete} /> Delete Wallbox
                    </Link>
                  </li>
                </ul>
                <ul className="box_progress">
                  <li className="h5">Wallbox Progress</li>
                </ul>
              </div>
              <div className="details_cont">
                <div
                  className="wallbox_details"
                  style={{ position: "relative", marginBottom: '15px' }}
                >
                  <div className="h5">Wallbox Details</div>
                  {wallbox ? (
                    <div className="wallbox_details_content">
                      <form action="">
                        <div className="box_group">
                          <label>Product Type</label>
                          <input
                            type="text"
                            defaultValue={wallbox?.product_type}
                            readOnly
                          />
                        </div>
                        <div className="box_group">
                          <label>
                            <IoMdKey /> Environment Key (Wallbox Serial){" "}
                          </label>
                          <input
                            type="text"
                            defaultValue={wallbox?.serial_number}
                            readOnly
                          />
                        </div>
                        <div className="box_group">
                          <label>Currently running firmware version</label>
                          <input
                            type="text"
                            defaultValue={wallbox?.firmware_soll}
                            readOnly
                          />
                        </div>
                        <div className="box_group">
                          <label>Wallbox target firmware version</label>
                          {isFirmwareVersionEditable ? (
                            <input
                              type="text"
                              onChange={(e) =>
                                setWallboxFirmwareVersion(e.target.value)
                              }
                              defaultValue={wallbox?.firmware_ist}
                              readOnly={isFirmwareVersionEditable}
                            />
                          ) : (
                            <Form.Select
                              aria-label="Default select example"
                              onChange={(event) => handleChangeVersion(event)}
                              defaultValue={wallbox?.firmware_ist}
                            >
                              {data.map((element) => (
                                <option value={element?.id}>
                                  {element?.version}
                                </option>
                              ))}
                            </Form.Select>
                          )}
                          {checkEditFirmware == true ?
                            <span>
                              {isFirmwareVersionEditable ? (
                                <button
                                  className="btn-yellpow"
                                  onClick={(e) => setFirmwareVersion(e)}
                                >
                                  Edit Firmware Version
                                </button>
                              ) : (
                                <button
                                  className="btn-yellpow"
                                  onClick={(e) => postFirmwareVersion(e)}
                                >
                                  Submit
                                </button>
                              )}
                            </span>
                            :
                            null
                          }
                        </div>
                        <div className="box_group">
                          <label>Group</label>
                          {isGroupEditable ? (
                            <Form.Select
                              aria-label="Default select example"
                              onChange={(event) => handleChangeGroup(event)}
                              defaultValue={wallbox?.group}
                            >
                              {groups.map((group) => (
                                <option value={group?.id}>
                                  {group?.name}
                                </option>
                              ))}
                            </Form.Select>

                          ) : (

                            <input
                              type="text"
                              defaultValue={wallbox?.group}
                              readOnly
                            />
                          )}
                          {checkChangeGroup == true ?
                            <span>
                              {isGroupEditable ? (
                                <button
                                  type="text"
                                  className="btn-yellpow"
                                  onClick={(e) => submitGroup(e)}
                                >
                                  Submit
                                </button>

                              ) : (
                                <button
                                  type="text"
                                  className="btn-yellpow"
                                  onClick={(e) => setGroupEditable(e)}
                                >
                                  Set Group
                                </button>
                              )}
                            </span>
                            :
                            null
                          }
                        </div>
                        <div className="box_group">
                          <label>Date installed</label>
                          <input type="text" defaultValue={theDate} readOnly />
                        </div>
                        <div className="box_group">
                          <label>Owner</label>
                          <input
                            type="text"
                            defaultValue={wallboxDetails?.owner}
                            readOnly
                          />
                        </div>
                        <div className="box_group">
                          <label>Company Name</label>
                          <input
                            type="text"
                            defaultValue={wallboxDetails?.company}
                            readOnly
                          />
                        </div>
                        <div className="box_group">
                          <label>Installed Adresse</label>
                          <input
                            type="text"
                            defaultValue={isMap ? address : wallboxDetails?.installed_address}
                            readOnly
                          /> 
                        </div>
                        {
                          isMap ? 
                          <div style={{ height: '300px', width: '100%' }}>
                            <GoogleMapReact
                              bootstrapURLKeys={'AIzaSyApcRz24my_H6fqJzHD0OJ_fnau_Ut40Os'}
                              center={addressLocation.center}
                              zoom={addressLocation.zoom}
                            >
                              <Marker
                                lat={addressLocation.center.lat}
                                lng={addressLocation.center.lng}
                                color="blue"
                              />
                            </GoogleMapReact>
                          </div> : null
                        }
                      </form>
                      <div className="box_right_img">
                        <img alt="" src={Rectangle} />
                        <span>Status</span>
                        <span className="Running">{wallbox?.status}</span>
                      </div>
                    </div>
                  ) : (
                    <div className="router-loading-spinner">
                      <TailSpin width={25} height={25} color="#002060" />
                    </div>
                  )}
                </div>
                <div className="user_wallbox" style={{marginBottom: "15px"}}>
                  <div className="h5">Wallbox Comment</div>
                  <WallboxComment />
                </div>
                <div className="user_wallbox">
                  <div className="h5">User in Wallbox</div>
                  <form action="">
                    <input type="input" placeholder="Search Product..." />
                    <button type="submit">
                      <AiOutlineSearch />
                    </button>
                  </form>
                  <UserWallbox />
                </div>
              </div>
              <div className="allActivities">
                <AllActivities />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default WallboxSingle;
