import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import Wallbox from "./Wallbox/Wallbox";
import WallboxSingle from "./Wallbox/WallboxSingle";
import AddWallbox from "./Wallbox/AddWallbox";
import ChargeEvents from "./ChargeEvents/ChargeEvents";
import CustomersMessages from "./CustomersMessages/CustomersMessages";
import Firmware from "./Firmware/Firmware";
import AddFirmware from "./Firmware/AddFirmware/AddFirmware";
import Group from "./Group/Group";
import AddGroup from "./AddGroup/AddGroup"
import User from "./User/User";
import UserSingle from "./User/UserSingle";
import Roles from "./User/Roles";
import Permissions from "./User/Permissions";
import Documents from "./Docs/Docs";
import Serial2Sim from "./Docs/APIs/Serial2Sim";
import Sim2Serial from "./Docs/APIs/Sim2Serial";
import Serial2Code from "./Docs/APIs/Serial2Code";
import Code2Serial from "./Docs/APIs/Code2Serial";
import releasenotes from "./Docs/APIs/releasenotes";
import Measurement from "./Measurement/Measurement"
import AddMeasurement from "./Measurement/AddMeasurement/AddMeasurement"
import WallboxComment from "./Wallbox/WallboxComment";
import Login from "./Login/Login";
import StatisticsDashboard from "./StatisticsDashboard/StatisticsDashboard";
import "./App.scss";
import Register from "./Register/Register";
import AuthProvider from "../context/AuthProvider";
import { Switch } from "react-router-dom";
import PrivateRoute from "../Routes/PrivateRoute";
import Events from "./Events/Events";
import PowerBrain from "./PowerBrain/PowerBrain";
import ResetPassword from "./Reset_Password/ResetPassword";
import ReleaseNotes from "./Docs/APIs/releasenotes";
import BillingService from './BillingService/BillingService';
import UserProfile from "./User/UserProfile";
import AddPermissionForm from "./User/AddPermission";
import Permission from "./User/permission/Permission";
import UserRole from "./User/permission/UserRole";
import ConfirmUser from "./User/ConfirmUser"
import UpdateWallbox from './Wallbox/UpdateWallbox';
import WallboxUser from "./User/WallboxUser";
import SyncWallboxes from "./Docs/APIs/SyncWallboxes";
import MobileLogs from "./MobileLogs/MobileLogs";
import UserLogControl from "./User/permission/UserLogControl";
import EditWallboxUser from "./User/EditWallboxUser/EditWallboxUser";
import ListCarProfile from "./CarProfile/ListCarProfile/ListCarProfile";
import AddCarProfile from "./CarProfile/AddCarProfile/AddCarProfile";

const App = () => {

  return (
    <>
      <AuthProvider>
        <Router>
          <Switch>
            <PrivateRoute exact path="/">
              <Wallbox />
            </PrivateRoute>

            <PrivateRoute exact path="/wallbox/:wallboxId">
              <WallboxSingle />
            </PrivateRoute>

            <PrivateRoute exact path="/addwallbox">
              <AddWallbox />
            </PrivateRoute>

            <PrivateRoute exact path="/updatewallbox">
              <UpdateWallbox />
            </PrivateRoute>

            <PrivateRoute exact path="/chargeEvents">
              <ChargeEvents />
            </PrivateRoute>

            <PrivateRoute exact path="/CustomersMessages">
              <CustomersMessages />
            </PrivateRoute>

            <PrivateRoute exact path="/users">
              <User />
            </PrivateRoute>

            <PrivateRoute exact path="/user/:userID">
              <UserSingle />
            </PrivateRoute>

            <PrivateRoute exact path="/userprofile">
              <UserProfile />
            </PrivateRoute>

            {/* <PrivateRoute exact path="/users/confirm_user/:token">
              <ConfirmUser />
            </PrivateRoute> */}

            <PrivateRoute exact path="/events">
              <Events />
            </PrivateRoute>

            <PrivateRoute exact path="/powerbrain">
              <PowerBrain />
            </PrivateRoute>

            <PrivateRoute exact path="/mobilelogs">
              <MobileLogs />
            </PrivateRoute>
            <Route exact path="/reset_password/:token">
              <ResetPassword />
            </Route>

            <PrivateRoute exact path="/roles">
              <Roles />
            </PrivateRoute>

            <PrivateRoute exact path="/permissions">
              <Permissions />
            </PrivateRoute>

            <PrivateRoute exact path="/docs/sim2serial">
              <Sim2Serial />
            </PrivateRoute>
            <PrivateRoute exact path="/docs/serial2sim">
              <Serial2Sim />
            </PrivateRoute>
            <PrivateRoute exact path="/docs/Serial2Code">
              <Serial2Code />
            </PrivateRoute>
            <PrivateRoute exact path="/docs/Code2Serial">
              <Code2Serial />
            </PrivateRoute>
            <PrivateRoute exact path="/releasenotes">
              <ReleaseNotes />
            </PrivateRoute>
            <PrivateRoute exact path="/billingservice">
              <BillingService />
            </PrivateRoute>
            <PrivateRoute exact path="/firmware">
              <Firmware />
            </PrivateRoute>
            <PrivateRoute exact path="/addfirmware">
              <AddFirmware />
            </PrivateRoute>
            <PrivateRoute exact path="/group">
              <Group />
            </PrivateRoute>
            <PrivateRoute exact path="/addgroup">
              <AddGroup />
            </PrivateRoute>
            <PrivateRoute exact path="/measurement">
              <Measurement />
            </PrivateRoute>
            <PrivateRoute exact path="/addmeasurement">
              <AddMeasurement />
            </PrivateRoute>
            <PrivateRoute exact path="/addpermission">
              <AddPermissionForm />
            </PrivateRoute>
            <PrivateRoute exact path="/rolePermission">
              <Permission />
            </PrivateRoute>
            <PrivateRoute exact path="/userRole">
              <UserRole />
            </PrivateRoute>
            <PrivateRoute exact path="/userlogcontrol">
              <UserLogControl />
            </PrivateRoute>
            <PrivateRoute exact path="/wallboxuser/:user_id">
              <WallboxUser />
            </PrivateRoute>
            <PrivateRoute exact path="/docs/syncWallbox">
              <SyncWallboxes />
            </PrivateRoute>
            <PrivateRoute exact path="/wallboxcomment">
              <WallboxComment />
            </PrivateRoute>
            <PrivateRoute exact path="/statisticsdashboard">
              <StatisticsDashboard />
            </PrivateRoute>
            <PrivateRoute exact path="/editwallboxuser/:user_id">
              <EditWallboxUser />
            </PrivateRoute>
            <PrivateRoute exact path="/listcarprofile">
              <ListCarProfile />
            </PrivateRoute>
            <PrivateRoute exact path="/addcarprofile">
              <AddCarProfile />
            </PrivateRoute>

            <Route exact path="/login" component={Login} />
            <Route exact path="/users/confirm_user/:token" component={ConfirmUser} />
          </Switch>
        </Router>
      </AuthProvider>


    </>
  );
};

export default App;
