import React, { useState } from "react";
import logoCloud from '../../images/logo/logoCloud.png';
import logo2 from '../../images/logo/logo2.png';
import LoginSlider from "./LoginSlider";
import "./Login.scss";
import useAuth from "../../Hooks/useAuth";
import { useHistory } from "react-router-dom";

const Login = () => {
    document.title = "Login - POWERGrid Cloud"
    const [inputEmail, setInputEmail] = useState("")
    const [inputPassword, setInputPassword] = useState("")
    const [isProcess, setIsProcess] = useState(false)
    const [message, setMessage] = useState("")


    const { LoginaViaPassword } = useAuth()
    const history = useHistory()


    const handleSubmit = e => {
        setIsProcess(true)
        e.preventDefault()
        LoginaViaPassword(inputEmail, inputPassword, history, setIsProcess, setMessage)


    }

    return (
        <>
            <div className="container-fluid p-0">
                <div className="login-area">
                    <div className="login-form">
                        <div className="logo-head">
                            <div className="logo-2">
                                <img src={logoCloud} alt="" />
                            </div>
                            <div className="h4">Melde dich bei deinem Konto an.</div>
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label className="emails"><span>*</span> Your Email</label>
                                    <input onChange={e => setInputEmail(e.target.value.trim())} required type="text" />
                                </div>
                                <div className="form-group">
                                    <label className="pass"><span>*</span> Passwort</label>
                                    <input onChange={e => setInputPassword(e.target.value)} required type="password" />
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="subBtn"><i className="fa-solid fa-unlock"></i> {isProcess ? "Loading..." : "Anmelden"}</button>
                                </div>
                                <div className="form-group">
                                    <div className="h5">{message}</div>
                                </div>
                            </form>
                        </div>
                        <div className="logo-footer">
                            <img src={logo2} alt="" />
                        </div>
                    </div>
                    <div className="login-slid ">
                        <LoginSlider />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;
