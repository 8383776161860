import React, { useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import NavBar from "../../NavBar/NavBar";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

const AddFirmware = () => {
  const [imageBinary, setImageBinary] = useState();
  const [isProcess, setIsProcess] = useState(false);
  const [firmwareObj, setFirmwareObj] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [message, setMessage] = useState("")

  const history = useHistory();
  const getFirmwareList = () => {
    history.push("/firmware");
  };

  const location = useLocation();
  const firmwareListState = location.state;

  

  const getFirmwareDataById = (id) => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_API_URL}/firmware_info/${id}`, {
        headers: {
          Authorization: "Bearer " + Cookies.get("access_token"),
        },
      })
      .then(function (response) {
        if (response?.data.data) {
          setFirmwareObj(response?.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleSubmit = (e, id) => {
    setIsProcess(true);
    e.preventDefault();

    let dataInfo = new FormData();
    dataInfo.append("branch", firmwareObj.branch);
    dataInfo.append("version", firmwareObj.version);
    dataInfo.append("changelog", firmwareObj.changelog);
    if (imageBinary){
      dataInfo.append("image_binary", imageBinary);
    } else {
      dataInfo.append("image_binary", null);
    }

    if (isUpdate) {
      axios
        .put(`${process.env.REACT_APP_BACKEND_API_URL}/firmware/${firmwareListState.firmwareId}`, dataInfo, {
          headers: {
            Authorization: "Bearer " + Cookies.get("access_token"),
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          if (response.data.success) {
            getFirmwareList();
            toast.success('Firmware Updated Successfuly', {autoClose:3000})
          }else{
            setMessage(response.data.message);
            toast.error(message, {autoClose:3000})
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .then(function () {
          setIsProcess(false);
        });
    } else {
      axios
        .post(`${process.env.REACT_APP_BACKEND_API_URL}/firmware`, dataInfo, {
          headers: {
            Authorization: "Bearer " + Cookies.get("access_token"),
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          if (response.data.success) {
            getFirmwareList();
            toast.success('Firmware Added Successfuly', {autoClose:3000})
          }else{
            setMessage(response.data.message);
            toast.error(message, {autoClose:3000})
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .then(function () {
          setIsProcess(false);
        });
    }
  };

  const handleFileSelected = (e) => {
    const files = e.target.files[0];
    setImageBinary(files);
  };


  useEffect(() => {
    setIsUpdate(firmwareListState?.update ? true : false);
    if (firmwareListState?.update) {
      getFirmwareDataById(firmwareListState.firmwareId);
    }
  }, []);

  return (
    <>
      <NavBar />
      <main className="wrapper">
        <div className="page-content">
          <div className="wallbox-area">
            <div className="pages-title-list">
              {isUpdate ? (
              <ul>
                <li>
                    <span>
                        <Link to="/firmware">
                        Firmware
                        </Link>
                    </span>
                </li>
                <li>
                    <span>Update Firmware</span>
                </li>
              </ul>
              ) : (
                <ul>
                <li>
                    <span>
                        <Link to="/firmware">
                        Firmware
                        </Link>
                    </span>
                </li>
                <li>
                    <span>Add Firmware</span>
                </li>
              </ul>
              )}
            </div>
            <div className="user__content">
              <div className="user__data">
                <div className="wallbox-tabl">
                  {
                    <div className="table-componet">
                      <div className="details_cont">
                        <div
                          className="wallbox_details"
                          style={{ position: "relative" }}
                        >
                          <div className="wallbox_details_content1">
                            <form onSubmit={handleSubmit}>
                              <div className="box_group">
                                <label>Branch</label>
                                <input
                                  type="text"
                                  value={firmwareObj?.branch}
                                  onChange={(e) => {
                                    firmwareObj.branch = e.target.value;
                                    setFirmwareObj({...firmwareObj})
                                  }}
                                  required
                                />
                              </div>
                              <div className="box_group">
                                <label>Version</label>
                                <input
                                  type="text"
                                  value={firmwareObj?.version}
                                  onChange={(e) => {
                                    firmwareObj.version = e.target.value;
                                    setFirmwareObj({...firmwareObj})
                                  }}
                                  required
                                />
                              </div>
                              <div className="box_group">
                                <label>Change Log</label>
                                <input
                                  type="text"
                                  value={firmwareObj?.changelog}
                                  onChange={(e) => {
                                    firmwareObj.changelog = e.target.value;
                                    setFirmwareObj({...firmwareObj})
                                  }}
                                  required
                                />
                              </div>
                              <div className="box_group">
                                <label>Image Binary</label>
                                <input
                                  type="file"
                                  onChange={handleFileSelected}
                                />
                              </div>
                              <button
                                className="btn-yellpow"
                                {...(isProcess ? "Loading..." : "Data Added..")}
                              >
                                Submit
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default AddFirmware;
