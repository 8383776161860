import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import logoCloud from "../../images/logo/logoCloud.png";
import logo2 from "../../images/logo/logo2.png";

function WallboxCommentForm(props) {

  const [commentData, setCommentData] = useState({
    id: 0,
    comment: "",
    updated_at: 0,
  });

  useEffect(() => {
    if (props?.data) {
      setCommentData(props.data);
    }
  }, [props]);

  const handleRegister = (e) => {
    e.preventDefault();
  };

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div
                    className="login-form"
                    style={{ height: "auto", padding: "0 20px" }}
                >
                    <div className="logo-head">
                        <div className="logo-2">
                            <img src={logoCloud} alt="" />
                        </div>
                        <div className="h4">Comment</div> 
                        <form onSubmit={handleRegister}>
                            <div className="form-group">
                                <label className="text">
                                    <span>*</span> Comment
                                </label>
                                <input
                                    type="text"
                                    required
                                    onChange={(e) =>
                                      setCommentData({
                                        ...commentData,
                                        comment: e.target.value,
                                      })
                                    }
                                    value={commentData?.comment}
                                    />
                            </div>
                            <div className="form-group">
                                <button
                                    type="submit"
                                    className="subBtn"
                                    onClick={() => props.callbacksubmit(commentData)}
                                >
                                    <i className="fa-solid fa-unlock"></i> Submit
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="logo-footer mt-5">
                        <img src={logo2} alt="" />
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default WallboxCommentForm;
