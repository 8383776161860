import React, { useState } from 'react';
import search from "../../images/icons/search.png"
import dot3 from "../../images/icons/dot3.png"

import { BsArrowUp, BsChevronDoubleRight } from "react-icons/bs";

import { Link } from "react-router-dom";
import { TailSpin } from 'react-loader-spinner';
import { useHistory } from "react-router-dom";

const Users = [
    {
        id: 100,
        selected: false,
        name: "create.user",
        Description: "Create new roles",
        type: "create",
        application: "POWERGrid Cloud",
        Add_In: "Dashboard",
        Component: "LTE",
        userCount: 3,

    },
    {
        id: 101,
        selected: false,
        name: "create.permission",
        Description: "Create new permissions",
        type: "create",
        application: "POWERGrid Cloud",
        Add_In: "Dashboard",
        Component: "LTE",
        userCount: 3,

    },
    {
        id: 102,
        selected: false,
        name: "create.environment",
        Description: "Create new environments",
        type: "create",
        application: "POWERGrid Cloud",
        Add_In: "Dashboard",
        Component: "LTE",
        userCount: 3,

    },
    {
        id: 103,
        selected: false,
        name: "read.user",
        Description: "View all Standard Users",
        type: "read",
        application: "POWERGrid Cloud",
        Add_In: "Dashboard",
        Component: "LTE",
        userCount: 3,

    },
    {
        id: 104,
        selected: false,
        name: "create.user",
        Description: "Create new roles",
        type: "read",
        application: "POWERGrid Cloud",
        Add_In: "Dashboard",
        Component: "LTE",
        userCount: 16,

    },
    {
        id: 105,
        selected: false,
        name: "create.user",
        Description: "Create new roles",
        type: "read",
        application: "POWERGrid Cloud",
        Add_In: "Dashboard",
        Component: "LTE",
        userCount: 16,

    },
    {
        id: 106,
        selected: false,
        name: "create.user",
        Description: "Create new roles",
        type: "update",
        application: "POWERGrid Cloud",
        Add_In: "Dashboard",
        Component: "LTE",
        userCount: 16,

    },
    {
        id: 107,
        selected: false,
        name: "create.user",
        Description: "Create new roles",
        type: "update",
        application: "POWERGrid Cloud",
        Add_In: "Dashboard",
        Component: "LTE",
        userCount: 16,
    },
    {
        id: 108,
        selected: false,
        name: "create.user",
        Description: "Create new roles",
        type: "delete",
        application: "POWERGrid Cloud",
        Add_In: "Dashboard",
        Component: "LTE",
        userCount: 16,

    },
    {
        id: 109,
        selected: false,
        name: "create.user",
        Description: "Create new roles",
        type: "use function",
        application: "POWERGrid Cloud",
        Add_In: "Dashboard",
        Component: "LTE",
        userCount: 16,
    },
    {
        id: 110,
        selected: false,
        name: "create.user",
        Description: "Create new roles",
        type: "use function",
        application: "POWERGrid Cloud",
        Add_In: "Dashboard",
        Component: "LTE",
        userCount: 16,

    },
    {
        id: 111,
        selected: false,
        name: "create.user",
        Description: "Create new roles",
        type: "use function",
        application: "POWERGrid Cloud",
        Add_In: "Dashboard",
        Component: "LTE",
        userCount: 16,
    },
    {
        id: 112,
        selected: false,
        name: "create.user",
        Description: "Create new roles",
        type: "create",
        application: "POWERGrid Cloud",
        Add_In: "Dashboard",
        Component: "LTE",
        userCount: 16,
    },
    {
        id: 113,
        selected: false,
        name: "create.user",
        Description: "Create new roles",
        type: "create",
        application: "POWERGrid Cloud",
        Add_In: "Dashboard",
        Component: "LTE",
        userCount: 16,
    },
    {
        id: 114,
        selected: false,
        name: "create.user",
        Description: "Create new roles",
        type: "create",
        application: "POWERGrid Cloud",
        Add_In: "Dashboard",
        Component: "LTE",
        userCount: 16,
    },
    {
        id: 115,
        selected: false,
        name: "create.user",
        Description: "Create new roles",
        type: "create",
        application: "POWERGrid Cloud",
        Add_In: "Dashboard",
        Component: "LTE",
        userCount: 16,
    },
    {
        id: 116,
        selected: false,
        name: "create.user",
        Description: "Create new roles",
        type: "create",
        application: "POWERGrid Cloud",
        Add_In: "Dashboard",
        Component: "LTE",
        userCount: 16,
    },
    {
        id: 117,
        selected: false,
        name: "create.user",
        Description: "Create new roles",
        type: "create",
        application: "POWERGrid Cloud",
        Add_In: "Dashboard",
        Component: "LTE",
        userCount: 16,
    },


];

// Sorting function
const sortedList = (data, order, item) => {
    if (order === 'up') {
        return data.sort((a, b) => (a[item] ? 1 : -1));
    }
    if (order === 'down') {
        return data.sort((a, b) => (a[item] ? -1 : 1));
    }
}

const PermissionsRow = ({ permissions, isFetching }) => {
    const [List, setList] = useState(permissions)
    const [MasterChecked, setMasterChecked] = useState(false)
    const [SelectedList, setSelectedList] = useState([])
    const [currentSort, setCurrentSort] = useState('up')

    const history = useHistory();
    const showRoleList = (permissionId) => {
        history.push("/roles", {permissionId: permissionId});
    }

    function onMasterCheck(e) {
        let tempList = List;
        // Check/ UnCheck All Items
        tempList.map((user) => (user.selected = e.target.checked));

        //Update State
        setMasterChecked(e.target.checked)
        setList(tempList)
        setSelectedList(List.filter((e) => e.selected))
    }

    function onItemCheck(e, item) {
        let tempList = List;
        tempList.map((user) => {
            if (user.id === item.id) {
                user.selected = e.target.checked;
            }
            return user;
        });

        //To Control Master Checkbox State
        const totalItems = List.length;
        const totalCheckedItems = tempList.filter((e) => e.selected).length;

        // Update State
        setMasterChecked(totalItems === totalCheckedItems)
        setList(tempList)
        setSelectedList(List.filter((e) => e.selected))
    }

    function getSelectedRows() {
        setSelectedList(List.filter((e) => e.selected))
    }


    const onSortChange = () => {
        let nextSort;

        if (currentSort === 'down') {
            nextSort = 'up'
        }
        else if (currentSort === 'up') {
            nextSort = 'down';
        }
        setCurrentSort(nextSort)
    };


    return (
        <>
            <div className="table-componet">
                <div className="row">
                    <div className="col-md-12">
                        <div className="tab__content">
                            <table className="table box-table">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={MasterChecked}
                                                id="mastercheck"
                                                onChange={(e) => onMasterCheck(e)}
                                            />
                                        </th>
                                        <th onClick={onSortChange} scope="col" className="id">
                                            <span className="d-installed">ID  <BsArrowUp /></span>
                                        </th>
                                        <th scope="col" className="serialT">Permission Name</th>
                                        <th scope="col" className="Description">Description</th>
                                        <th scope="col" className="Type_tt">Type </th>
                                        <th scope="col" className="Application">Application</th>
                                        <th scope="col" className="Add_In">Add-In </th>
                                        <th scope="col" className="Component">Component </th>
                                        <th scope="col" className="Roles_tt">Roles </th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {isFetching ?
                                    <tr className="router-loading-spinner">
                                        <td>
                                            <div className="router-loading-spinner">
                                                <TailSpin width={50} height={50} color="#002060" />
                                            </div>
                                        </td>
                                    </tr>
                                        :
                                        permissions.map((permission, index) => (
                                            <SingleRow
                                                onItemCheck={onItemCheck}
                                                permission={permission}
                                                showRoleList={showRoleList}
                                                key={index}
                                            />
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="pagination-content">
                    <ul>
                        <li className="active">1</li>
                        {/* <li>3</li>
                        <li>4</li>
                        <li><BsChevronDoubleRight /></li> */}
                    </ul>
                    <div className="pageRight">
                        <span>Showing {permissions.length} of {permissions.length} items</span>
                    </div>
                </div>
            </div>

        </>
    );
};


const SingleRow = ({ onItemCheck, permission, showRoleList }) => {
    return (
        <tr key={permission.id} className={permission.selected ? "selected" : ""}>
            <th scope="row">
                <input
                    type="checkbox"
                    checked={permission.selected}
                    className="form-check-input"
                    id="rowcheck{user.id}"
                    onChange={(e) => onItemCheck(e, permission)}
                />
            </th>
            <td><span>{permission?.ID}</span></td>
            <td className="wallboxSerial"><span><Link to="#">{permission?.PermissionName}</Link></span></td>
            <td className="Description_tt"><span>{permission?.description}</span></td>
            <td className="type_tt"><span /* className={user.type} */>Not Found</span></td>
            <td className="application_tt"><span>{permission?.App}</span></td>
            <td className="Add_In_tt"><span>{permission?.AddIn}</span></td>
            <td className="Component_tt"><span>{permission?.Component}</span></td>
            <td className="box_dot_tt">
                <span className="" >
                    <button className="box_dot" onClick={() => showRoleList(permission?.ID)}>
                        <img alt="" src={dot3} /> {permission?.role.length} Roles
                    </button>
                </span>
            </td>
            <td className="Search">
                <span>
                    <button><img alt="" src={search} /></button>
                </span>
            </td>
        </tr>
    )
}

export default PermissionsRow;