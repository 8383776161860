import React, { useEffect, useState } from "react";
import axios from "axios"
import Cookies from 'js-cookie'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import NavBar from "../../NavBar/NavBar";
import filterAdd from "../../../images/icons/filter-add.png";
import { AiOutlineSearch } from "react-icons/ai";
import rolesI from "../../../images/icons/roles.png";
import ppermiI from "../../../images/icons/ppermi.png";
import "../User.scss";
import { Link } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Form from 'react-bootstrap/Form';
toast.configure()


const UserLogControl = () => {
    document.title = "UserLogControl - POWERGrid Cloud"

    const [userLog, setUserLog] = useState();
    const [isFetching, setIsFetching] = useState(true)
    const [pageCount, setPageCount] = useState(1)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalDataCount, setTotalDataCount] = useState()
    const [searchValue, setSearchValue] = useState("")
    const [displaySize, setDisplaySize] = useState(20)
    const [sorting, setSorting] = useState("ID")
    const [orderWay, setOrderWay] = useState("desc")
    const [userLogUpdate, setUserLogUpdate] = useState("")
    const [userLogList, setUserLogList] = useState([])

    const onOrderChange = () => {
        if (orderWay === "desc") {
            setOrderWay("asc")
        } else {
            setOrderWay("desc")
        }
    }
    
    const getUserLogStatus = () => {
        let log_status_url = `${process.env.REACT_APP_BACKEND_API_URL}/log_status?order=${sorting}&order_way=${orderWay}&search_string=${searchValue}&page=${currentPage}&page_size=${displaySize}`
        axios.get(log_status_url, {
            headers: {
                Authorization: "Bearer " + Cookies.get("access_token")
            }
        })
        .then(response => {
            setIsFetching(false)
            const userLogs = response?.data?.data
            if (response?.data?.total_count > 0) {
                userLogs.forEach(element => {
                    element.selected = false
                });
                setTotalDataCount(response?.data?.total_count)
                setPageCount(Math.ceil(response?.data?.total_count / displaySize))
                setUserLog(userLogs)
            } else {
                setTotalDataCount(response?.data?.total_count)
            }
        })
        .catch(function (error) {
            setIsFetching(false)
            console.log(error);
        })
        .then(() => {
            setIsFetching(false)
        })
    }

    const onLogCheck = (event, user_id) => {
        const dataDict = {
            id: user_id,
            is_log: event.target.checked
        }
        setUserLogList(oldArray=> [...oldArray, dataDict])
    }

    const onSubmit = () => {
        if (userLogUpdate) {
            axios.put(`${process.env.REACT_APP_BACKEND_API_URL}/log_status`, userLogList, {
                headers: {
                    Authorization: "Bearer " + Cookies.get("access_token")
                }
            })
            .then(function (response) {
                if (response.data) {
                    setUserLogUpdate(false)
                    getUserLogStatus();
                }
            })
            .catch(function (error) {
                console.log(error);
            })
        }
    }

    useEffect(()=>{
        getUserLogStatus();
    }, [currentPage, sorting, orderWay, searchValue, displaySize])

    return (
        <>
            <NavBar />
            <main className="wrapper">
                <div className="page-content">
                    <div className="wallbox-area">
                        <div className="pages-title">
                            <div className="h2">Permissions</div>
                            <span>View all users in one table</span>
                        </div>
                        <div className="user__content">
                            <div className="user__data">
                                <ul className="panel_section hhh" style={{ padding: "30px 12px" }}>
                                    <li>
                                        <span>Panel Selections</span>
                                    </li>
                                    <li>
                                        <Link to="/rolePermission"><img alt="" src={ppermiI} /> Permission's Roles</Link>
                                    </li>
                                    <li>
                                        <Link to="/userRole"><img alt="" src={rolesI} />User's Roles </Link>
                                    </li>
                                    <li>
                                        <Link to="/userlogcontrol" className="active"><img alt="" src={rolesI} />User Log Control</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="user__data">
                            <div className="search-filter">
                                    <form action="">
                                        <input type="text" value={searchValue} onChange={e => setSearchValue(e.target.value)} placeholder="Search By Entire ID..." />
                                        <button onClick={e => e.preventDefault()} type="submit"><AiOutlineSearch /></button>
                                    </form>
                                    <div className="sort-date">
                                        Sort by:
                                        <Form.Select onChange={(e) => setSorting(e.target.value)} aria-label="Default select example">
                                            <option value="created_at">Created At</option>
                                            <option value="id">ID</option>
                                        </Form.Select>
                                    </div>
                                    <div className="sort-date">
                                        Order way:
                                        <Form.Select defaultValue={"Descending"} onChange={onOrderChange} aria-label="Default select example">
                                            <option>Ascending</option>
                                            <option>Descending</option>
                                        </Form.Select>
                                    </div>
                                    <div className="sort-date">
                                        Display size:
                                        <Form.Select defaultValue={20} onChange={e => setDisplaySize(e.target.value)} aria-label="Default select example">
                                            <option value={10}>10</option>
                                            <option value={20}>20</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                        </Form.Select>
                                    </div>
                                    <div className="filter">
                                        Filter <img alt="" src={filterAdd} />
                                    </div>
                                    {userLogUpdate?
                                    <button
                                        className="btn-yellpow"
                                        onClick={() => {
                                            onSubmit();
                                        }}
                                        >Submit
                                    </button>
                                    : 
                                    <button
                                        className="btn-yellpow"
                                        onClick={() => {
                                            setUserLogUpdate(true);
                                        }}
                                        >Update
                                    </button>
                                    }
                                </div>
                                <div className="wallbox-tabl">
                                    <div className="table-componet">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="tab__content">
                                                    <table className="table box-table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" className="serialT">User Email</th>
                                                                <th scope="col" className="serialT">User Name</th>
                                                                <th scope="col" className="serialT">Log Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {isFetching ?
                                                            <tr>
                                                                <td>
                                                                    <div className="router-loading-spinner">
                                                                        <TailSpin width={50} height={50} color="#002060" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            :
                                                            userLog?.map((logs, index)=>{
                                                                return(
                                                                    <tr>
                                                                        <td>
                                                                            <label class="form-check-label" for="check1">
                                                                                {logs.email.length > 1 ? logs.email : "--"}
                                                                            </label>
                                                                        </td>
                                                                        <td>
                                                                            <label class="form-check-label" for="check1">
                                                                                {logs.name}
                                                                            </label>
                                                                        </td>
                                                                        <td>
                                                                        {
                                                                            userLogUpdate ? <input class="form-check-input" onClick={(e)=>onLogCheck(e, logs.id)} type="checkbox" defaultChecked={logs?.log_status ? true : false} id="check1"/>
                                                                            :
                                                                            <input class="form-check-input" type="checkbox" checked={logs?.log_status ? true : false} id="check1"/>
                                                                        }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                            
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pagination-content">
                                            <ul>
                                                <Stack spacing={2}>
                                                    <Pagination onChange={(e, pn) => setCurrentPage(pn)} count={pageCount} />
                                                </Stack>
                                            </ul>
                                            <div className="pageRight">
                                                <span>Showing {userLog?.length} of {totalDataCount} items</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default UserLogControl;
