import React, { useState, useEffect } from "react";
import NavBar from "../../NavBar/NavBar";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

const AddMeasurement = () => {

    const [isProcess, setIsProcess] = useState(false);
    const [measurementObj, setMeasurementObj] = useState({});
    const [isUpdate, setIsUpdate] = useState(false);
    const [message, setMessage] = useState("")

    const history = useHistory();
    const getMeasurementList = () => {
        history.push("/measurement");
    };

    const location = useLocation();
    const measurementListState = location.state;

    const getMeasurementDataById = (id) => {
        axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/measurements/${id}`, {
            headers: {
                Authorization: "Bearer " + Cookies.get("access_token"),
        },
        })
        .then(function (response) {
            if (response?.data.data) {
                setMeasurementObj(response?.data.data);
        }
        })
        .catch(function (error) {
            console.log(error);
        });
    };

    const handleSubmit = (e) => {
        setIsProcess(true);
        e.preventDefault();
    
        let dataInfo = {
            serial_number: measurementObj.serial_number,
            channel: measurementObj.channel,
            parameter: measurementObj.parameter,
            value: measurementObj.value
        }
        if (isUpdate) {
          axios
            .put(`${process.env.REACT_APP_BACKEND_API_URL}/measurements/${measurementListState.measurementId}`, dataInfo, {
              headers: {
                Authorization: "Bearer " + Cookies.get("access_token"),
                "Content-Type": "multipart/form-data",
              },
            })
            .then(function (response) {
              if (response.data.success) {
                getMeasurementList();
                toast.success('Measurement Updated Successfuly', {autoClose:3000})
              }else{
                setMessage(response.data.message);
                toast.error(message, {autoClose:3000})
              }
            })
            .catch(function (error) {
              console.log(error);
            })
            .then(function () {
              setIsProcess(false);
            });
        } else {
          axios
            .post(`${process.env.REACT_APP_BACKEND_API_URL}/measurements`, dataInfo, {
              headers: {
                Authorization: "Bearer " + Cookies.get("access_token"),
                "Content-Type": "multipart/form-data",
              },
            })
            .then(function (response) {
              if (response.data.success) {
                getMeasurementList();
                toast.success('Measurement Added Successfuly', {autoClose:3000})
              }else{
                setMessage(response.data.message);
                toast.error(message, {autoClose:3000})
              }
            })
            .catch(function (error) {
              console.log(error);
            })
            .then(function () {
              setIsProcess(false);
            });
        }
    };

    useEffect(() => {
        setIsUpdate(measurementListState?.update ? true : false);
        if (measurementListState?.update) {
            getMeasurementDataById(measurementListState.measurementId);
        }
    }, []);

    return(
        <>
        <NavBar />
        <main className="wrapper">
            <div className="page-content">
                <div className="wallbox-area">
                    <div className="pages-title-list">
                        {isUpdate ? (
                            <ul>
                            <li>
                                <span>
                                    <Link to="/measurement">
                                    Measurement
                                    </Link>
                                </span>
                            </li>
                            <li>
                                <span>Update Measurement</span>
                            </li>
                          </ul>
                        ) : (
                            <ul>
                            <li>
                                <span>
                                    <Link to="/measurement">
                                    Measurement
                                    </Link>
                                </span>
                            </li>
                            <li>
                                <span>Add Measurement</span>
                            </li>
                          </ul>
                        )}
                    </div>
                    <div className="user__content">
                        <div className="user__data">
                            <div className="wallbox-tabl">
                            {
                                <div className="table-componet">
                                    <div className="details_cont">
                                        <div className="wallbox_details" style={{ position: "relative" }}>
                                            <div className="wallbox_details_content1">
                                                <form onSubmit={handleSubmit}>
                                                    <div className="box_group">
                                                        <label>Serial Number</label>
                                                        <input
                                                        type="text"
                                                        value={measurementObj?.serial_number}
                                                        onChange={(e) => {
                                                            measurementObj.serial_number = e.target.value;
                                                            setMeasurementObj({...measurementObj})
                                                        }}
                                                        required
                                                        />
                                                    </div>
                                                    <div className="box_group">
                                                        <label>Channel</label>
                                                        <input
                                                        type="text"
                                                        // value={measurementObj?.channel}
                                                        onChange={(e) => {
                                                            measurementObj.channel = e.target.value;
                                                            setMeasurementObj({...measurementObj})
                                                        }}
                                                        required
                                                        />
                                                    </div>
                                                    <div className="box_group">
                                                        <label>Parameter</label>
                                                        <input
                                                        type="text"
                                                        // value={measurementObj?.parameter}
                                                        onChange={(e) => {
                                                            measurementObj.parameter = e.target.value;
                                                            setMeasurementObj({...measurementObj})
                                                        }}
                                                        required
                                                        />
                                                    </div>
                                                    <div className="box_group">
                                                        <label>Value</label>
                                                        <input
                                                        type="text"
                                                        value={measurementObj?.value}
                                                        onChange={(e) => {
                                                            measurementObj.value = e.target.value;
                                                            setMeasurementObj({...measurementObj})
                                                        }}
                                                        required
                                                        />
                                                    </div>
                                                    <button
                                                        className="btn-yellpow"
                                                        {...(isProcess ? "Loading..." : "Data Added..")}
                                                    >
                                                        Submit
                                                    </button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        </>
    );
};

export default AddMeasurement;