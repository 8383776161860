import React, { useState, useEffect } from "react";
import NavBar from "../../NavBar/NavBar";
import axios from "axios";
import Cookies from "js-cookie";
import { TailSpin } from "react-loader-spinner";
import "./Relesenotes.scss"

const ReleaseNotes = () => {
  document.title = "ReleaseNotes - POWERGrid Cloud";
  const [data, setData] = useState([]);
  const [isFetcing, setIsFetching] = useState(true);
  const [isDe, setIsDe] = useState(true);

  useEffect(() => {
    let end = isDe ? "DE" : "EN";
    axios
      .get(`${process.env.REACT_APP_BACKEND_API_URL}/translate_release_notes/${end}`, {
        headers: {
          Authorization: "Bearer " + Cookies.get("access_token"),
        },
      })
      .then(function (response) {
        setData(response.data);
        setIsFetching(false);
      })
      .catch(function (error) {
        setIsFetching(false);
        console.log(error);
      });
  }, [isDe]);

  return (
    <>
      <NavBar />
      <main className="wrapper">
        <div className="page-content">
          <div className="wallbox-area">
            <div className="pages-title">
              <div className="h2">Release Notes</div>
              <span>View all release notes</span>
            </div>
            <div className="table-componet" style={{ display: "contents" }}>
              <div className="col-md-12">
                <div className="col-md-12">
                  <div className="tab__content mt-5">
                    <table className="table box-table">
                      <thead>
                        <tr>
                          <th style={{ width: "150px"}} scope="col" className="serialT">
                            <h6>
                              <b> Release Date</b>
                            </h6>
                          </th>
                          <th scope="col" className="serialT">
                            <h6>
                              <b> Summary</b>
                            </h6>
                          </th>
                          <th scope="col" className="serialT">
                            <h6>
                              <b> App</b>
                            </h6>
                          </th>
                          <th scope="col" className="serialT">
                            <h6>
                              <b> Version</b>
                            </h6>
                          </th>
                          <th style={{ width: "58px"}}  scope="col" className="serialT">
                            <button className="btn-yellpow" onClick={() => setIsDe(!isDe)}>
                              {isDe ? "EN" : "DE"}
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                      {isFetcing ? (
                        <tr>
                          <td>
                            <div className="router-loading-spinner">
                              <TailSpin width={50} height={50} color="#002060" />
                            </div>
                          </td>
                        </tr>
                        ) : (
                          data.map((item, i) => {
                            return (
                              <tr key={i} style={{ fontSize: "13px" }}>
                                <td valign="middle">{Object.values(item)[0]}</td>
                                {
                                  typeof Object.values(item)[1] == "object" 
                                  ?
                                      Object.values(item)[1].map((summary, index) => (
                                      <tr key={index}>
                                        <td valign="middle">
                                          <span><b>{summary?.heading}</b> - {summary?.description}</span>
                                        </td>
                                      </tr>
                                      ))
                                  :
                                  <td valign="middle" className="pe-5 w-75">{Object.values(item)[1]}</td> 

                                }
                                <td valign="middle" className="newclass"><span>{Object.values(item)[2]}</span></td>
                                <td valign="middle">{Object.values(item)[3]}</td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default ReleaseNotes;
