import { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import logoCloud from "../../../images/logo/logoCloud.png";
import logo2 from "../../../images/logo/logo2.png";

function BillingServiceForm(props) {
  const [chargeTransactionData, setChargeTransactionData] = useState({
    id: 0,
    wallbox_serial_number: "",
    email: "",
  });
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (props?.data) {
      setChargeTransactionData(props.data);
    }
  }, [props]);

  const handleRegister = (e) => {
    e.preventDefault();
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div
          className="login-form"
          style={{ height: "auto", padding: "0 20px" }}
        >
          <div className="logo-head">
            <div className="logo-2">
              <img src={logoCloud} alt="" />
            </div>
            <div className="h4">Register a new charge transaction email </div>
            <form onSubmit={handleRegister}>
              <div className="form-group">
                <label className="emails">
                  <span>*</span> Email
                </label>
                <input
                  required
                  onChange={(e) =>
                    setChargeTransactionData({
                      ...chargeTransactionData,
                      email: e.target.value,
                    })
                  }
                  type="email"
                  value={chargeTransactionData?.email}
                />
              </div>
              <div className="form-group">
                <label className="FirstName">
                  <span>*</span> Serial Number
                </label>
                <input
                  required
                  onChange={(e) =>
                    setChargeTransactionData({
                      ...chargeTransactionData,
                      wallbox_serial_number: e.target.value,
                    })
                  }
                  type="text"
                  value={chargeTransactionData?.wallbox_serial_number}
                />
              </div>
              <div className="form-group">
                <button
                  type="submit"
                  className="subBtn"
                  onClick={() => props.callBackSubmit(chargeTransactionData)}
                >
                  <i className="fa-solid fa-unlock"></i> Register
                </button>
              </div>
            </form>
            <div className="message">
              <p className="mt-2" style={{ color: "gray", fontWeight: "bold" }}>
                {message}
              </p>
            </div>
          </div>
          <div className="logo-footer mt-5">
            <img src={logo2} alt="" />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default BillingServiceForm;
