import React, { useState, useEffect } from "react";
import logoCloud from '../../images/logo/logoCloud.png';
import logo2 from '../../images/logo/logo2.png';
import LoginSlider from "../Login/LoginSlider";
import "../Login/Login.scss";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";

const ConfirmUser = () => {
    document.title = "Confirm User - POWERGrid Cloud"

    const [message, setMessage] = useState("");
    const [email, setEmail] = useState();
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [isExpired, setIsExpired] = useState(false);

    const params = useParams()

    const confirmUser = () => {
        axios.get(`${process.env.REACT_APP_USER_BACKEND_API_URL}/users/confirm_user/${params?.token}`, {})
        .then(function (response) {
            if (response.data.status == true){
                setIsConfirmed(true)
                setMessage(response.data.message)
            } else {
                setIsExpired(true)
                setMessage(response.data.message)
                setEmail(response.data.data)
            }
        })
        .catch(function (error) {
            setMessage(error)
            console.log(error)
        })
    }

    const resendEmail = () => {
        axios.get(`${process.env.REACT_APP_USER_BACKEND_API_URL}/users/resend_email/${email}`, {})
        .then(function (response) {
            setIsConfirmed(false)
            setIsExpired(false)
            setMessage(response.data)
        })
        .catch(function (error) {
            setMessage(error)
            console.log(error)
        })
    }

    useEffect(() => {
        confirmUser();
      }, [])

    return (
        <>
            <div className="container-fluid p-0">
                <div className="login-area">
                    <div className="login-form">
                        <div className="logo-head">
                            <div className="logo-2">
                                <img src={logoCloud} alt="" />
                            </div>
                            <div className="message">
                                <p className="mt-5" style={{ color: "gray", fontWeight: "bold" }}>
                                    {message} 
                                    {
                                        isConfirmed ?
                                        <p>Please click here to <Link style={{ color: "#F49945", fontWeight: "800" }} to="/login">Login</Link></p>
                                        :
                                        isExpired ?
                                        <p>please click here to <button style={{ color: "#F49945", fontWeight: "800" }} onClick={resendEmail}>Resend Email</button></p>
                                        :
                                        null
                                    }
                                </p>
                            </div>
                        </div>
                        <div className="logo-footer">
                            <img src={logo2} alt="" />
                        </div>
                    </div>
                    <div className="login-slid ">
                        <LoginSlider />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ConfirmUser;