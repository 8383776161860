import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import axios from "axios";
import Cookies from "js-cookie";
import Chart from "chart.js/auto";

const PowerConsuptionGraph = () => {
  const [energy, setEnergy] = useState([]);
  const [time, setTime] = useState([]);
  const [events, setEvents] = useState([]);
  const [days, setDays] = useState(1)

  const state = {
    labels: [...time],
    datasets: [
      {
        label: "Energy in KWh",
        fill: false,
        lineTension: 0.5,
        backgroundColor: "rgba(75,192,192,1)",
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 2,
        data: [...energy, ...events],
      },

      {
        label: "Events",
        fill: false,
        lineTension: 0.5,
        backgroundColor: "rgba(456,122,112,2)",
        borderColor: "rgba(0,0,0,5)",
        borderWidth: 2,
        data: [...events],
      },
    ],
  };

  const oneDayEvents = (days) => {
    axios
        .get(`${process.env.REACT_APP_BACKEND_API_URL}/event_chart_data?time=${days}`, {
          headers: {
          Authorization: "Bearer " + Cookies.get("access_token"),
        },
        })
        .then(function (response) {
          setTime([...response.data.data.time]);
          setEnergy([...response.data.data.energy]);
          setEvents([...response.data.data.events]);
          setDays(days);
        })
        .catch(function (error) {
          console.log(error);
        });
    }

  useEffect(() => {
    oneDayEvents(1);
  }, []);

  return (
    <div>
    <div className="charts">
      <div className="graphModule__topButton">
        <button className={days===1?"active":""} onClick={()=>oneDayEvents(1)}>Day</button>
        <button className={days===7?"active":""} onClick={()=>oneDayEvents(7)}>Week</button>
        <button className={days===30?"active":""} onClick={()=>oneDayEvents(30)}>Month</button>
        <button className={days===365?"active":""} onClick={()=>oneDayEvents(365)}>Year</button>
      </div>
      <Line
        data={state}
        options={{
          title: {
            display: true,
            text: "Average Rainfall per month",
            fontSize: 20,
          },
          legend: {
            display: true,
            position: "right",
          },
        }}
      />
    </div>
    </div>
  );
};

export default PowerConsuptionGraph;
