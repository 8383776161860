import React, { useState } from "react";

import search from "../../images/icons/search.png"
import vpn_key from "../../images/icons/vpn_key.png"
/* import dot3 from "../../images/icons/dot3.png" */
import Cookies from 'js-cookie'

/* import { BsChevronDoubleRight } from "react-icons/bs"; */

import { Link } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import axios from "axios"
import { getPermission } from "../../utils";


// Sorting function


const UserRows = ({ users, isFetcing }) => {

    const [List, setList] = useState(users)
    const [MasterChecked, setMasterChecked] = useState(false)
    const [SelectedList, setSelectedList] = useState([])
    const [currentSort, setCurrentSort] = useState("up")

    const checkResetPasswordTitle = getPermission("reset_password")

    function onMasterCheck(e) {
        let tempList = List;
        // Check/ UnCheck All Items
        tempList.map((user) => (user.selected = e.target.checked));

        //Update State
        setMasterChecked(e.target.checked)
        setList(tempList)
        setSelectedList(List.filter((e) => e.selected))
    }

    function onItemCheck(e, item) {
        let tempList = List;
        tempList.map((user) => {
            if (user.id === item.id) {
                user.selected = e.target.checked;
            }
            return user;
        });

        //To Control Master Checkbox State
        const totalItems = List.length;
        const totalCheckedItems = tempList.filter((e) => e.selected).length;

        // Update State

        setMasterChecked(totalItems === totalCheckedItems)
        setList(tempList)
        setSelectedList(List.filter((e) => e.selected))
    }

    function getSelectedRows() {
        setSelectedList(List.filter((e) => e.selected))
    }


    return (
        <>
            <div className="table-componet">
                <div className="row">
                    <div className="col-md-12">
                        <div className="tab__content">
                            <table className="table box-table user_table">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={MasterChecked}
                                                id="mastercheck"
                                                onChange={(e) => onMasterCheck(e)}
                                            />
                                        </th>{/* 
                                        <th onClick={onSortChange} scope="col" className="id">
                                            <span className="d-installed">  <BsArrowUp /></span>
                                        </th> */}
                                        <th style={{ minWidth: "200px" }} scope="col" className="serialT">ID</th>
                                        <th scope="col" className="serialT">Name</th>
                                        <th scope="col" className="FirmwareT">E-Mail</th>
                                        {checkResetPasswordTitle == true ?
                                        <th scope="col" className="Energy_T">Password </th>
                                        :
                                        null
                                        }
                                        <th scope="col" className="statusT">Status</th>
                                        <th scope="col" className="Energy_T">Roles</th>
                                        <th scope="col" className="ownerT">Wallboxes </th>
                                        <th scope="col" className="ownerT">Konten </th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* <tr className={user.selected ? "selected" : ""}> */}
                                    {
                                        isFetcing ? 
                                        <tr className="router-loading-spinner">
                                            <td>
                                                <div className="router-loading-spinner">
                                                    <TailSpin width={50} height={50} color="#002060" />
                                                </div>
                                            </td>
                                        </tr>
                                        :
                                        users.map((user, index) => <SingleRows key={index} user={user} />)
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="pagination-content">
                    <ul>
                        <li className="active">1</li>
                        {/*   <li><BsChevronDoubleRight /></li> */}
                    </ul>
                    <div className="pageRight">
                        <span>Showing {users.length} of {users.length} items</span>
                    </div>
                </div>
            </div>
        </>
    )
}

const SingleRows = ({ user }) => {
    const { Email, FirstName, LastName, Status, Roles, ID } = user
    const checkResetPassword = getPermission("add_measurements");
    const handlePassReset = (email) => {
        const askToConfirm = window.confirm(`Are you sure want to Reset password of ${email}`)
        if (askToConfirm) {
            const resetPasswordInfo = new URLSearchParams(Object.entries({
                Email: email
            })).toString();
            axios.post(`${process.env.REACT_APP_USER_BACKEND_API_URL}/users/forgot_password`, resetPasswordInfo, {
                headers: {
                    Authorization: "Bearer " + Cookies.get("access_token")
                }
            })
                .then(function (response) {
                    window.alert(response.data)
                })
                .catch(function (error) {
                    console.log(error)
                })
        }
    }

    return (
        <tr>
            <th scope="row">
                <input
                    type="checkbox"
                    /*  checked={user.selected} */
                    className="form-check-input"
                    id="rowcheck{user.id}"
                /* onChange={(e) => onItemCheck(e, user)} */
                />
            </th>
            <td><span>{ID}</span></td>
            <td className="wallboxSerial"><span><Link to={`/user/${ID}`}>{FirstName + " " + LastName}</Link></span></td>
            <td className="FirmwareVersion"><span>{Email}</span></td>
            {checkResetPassword == true ?
            <td className="reset_pass" onClick={() => handlePassReset(Email)}><span> <img alt="" src={vpn_key} /> Reset Password</span></td>
            :
            null
            }
            <td className="Stat">
                <span className={Status === "active" ? "Active" : "inactive"}>{Status}</span>
            </td>
            <td className="roles_tt"><span>{Roles.toString()}</span></td>
            <td className="wallboxes_tt">
                {/* <span className="">

                                                    {
                                                        user.wallboxes.length === 1 && <button className="box_dot">  {user.wallboxes} </button>
                                                    }

                                                    {user.wallboxes.length > 1 && (
                                                        <button className="box_dot">
                                                            <img alt="" src={dot3} /> {user.wallboxes.length} Wallboxes
                                                        </button>
                                                    )}
                                                </span> */}
            </td>
            <td className="konten">
                <span>
                    <button className="konten_btn">Geschäftlich</button>
                    <button className="konten_btn">Privat</button>
                </span>
            </td>
            <td className="Search">
                <span>
                    <button><img alt="" src={search} /></button>
                </span>
            </td>
        </tr>
    )
}

export default UserRows