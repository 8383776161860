import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';
import axios from "axios"
import Cookies from 'js-cookie'
import NavBar from "../../NavBar/NavBar";
import filterAdd from "../../../images/icons/filter-add.png";
import userI from "../../../images/icons/user.png";
import rolesI from "../../../images/icons/roles.png";
import ppermiI from "../../../images/icons/ppermi.png";
import { AiOutlineSearch, AiOutlinePlus } from "react-icons/ai";
import AddPermissionForm from "../AddPermission";
import getUserPermissions from "../../../utils";
import { getPermission } from "../../../utils";

import "../User.scss";
import { Link } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
// import UserRows from "./UserRows";
// import MyVerticallyCenteredModal from "./SignUpModal";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()


const Permission = () => {
    document.title = "User - POWERGrid Cloud"
    const [roles, setRoles] = useState([])
    const [roleList, setRoleList] = useState([])
    const [isFetcing, setIsFetching] = useState(true)
    const [permissions, setPermissions] = useState([])
    const [rolePermission, setRolePermission] = useState([])
    const [permissionEdit, setPermissionEdit] = useState(false)
    const [allRolePermission, setAllRolePermission] = useState([])
    const [addRolePermission, setAddRolePermission] = useState([])
    const [removeRolePermission, setRemoveRolePermission] = useState([])
    const [permissionModalShow, setPermissionModalShow] = useState(false);
    const [sorting, setSorting] = useState("ID")
    const [searchValue, setSearchValue] = useState("")

    // const checkAddPermission = getPermission("add_permissions")
    // const checkUpdatePermission = getPermission("update_permissions")

    const getPermissionList = (roleObjs = []) => {
        axios.get(`${process.env.REACT_APP_USER_BACKEND_API_URL}/permissions/permissions_table?search_string=${searchValue}&order=${sorting}`, {
            headers: {
                Authorization: "Bearer " + Cookies.get("access_token")
            }
        })
            .then(function (response) {
                if (response.data) {
                    setPermissions(response.data)

                    response?.data?.map(permission => {
                        const obj = roleObjs.map(role => {
                            return {
                                "roles": {
                                    "role": role.RoleName, "value": permission.role.includes(role.RoleName)
                                }
                            }
                        })
                        setRolePermission(obj)
                    })
                }
            })
            .catch(function (error) {
                setIsFetching(false)
                console.log(error);
            })
            .then(() => {
                setIsFetching(false)
            })
    }

    const getRoleList = () => {
        let role_page_url = `${process.env.REACT_APP_USER_BACKEND_API_URL}/roles`
        axios.get(role_page_url, {
            headers: {
                Authorization: "Bearer " + Cookies.get("access_token")
            }
        })
            .then(function (response) {
                if (response.data) {
                    setRoleList(response.data)
                    getPermissionList(response.data);
                }
            })
            .catch(function (error) {
                setIsFetching(false)
                console.log(error);
            })
            .then(() => {
                setIsFetching(false)
            })
    }

    // const onRoleCheck = (e, permission, role) => {
    //     const checked = e.target.checked
    //     if (checked && !permission.role.includes(role.RoleName)){
    //         const addRole = {"permission_id": permission.ID, "role_id": role.ID}
    //         setAddRolePermission(addRolePer => [...addRolePer, addRole])

    //     } else if (!checked && permission.role.includes(role.RoleName)) {
    //         const removeRole = {"permission_id": permission.ID, "role_id": role.ID}
    //         setRemoveRolePermission(removeRolePer => [...removeRolePer, removeRole])
    //     }
    // }

    const onRoleCheck = (e, permission, role) => {
        const checked = e.target.checked
        const objIndex = allRolePermission.findIndex((obj => obj.permission_id == permission.ID && obj.role_id == role.ID));

        if (objIndex > -1){
            allRolePermission[objIndex].addRole = checked
        } else {
            const addRole = {"permission_id": permission.ID, "role_id": role.ID, "addRole": checked}
            setAllRolePermission(addRolePer => [...addRolePer, addRole])
        }
        }


    const onSubmit = () => {
        if (permissionEdit) {
            const data_dict = {
                add_role: allRolePermission.filter(obj => obj.addRole == true),
                remove_role: allRolePermission.filter(obj => obj.addRole == false)
            }
            axios.post(`${process.env.REACT_APP_USER_BACKEND_API_URL}/permissions/bulk_set_role_permission`, data_dict, {
                headers: {
                    Authorization: "Bearer " + Cookies.get("access_token")
                }
            })
                .then(function (response) {
                    if (response.data) {
                        setAllRolePermission([])
                        setPermissionEdit(false);
                        getPermissionList();
                        getUserPermissions();
                        toast.success('Permission was remove and add successfully to the Role', {autoClose:3000})
                    }
                })
                .catch(function (error) {
                    console.log(error);
                })
                .then(() => {
                    console.log();
                })
        }
    }

    const handlePermissionSubmit = (permissionData) => {
        const formdata = new FormData();
        formdata.append("App", permissionData.App)
        formdata.append("AddIn", permissionData.AddIn)
        formdata.append("Component", permissionData.Component)
        formdata.append("PermissionName", permissionData.PermissionName)
        formdata.append("PermissionValue", permissionData.PermissionValue)
        axios.post(`${process.env.REACT_APP_USER_BACKEND_API_URL}/permissions`, formdata, {
            headers: {
                Authorization: "Bearer " + Cookies.get("access_token")
            }
        })
            .then(function (response) {
                if (response.data) {
                    getPermissionList();
                    setPermissionModalShow(false);
                    toast.success('Permission Added Successfuly', {autoClose:3000})

                }
            })
            .catch(function (error) {
                setIsFetching(false)
                console.log(error);
            })
            .then(() => {
                setIsFetching(false)
            }) 
    }

    useEffect(() => {
        getRoleList();
        getUserPermissions();
    }, [searchValue, sorting])

    return (
        <>
            <NavBar />
            <main className="wrapper">
                <div className="page-content">
                    <div className="wallbox-area">
                        <div className="pages-title">
                            <div className="h2">Permissions</div>
                            <span>View all permissions in table</span>
                        </div>
                        <div className="user__content">
                            <div className="user__data">
                                <ul className="panel_section hhh" style={{ padding: "30px 12px" }}>
                                    <li>
                                        <span>Panel Selections</span>
                                    </li>
                                    <li>
                                        <Link to="/rolePermission" className="active"><img alt="" src={ppermiI} /> Permission's Roles</Link>
                                    </li>
                                    <li>
                                        <Link to="/userRole" ><img alt="" src={rolesI} />User's Roles </Link>
                                    </li>
                                    <li>
                                        <Link to="/userlogcontrol"><img alt="" src={rolesI} />User Log Control</Link>
                                    </li>
                                    {/* <li>
                                        <Link to="/permissions" ><img alt="" src={ppermiI} /> Permissions</Link>
                                    </li> */}
                                    <li className="additional_tt">
                                        <span>Additional Functions</span>
                                    </li>
                                    <li>
                                        <button
                                        onClick={() => {
                                            // setChargeTransactionEmail(initState);
                                            setPermissionModalShow(true);
                                        }}>Add Permission</button>
                                    </li>
                                    {/* <li>
                                        <button>Add Role</button>
                                    </li> */}
                                    {/* <li>
                                        <button onClick={() => setModalShow(true)}><AiOutlinePlus />Add new User</button>
                                    </li> */}
                                </ul>
                            </div>
                            <div className="user__data">
                                <div className="search-filter">
                                    <form action="">
                                        <input onChange={e => setSearchValue(e.target.value)} type="search" placeholder="Search Permissions..." />
                                        <button type="submit"><AiOutlineSearch /></button>
                                    </form>
                                    <div className="filter">
                                        Filter <img alt="" src={filterAdd} />
                                    </div>
                                    { permissionEdit ?  <button
                                        className="btn-yellpow"
                                        onClick={() => {
                                            onSubmit();
                                        }}
                                        >Submit
                                    </button> 
                                    : 
                                    <button
                                        className="btn-yellpow"
                                        onClick={() => {
                                            setPermissionEdit(true);
                                        }}
                                        >Update
                                    </button> }
                                </div>
                                <div className="wallbox-tabl">
                                    <div className="table-componet">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="tab__content">
                                                    <table className="table box-table">
                                                        <thead>
                                                            <tr>
                                                                {/* <th scope="col" className="id">
                                                                    <span className="d-installed">ID </span>
                                                                </th> */}
                                                                <th scope="col" className="serialT">Permission Name</th>
                                                                <th scope="col" className="serialT">Application</th>
                                                                <th scope="col" className="serialT">Add In</th>
                                                                <th scope="col" className="serialT">Component</th>
                                                                {
                                                                    roleList.map((role, index) => {
                                                                        return <th key={index} scope="col" className="FirmwareT">{role?.RoleName}</th>
                                                                    })
                                                                }
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {isFetcing ?
                                                            <tr>
                                                                <td>
                                                                    <div className="router-loading-spinner">
                                                                        <TailSpin width={50} height={50} color="#002060" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            :
                                                            permissions.map((permission, index) => (
                                                                <tr key={index}>
                                                                    {/* <td>
                                                                        <label className="form-check-label" htmlFor="check1">
                                                                            {permission.ID}
                                                                        </label>
                                                                    </td> */}
                                                                    <td>
                                                                        <label className="form-check-label" htmlFor="check1">
                                                                            {permission.PermissionName}
                                                                        </label>
                                                                    </td>
                                                                    <td>
                                                                        <label className="form-check-label" htmlFor="check1">
                                                                            {permission.App}
                                                                        </label>
                                                                    </td>
                                                                    <td>
                                                                        <label className="form-check-label" htmlFor="check1">
                                                                            {permission.AddIn}
                                                                        </label>
                                                                    </td>
                                                                    <td>
                                                                        <label className="form-check-label" htmlFor="check1">
                                                                            {permission.Component}
                                                                        </label>
                                                                    </td>

                                                                    {
                                                                        roleList.map((role, index) => {
                                                                            return (
                                                                                <td key={index}>
                                                                                    {
                                                                                        permissionEdit ? <input className="form-check-input" type="checkbox" onClick={(e) => onRoleCheck(e, permission, role)} defaultChecked={permission?.role?.includes(role.RoleName) ? true : false} id="check1"/>
                                                                                        :
                                                                                        <input className="form-check-input" type="checkbox" checked={permission?.role?.includes(role.RoleName) ? true : false} id="check1"/>
                                                                                    }
                                                                                    
                                                                                </td>
                                                                            )
                                                                        })
                                                                    }
                                                                </tr>
                                                                ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pagination-content">
                                            {/* <ul>
                                                <li className="active">1</li>
                                            </ul> */}
                                            {/* <div className="pageRight">
                                                <span>Showing {roles.length} of {roles.length} items</span>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <AddPermissionForm
                    show={permissionModalShow}
                    onHide={() => setPermissionModalShow(false)}
                    // data={chargeTransactionEmail}
                    callBackSubmit={handlePermissionSubmit}
                />
            </main>
        </>
    );
};



export default Permission;
