import NavBar from "../NavBar/NavBar";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Cookies from 'js-cookie'
import React, { useState, useEffect } from "react";
import { TailSpin } from "react-loader-spinner";
import update from "../../images/icons/update.png";
import Delete from "../../images/icons/bin.png";
import { Link } from "react-router-dom";
import { getPermission } from "../../utils";

const Measurement = () => {

    document.title = "Firmware - POWERGrid Cloud";
    const [data, setData] = useState([]);
    const [isFetcing, setIsFetching] = useState(true);

    const checkAddMeasurements = getPermission("add_measurements");
    const checkDownloadMeasurements = getPermission("download_measurements");
    const checkUpdateMeasurements = getPermission("update_measurements");
    const checkDeleteMeasurements = getPermission("delete_measurements");
    const history = useHistory();
    const AddMeasurement = () => {
        history.push("/addmeasurement");
    }

    const getMeasurementData = () => {

        axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/measurements`, {
            headers: {
              Authorization: "Bearer " + Cookies.get("access_token"),
            },
          })
          .then(function (response) {
            if (response?.data?.success) {
              setIsFetching(true);
              setData([...response.data.data]);
              setIsFetching(false);
    
            } else {
              setData([]);
              setIsFetching(false);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    };

    const deleteMeasurement = (deleteEntry) => {
        const askToConfirm = window.confirm(
          `Are you sure want to Delete this Entry`
        );
        if (askToConfirm) {
          axios
            .delete(
              `${process.env.REACT_APP_BACKEND_API_URL}/measurements/${deleteEntry}`,
              {
                headers: {
                  Authorization: "Bearer " + Cookies.get("access_token"),
                },
              }
            )
            .then(function (response) {
              window.alert(response?.data.message);
              getMeasurementData();
            })
            .catch(function (error) {
              console.log(error);
            });
        }
    };

    const downloadCSV = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/download_measurements`, {
            headers: {
              Authorization: "Bearer " + Cookies.get("access_token"),
            },
          })
          .then(function (response) {
            const data = response?.data
            if (data?.success) {
                var link = document.createElement("a");
                link.setAttribute('download', 'measurements.csv');
                link.href = data?.data?.link;
                document.body.appendChild(link);
                link.click();
                link.remove();
            } else {
              setData([]);
              setIsFetching(false);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    } 

    const getMeasurementDataById = (id) => {
        history.push("/addmeasurement", {update: true, measurementId: id});
    }

    useEffect(() => {
        getMeasurementData();
      }, []);

    return(
        <>
        <NavBar />
        <main className="wrapper">
            <div className="page-content">
                <div className="wallbox-area">
                    <div className="pages-title">
                        <div className="h2">Measurement</div>
                        <span>View all Measurements</span>
                    </div>
                    <div className="search-filter">
                        <div style={{ width: "160px" }} scope="col" className="serialT">
                            {checkAddMeasurements == true ?
                            <button
                            className="btn-yellpow"
                            onClick={AddMeasurement}
                            >
                                Add Measurement
                            </button>
                            :
                            null
                            }
                        </div>
                        <div style={{ width: "140px" }} scope="col" className="serialT">
                            {checkDownloadMeasurements == true ?
                            <button
                            className="btn-yellpow"
                            onClick={downloadCSV}
                            >
                                Export as csv
                            </button>
                            :
                            null
                            }
                        </div>            
                    </div>
                    <div className="table-componet" style={{ display: "contents" }}>
                        <div className="col-md-12">
                            <div className="col-md-12">
                                <div className="tab__content mt-2">
                                    <table className="table box-table">
                                        <thead>
                                            <tr>
                                                <th
                                                scope="col"
                                                className="serialT"
                                                >
                                                    <h6>
                                                        <b> ID</b>
                                                    </h6>
                                                </th>
                                                <th scope="col" className="serialT">
                                                    <h6>
                                                    <b> Serial Number</b>
                                                    </h6>
                                                </th>
                                                {/* <th scope="col" className="serialT">
                                                    <h6>
                                                    <b> Channel</b>
                                                    </h6>
                                                </th>
                                                <th scope="col" className="serialT">
                                                    <h6>
                                                    <b> Parameter</b>
                                                    </h6>
                                                </th> */}
                                                <th scope="col" className="serialT">
                                                    <h6>
                                                    <b> Value</b>
                                                    </h6>
                                                </th>
                                                <th scope="col" className="serialT">
                                                    <h6>
                                                    <b> Created At</b>
                                                    </h6>
                                                </th>
                                                {checkUpdateMeasurements || checkDeleteMeasurements == true ?
                                                <th scope="col" className="actionsT">
                                                    <h6>
                                                    <b> Actions</b>
                                                    </h6>
                                                </th> 
                                                :
                                                null
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {data.length > 0 ?
                                            (
                                            data?.map((item, i) => {
                                                return (
                                                <tr key={i} style={{ fontSize: "13px" }}>
                                                    <td>{item?.id}</td>
                                                    <td className="wallboxSerial" style={{fontWeight: 'bold'}}><Link to={`/wallbox/${item.serial_number}`}>{item?.serial_number}</Link></td>
                                                    {/* <td>{item?.channel}</td>
                                                    <td>{item?.parameter}</td> */}
                                                    <td>{item?.value}</td>
                                                    <td>{item?.created_at}</td>
                                                    <td className="Actions">
                                                        {checkUpdateMeasurements == true ?
                                                        <button
                                                            onClick={() => getMeasurementDataById(item?.id)}
                                                        >
                                                            <img alt="" src={update}  /> Update
                                                        </button>
                                                        :
                                                        null
                                                        }
                                                        
                                                        {checkDeleteMeasurements == true ?
                                                        <button
                                                            style={{ margin: "3px" }}
                                                            onClick={() => deleteMeasurement(item?.id)}
                                                        >
                                                            <img alt="" src={Delete} /> Delete
                                                        </button>
                                                        :
                                                        null
                                                        }
                                                        <button></button>
                                                    </td>
                                                </tr>
                                                );
                                            })
                                            )
                                            :
                                            isFetcing == true ?
                                            (
                                            <tr>
                                                <td>
                                                    <div className="router-loading-spinner">
                                                        <TailSpin width={50} height={50} color="#002060" />
                                                    </div>
                                                </td>
                                            </tr>
                                            )
                                            :
                                            <h5 className="text-center mt-5">Result Not found...</h5>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        </>
    );
};

export default Measurement;