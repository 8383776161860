import React, { useState, useEffect } from "react";
import { TailSpin } from "react-loader-spinner";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { Link } from "react-router-dom";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const EventsDashboardRows = ({ events, pageCount, currentPage, setCurrentPage, isFetching, totalDataCount, displaySize, allEventsTotalData, allAndroidTotalData, allIOSTotalData, setEventType, getFilterEvents, eventType }) => {
    const [List, setList] = useState(events)
    const [MasterChecked, setMasterChecked] = useState(false)
    const [SelectedList, setSelectedList] = useState([])
    const [androidPerc, setAndroidPerc] = useState()
    const [IOSPerc, setIOSPerc] = useState()

    function onMasterCheck(e) {
        let tempList = List;
        tempList.map((user) => (user.selected = e.target.checked));

        setMasterChecked(e.target.checked)
        setList(tempList)
        setSelectedList(List.filter((e) => e.selected))
    }

    function onItemCheck(e, item) {
        let tempList = List;
        tempList.map((user) => {
            if (user.id === item.id) {
                user.selected = e.target.checked;
            }
            return user;
        });

        const totalItems = List.length;
        const totalCheckedItems = tempList.filter((e) => e.selected).length;

        setMasterChecked(totalItems === totalCheckedItems)
        setList(tempList)
        setSelectedList(List.filter((e) => e.selected))
    }

    const percentageData = () => {
    const totalAndroidPercentage = (allAndroidTotalData) / (allEventsTotalData) * 100
    const totalIOSPercentage = (allIOSTotalData) / (allEventsTotalData) * 100
    setAndroidPerc(Math.round(totalAndroidPercentage))
    setIOSPerc(Math.round(totalIOSPercentage))
    }

    useEffect(()=>{
        percentageData();
    })
    
    return (
        <>
        <div>
            <div className="row">
                <div className="col-md-4 col-xl-3">
                    <Link to="#"
                    onClick={(e) => {
                        setEventType(1);
                        getFilterEvents(1)
                    }}
                    >
                        <div className={eventType===1?"card bg-c-yellow order-card dashboardactive":"card bg-c-yellow order-card"}>
                        <div className="card-block d-flex justify-content-between h-auto">
                        <div className="card-left">
                                <h5 className="mb-3">All Events</h5>
                                <h6>({allEventsTotalData})</h6>
                            </div>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-md-4 col-xl-3">
                    <Link to="#"
                    onClick={(e) => { 
                        setEventType(2);
                        getFilterEvents(2)
                    }}
                    >
                        <div className={eventType===2?"card bg-c-yellow order-card dashboardactive":"card bg-c-yellow order-card"}>
                        <div className="card-block d-flex justify-content-between h-auto">
                            <div className="card-left w-75">
                            <h5 className="mb-3">Android</h5>
                            <h6>({allAndroidTotalData})</h6>
                            </div>
                            <div className="card-right">
                                <div className="w-60 h-25 float-end">
                                    <CircularProgressbar
                                    value={androidPerc}
                                    text={`${androidPerc}%`}
                                    styles={buildStyles({
                                        rotation: 0.25,
                                        strokeLinecap: 'butt',
                                        textSize: '25px',
                                        pathTransitionDuration: 0.5,
                                        pathColor: `rgba(62, 152, 199, ${androidPerc / 100})`,
                                        textColor: 'white',
                                        trailColor: 'white',
                                        backgroundColor: '#3e98c7',
                                    })}
                                    />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-md-4 col-xl-3">
                    <Link to="#"
                    onClick={(e) => { 
                        setEventType(3);
                        getFilterEvents(3)
                    }}
                    >
                        <div className={eventType===3?"card bg-c-yellow order-card dashboardactive":"card bg-c-yellow order-card"}>
                            <div className="card-block d-flex justify-content-between h-auto">
                                <div className="card-left w-60">
                                <h5 className="mb-3">IOS</h5>
                                <h6>({allIOSTotalData})</h6>
                                </div>
                                <div className="card-right">
                                <div className="w-60 h-25 float-end">
                                <CircularProgressbar
                                value={IOSPerc}
                                text={`${IOSPerc}%`}
                                styles={buildStyles({
                                    rotation: 0.25,
                                    strokeLinecap: 'butt',
                                    textSize: '25px',
                                    pathTransitionDuration: 0.5,
                                    pathColor: `red, ${IOSPerc / 100})`,
                                    textColor: 'white',
                                    trailColor: 'white',
                                    backgroundColor: '#3e98c7',
                                })}
                                />
                                </div>
                                </div>
                                
                                
                                
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
        <div className="table-componet">
            <div className="row">
                <div className="col-md-12">
                    <div className="tab__content">
                        <table className="table box-table user_table">
                            <thead>
                                <tr>
                                    <th scope="col">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={MasterChecked}
                                            id="mastercheck"
                                            onChange={(e) => onMasterCheck(e)}
                                        />
                                    </th>
                                    <th scope="col" className="ownerT">Timestamp</th>
                                    <th scope="col" className="serialT">Component Name</th>
                                    <th scope="col" className="FirmwareT">User</th>
                                    <th scope="col" className="statusT">Event Category</th>
                                    <th scope="col" className="Energy_T">Event Text</th>
                                    <th scope="col" className="ownerT">Event Level</th>
                                    <th scope="col" className="ownerT">Event Origin Path</th>
                                    <th scope="col" className="ownerT">Event Origin Line</th>
                                    <th scope="col" className="ownerT">Event Metadata</th>
                                    <th scope="col" className="ownerT">Component ID</th>
                                    <th scope="col" className="ownerT">Environment ID</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    events.length && !isFetching ?
                                    events.map((event, index) => <SingleRow key={index} onItemCheck={onItemCheck} event={event} />) :
                                    <div className="router-loading-spinner">
                                        <TailSpin width={50} height={50} color="#002060" />
                                    </div>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="pagination-content">
                <ul>
                    <Stack spacing={2}>
                        <Pagination onChange={(e, pn) => setCurrentPage(pn - 1)} count={pageCount} />
                    </Stack>
                </ul>
                <div className="pageRight">
                    <span>Showing {events?.length} of {totalDataCount} items</span>
                </div>
            </div>
        </div>

        </>
    )
}

const SingleRow = ({ event, onItemCheck }) => {
    return (
        <tr className={event.selected ? "selected" : ""}>
            <th scope="row">
                <input
                    type="checkbox"
                    checked={event.selected}
                    className="form-check-input"
                    id="rowcheck{user.id}"
                    onChange={(e) => onItemCheck(e, event)}
                />
            </th>
            <td className="td_event-timestamp"><span>{event.timestamp}</span></td>
            <td className="wallboxSerial"><span>{event.Component_Name}</span></td>
            <td className="reset_pass td_event-id-user"><span>{event.User || "unknown"}</span></td>
            <td className="td_event-category"><span>{event.Event_Category || "Not Found"}</span></td>
            <td className="td_event-text"><span>{event.Event_Text || "Not Found"}</span></td>
            <td className="FirmwareVersion"><span>{event.Event_Level || "Not Found"}</span></td>
            <td className="FirmwareVersion"><span>{event.Event_Origin_Path || "Not Found"}</span></td>
            <td className="FirmwareVersion"><span>{event.Event_Origin_Line || "Not Found"}</span></td>
            <td className="FirmwareVersion"><span>{event.Event_Metadata}</span></td>
            <td className="FirmwareVersion"><span>{event.Environment_ID}</span></td>
            <td className="FirmwareVersion"><span>{event.Component_ID || "Not Found"}</span></td>
        </tr>
    );
};

export default EventsDashboardRows;