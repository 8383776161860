import React from "react";

import { Tab, Nav, } from "react-bootstrap";
import Activities from "./Activities";
import Functions from "./Functions";
import Progress from "./Progress";

const AllActivities = () => {
  return (
    <>
      <Tab.Container defaultActiveKey="first">
        <Nav variant="pills" className="actibety_btn">
          <Nav.Item>
            <Nav.Link eventKey="first">Activities</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="second">Functions</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="third">Progress</Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="first">
            {/* <Activities /> */}
          </Tab.Pane>
          <Tab.Pane eventKey="second">
            {/*     <Functions /> */}
          </Tab.Pane>
          <Tab.Pane eventKey="third">
            {/* <Progress /> */}
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
};

export default AllActivities;
