import React, { useState, useEffect } from "react";
import NavBar from "../../NavBar/NavBar";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

const AddCarProfile = () => {

    const [isProcess, setIsProcess] = useState(false);
    const [carProfileData, setCarProfileData] = useState({});
    const [isUpdate, setIsUpdate] = useState(false);
    const [message, setMessage] = useState("");
    const [data, setData] = useState();
    const [owner, setOwner] = useState();
    const [assignedTo, setAssignedTo] = useState();

    const history = useHistory();

    const location = useLocation();
    const carProfileListState = location.state;

    const getCarProfileDataById = (id) => {
        axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/car/${id}`, {
            headers: {
                Authorization: "Bearer " + Cookies.get("access_token"),
        },
        })
        .then(function (response) {
            if (response?.data) {
                setCarProfileData(response?.data);
                setOwner(response?.data?.created_by)
                setAssignedTo(response?.data?.assigned_to)
                // setSerialNumber(response?.data?.box_serial_number)
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    };

    const handleSubmit = (e) => {
        setIsProcess(true);
        e.preventDefault();
    
        let dataInfo = {
            // identity: carProfileData.identity,
            // domain: carProfileData.domain,
            // box_serial_number: serialNumber,
            company_car: carProfileData.company_car || false,
            license_plate: carProfileData.license_plate,
            // certificate: carProfileData.certificate,
            created_by: owner,
            assigned_to: assignedTo,
            make: carProfileData.make,
            model: carProfileData.model
        }
        if (isUpdate) {
            axios.put(`${process.env.REACT_APP_BACKEND_API_URL}/car/${carProfileListState.carProfileId}`, dataInfo, {
                headers: {
                    Authorization: "Bearer " + Cookies.get("access_token"),
                    "Content-Type": "multipart/form-data",
                },
            })
            .then(function (response) {
                if (response.data.success) {
                    history.push("/listcarprofile")
                    toast.success('CarProfile Updated Successfuly', {autoClose:3000})
                }else{
                    setMessage(response.data.message)
                    toast.error(message, {autoClose:3000})
                }
            })
            .catch(function (error) {
                console.log(error);
            })
            .then(function () {
                setIsProcess(false);
            });
        } else {
          axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/car`, dataInfo, {
                headers: {
                    Authorization: "Bearer " + Cookies.get("access_token"),
                    "Content-Type": "multipart/form-data",
                },
            })
            .then(function (response) {
                if (response.data.success) {
                    history.push("/listcarprofile")
                    toast.success('CarProfile Added Successfuly', {autoClose:3000})
                }else{
                    setMessage(response.data.message)
                    toast.error(message, {autoClose:3000})
                }
            })
            .catch(function (error) {
                console.log(error);
            })
            .then(function () {
                setIsProcess(false);
            });
        }
    };

    const getData = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/dropdown_data`, {
            headers: {
                Authorization: "Bearer " + Cookies.get("access_token"),
            },
        })
        .then(function (response) {
            if (response?.data?.success) {
                setData(response?.data?.data);
            } else {
                setData();
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    };

    useEffect(() => {
        setIsUpdate(carProfileListState?.update ? true : false);
        if (carProfileListState?.update) {
            getCarProfileDataById(carProfileListState.carProfileId);
        }
        getData();
    }, []);

    return(
        <>
        <NavBar />
        <main className="wrapper">
            <div className="page-content">
                <div className="wallbox-area">
                    <div className="pages-title-list">
                        {isUpdate ? (
                            <ul>
                            <li>
                                <span>
                                    <Link to="/listcarprofile">
                                    CarProfile
                                    </Link>
                                </span>
                            </li>
                            <li>
                                <span>Update Carprofile</span>
                            </li>
                          </ul>
                        ) : (
                            <ul>
                            <li>
                                <span>
                                    <Link to="/listcarprofile">
                                    Carprofile
                                    </Link>
                                </span>
                            </li>
                            <li>
                                <span>Add Carprofile</span>
                            </li>
                          </ul>
                        )}
                    </div>
                    <div className="user__content">
                        <div className="user__data">
                            <div className="wallbox-tabl">
                            {
                                <div className="table-componet">
                                    <div className="details_cont">
                                        <div className="wallbox_details" style={{ position: "relative" }}>
                                            <div className="wallbox_details_content1">
                                                <ValidatorForm onSubmit={handleSubmit}>
                                                    {/* <div className="box_group">
                                                        <label>Identity</label>
                                                        <TextValidator
                                                        type="text"
                                                        value={carProfileData?.identity}
                                                        validators={['required']}
                                                        errorMessages={['Identity is required']}
                                                        onChange={(e) => {
                                                            carProfileData.identity = e.target.value;
                                                            setCarProfileData({...carProfileData})
                                                        }}
                                                        />
                                                    </div> */}
                                                    {/* <div className="box_group">
                                                        <label>Domain</label>
                                                        <TextValidator
                                                        type="number"
                                                        value={carProfileData?.domain}
                                                        validators={['required']}
                                                        errorMessages={['Domain is required']}
                                                        onChange={(e) => {
                                                            carProfileData.domain = e.target.value;
                                                            setCarProfileData({...carProfileData})
                                                        }}
                                                        />
                                                    </div> */}
                                                    {/* <div className="box_group">
                                                        <label>Box Serial Number</label>
                                                        <Form.Select
                                                            aria-label="Default select example"
                                                            onChange={(e) => {
                                                                setSerialNumber(e.target.value)
                                                            }}
                                                            value={serialNumber}
                                                            required
                                                        >
                                                            {isUpdate ?
                                                            (
                                                            data?.wallboxes.map((element, index) => (
                                                                <option value={element?.id} key={index}>
                                                                    {element?.serial_number}
                                                                </option>
                                                            ))
                                                            )
                                                            :
                                                            (
                                                                <>
                                                                <option></option>   
                                                                {data?.wallboxes.map((element, index) => (
                                                                
                                                                <option value={element?.id} key={index}>
                                                                    {element?.serial_number}
                                                                </option>
                                                                
                                                                ))}
                                                                </>
                                                            )}
                                                        </Form.Select>
                                                    </div> */}
                                                    <div className="box_group d-flex align-items-center">
                                                        <label>Company Car</label>
                                                        <input 
                                                        className="w-auto ms-2"
                                                        type="checkbox" 
                                                        onChange={(e) => {
                                                            carProfileData.company_car = e.target.checked;
                                                            setCarProfileData({...carProfileData})
                                                        }}
                                                        checked={carProfileData?.company_car} id="check1"
                                                        />
                                                    </div>
                                                    <div className="box_group">
                                                        <label>Licence Plate</label>
                                                        <TextValidator
                                                        type="text"
                                                        value={carProfileData?.license_plate}
                                                        validators={['required']}
                                                        errorMessages={['License plate is required']}
                                                        onChange={(e) => {
                                                            carProfileData.license_plate = e.target.value;
                                                            setCarProfileData({...carProfileData})
                                                        }}
                                                        />
                                                    </div>
                                                    {/* <div className="box_group">
                                                        <label>Certificate</label>
                                                        <TextValidator
                                                        type="number"
                                                        value={carProfileData?.certificate}
                                                        validators={['required']}
                                                        errorMessages={['Certificate is required']}
                                                        onChange={(e) => {
                                                            carProfileData.certificate = e.target.value;
                                                            setCarProfileData({...carProfileData})
                                                        }}
                                                        />
                                                    </div> */}
                                                    <div className="box_group">
                                                        <label>Created By</label>
                                                        <Form.Select
                                                            aria-label="Default select example"
                                                            onChange={(e) => {
                                                                setOwner(e.target.value)
                                                            }}
                                                            value={owner}
                                                            required
                                                        >
                                                            {isUpdate ?
                                                            (
                                                                data?.users.map((element, index) => (
                                                                <option value={element?.user_id} key={index}>
                                                                    {element?.name}
                                                                </option>
                                                                ))
                                                            )
                                                            :
                                                            (
                                                                <>
                                                                <option></option>
                                                                {data?.users.map((element, index) => (
                                                                    
                                                                    <option value={element?.user_id} key={index}>
                                                                        {element?.name}
                                                                    </option>
                                                                    
                                                                ))}
                                                                </>
                                                            )
                                                            }
                                                        </Form.Select>
                                                    </div>
                                                    <div className="box_group">
                                                        <label>Assigned To</label>
                                                        <Form.Select
                                                            aria-label="Default select example"
                                                            onChange={(e) => {
                                                                setAssignedTo(e.target.value)
                                                            }}
                                                            value={assignedTo}
                                                            required
                                                        >
                                                            {isUpdate ?
                                                            (
                                                                data?.users.map((element, index) => (
                                                                <option value={element?.user_id} key={index}>
                                                                    {element?.name}
                                                                </option>
                                                                ))
                                                            )
                                                            :
                                                            (
                                                                <>
                                                                <option></option>
                                                                {data?.users.map((element, index) => (
                                                                    
                                                                    <option value={element?.user_id} key={index}>
                                                                        {element?.name}
                                                                    </option>
                                                                    
                                                                ))}
                                                                </>
                                                            )
                                                            }
                                                        </Form.Select>
                                                    </div>
                                                    <div className="box_group">
                                                        <label>Make</label>
                                                        <TextValidator
                                                        type="text"
                                                        value={carProfileData?.make}
                                                        validators={['required']}
                                                        errorMessages={['Make is required']}
                                                        onChange={(e) => {
                                                            carProfileData.make = e.target.value;
                                                            setCarProfileData({...carProfileData})
                                                        }}
                                                        />
                                                    </div>
                                                    <div className="box_group">
                                                        <label>Model</label>
                                                        <TextValidator
                                                        type="text"
                                                        value={carProfileData?.model}
                                                        validators={['required']}
                                                        errorMessages={['Model is required']}
                                                        onChange={(e) => {
                                                            carProfileData.model = e.target.value;
                                                            setCarProfileData({...carProfileData})
                                                        }}
                                                        />
                                                    </div>
                                                    <button
                                                        className="btn-yellpow"
                                                        {...(isProcess ? "Loading..." : "Data Added..")}
                                                    >
                                                        Submit
                                                    </button>
                                                </ValidatorForm>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        </>
    );
};

export default AddCarProfile;