import { useState } from "react";
import { Modal } from "react-bootstrap";
import Cookies from 'js-cookie'

import logoCloud from '../../../images/logo/logoCloud.png';
import logo2 from '../../../images/logo/logo2.png';
import axios from "axios";

function MyAddRoleModal(props) {

    const [isPorccess, setIsProccess] = useState(false)
    const [message, setMessage] = useState("")
    const [role, setRole] = useState("Select One")


    const handleSubmit = (e) => {
        e.preventDefault()

        const signUpInfo = new URLSearchParams(Object.entries({
            UserEmail: props?.Email,
            Roles: role,
            Environments: "PJ ENV",
        })).toString();
        axios.post(`${process.env.REACT_APP_USER_BACKEND_API_URL}/users/addRole`, signUpInfo, {
            headers: {
                Authorization: "Bearer " + Cookies.get("access_token")
            }
        })
            .then(function (response) {
                if (response.data) {
                    setMessage(response.data)
                }
            })
            .catch(function (error) {
                setIsProccess(false)
                console.error(error);
            })
            .then(() => {
                setIsProccess(false)
            })
    }

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className="login-form" style={{ height: "auto", padding: "0 20px" }}>
                    <div className="logo-head">
                        <div className="logo-2">
                            <img src={logoCloud} alt="" />
                        </div>
                        <div className="h4">Add role to a user</div>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label className="role"><span>*</span> Select Role</label>
                                <select onChange={(e) => setRole(e.target.value)} className="form-control text-center shadow-none">
                                    <option selected className="d-none">Select One</option>
                                    <option className="from-control">Viewer</option>
                                    <option className="from-control">Admin</option>
                                    <option className="from-control">SuperAdmin</option>
                                    <option className="from-control">PenTester</option>
                                    <option className="from-control">Debugger</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <button onClick={() => setIsProccess(true)} type="submit" className="subBtn"><i className="fa-solid fa-unlock"></i> {isPorccess ? "Loading..." : "Add Role"}</button>
                            </div>
                        </form>
                        <div className="message">
                            <p className="mt-2" style={{ color: "gray", fontWeight: "bold" }}>{message}</p>
                        </div>
                    </div>
                    <div className="logo-footer mt-5">
                        <img src={logo2} alt="" />
                    </div>
                </div>
            </Modal.Body>
            {/* <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer> */}
        </Modal>
    );
}

export default MyAddRoleModal;