import { useState } from "react";
import { Modal } from "react-bootstrap";
import useAuth from "../../Hooks/useAuth";
import logoCloud from '../../images/logo/logoCloud.png';
import logo2 from '../../images/logo/logo2.png';

function MyVerticallyCenteredModal(props) {
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [isPorccess, setIsProccess] = useState(false)
    const [message, setMessage] = useState("")

    const { SignUpViaEmail } = useAuth()

    const handleRegister = (e) => {
        e.preventDefault()
        if (password == confirmPassword){
            SignUpViaEmail(firstName, lastName, email, phoneNumber, password, setIsProccess, setMessage)
        } else {
            setIsProccess(false)
            setMessage("Password Not matched")
        }
    }
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className="login-form" style={{ height: "auto", padding: "0 20px" }}>
                    <div className="logo-head">
                        <div className="logo-2">
                            <img src={logoCloud} alt="" />
                        </div>
                        <div className="h4">Register a new user</div>
                        <form onSubmit={handleRegister}>
                            <div className="form-group">
                                <label className="FirstName"><span>*</span> First Name</label>
                                <input required onChange={(e) => setFirstName(e.target.value)} type="text" />
                            </div>
                            <div className="form-group">
                                <label className="LastName"><span>*</span> Last Name</label>
                                <input required onChange={(e) => setLastName(e.target.value)} type="text" />
                            </div>
                            <div className="form-group">
                                <label className="emails"><span>*</span> Your Email</label>
                                <input required onChange={(e) => setEmail(e.target.value)} type="email" />
                            </div>
                            <div className="form-group">
                                <label className="phoneNumber"><span>*</span> Phone Number</label>
                                <input required onChange={(e) => setPhoneNumber(e.target.value)} type="text" />
                            </div>
                            <div className="form-group">
                                <label className="pass"><span>*</span> Password</label>
                                <input required type="password" onChange={(e) => setPassword(e.target.value)}/>
                            </div>
                            <div className="form-group">
                                <label className="pass"><span>*</span> Confirm password</label>
                                <input required type="password" onChange={(e) => setConfirmPassword(e.target.value)}/>
                            </div>
                            <div className="form-group">
                                <button type="submit" className="subBtn"><i className="fa-solid fa-unlock"></i> {isPorccess ? "Loading..." : "Registrieren"}</button>
                            </div>
                        </form>
                        <div className="message">
                            <p className="mt-2" style={{ color: "gray", fontWeight: "bold" }}>{message}</p>
                        </div>
                    </div>
                    <div className="logo-footer mt-5">
                        <img src={logo2} alt="" />
                    </div>
                </div>
            </Modal.Body>
            {/* <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer> */}
        </Modal>
    );
}

export default MyVerticallyCenteredModal;