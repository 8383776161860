import React, { useState } from "react";
import { TailSpin } from "react-loader-spinner";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { Link } from "react-router-dom";




// Sorting function


const PowerBrainRows = ({ events, pageCount, currentPage, setCurrentPage, isFetching, totalDataCount, displaySize }) => {
    const [List, setList] = useState(events)
    const [MasterChecked, setMasterChecked] = useState(false)
    const [SelectedList, setSelectedList] = useState([])



    function onMasterCheck(e) {
        let tempList = List;
        // Check/ UnCheck All Items
        tempList.map((user) => (user.selected = e.target.checked));

        //Update State
        setMasterChecked(e.target.checked)
        setList(tempList)
        setSelectedList(List.filter((e) => e.selected))
    }

    function onItemCheck(e, item) {
        let tempList = List;
        tempList.map((user) => {
            if (user.id === item.id) {
                user.selected = e.target.checked;
            }
            return user;
        });

        //To Control Master Checkbox State
        const totalItems = List.length;
        const totalCheckedItems = tempList.filter((e) => e.selected).length;

        // Update State

        setMasterChecked(totalItems === totalCheckedItems)
        setList(tempList)
        setSelectedList(List.filter((e) => e.selected))
    }

    /* function getSelectedRows() {
        setSelectedList(List.filter((e) => e.selected))
    } */



    return (
        <>
            <div className="table-componet">
                <div className="row">
                    <div className="col-md-12">
                        <div className="tab__content">
                            <table className="table box-table user_table">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={MasterChecked}
                                                id="mastercheck"
                                                onChange={(e) => onMasterCheck(e)}
                                            />
                                        </th>
                                        <th scope="col" className="serialT">ID</th>
                                        <th scope="col" className="serialT">timestamp</th>
                                        <th scope="col" className="FirmwareT">command</th>
                                        <th scope="col" className="Energy_T">info</th>
                                        <th scope="col" className="statusT">created_at</th>
                                        <th scope="col" className="ownerT">mac_address</th>
                                        <th scope="col" className="Energy_T">raw_message_content</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        events.length > 0?
                                        events.map((event, index) => <SingleRow key={index} onItemCheck={onItemCheck} event={event} />) 
                                        :
                                        isFetching == true ?
                                        <tr className="router-loading-spinner">
                                            <td>
                                            <div className="router-loading-spinner">
                                                <TailSpin width={50} height={50} color="#002060" />
                                            </div>
                                            </td>
                                        </tr>
                                        :
                                        <tr>
                                            <td>
                                                <h5 className="text-center mt-5">Result Not found...</h5>
                                            </td>
                                        </tr> 
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="pagination-content">
                    <ul>

                        {/* {
                            [...Array(pageCount).keys()].map((avc, index) => <li key={index} className={currentPage === avc + 1 && "active"} onClick={() => setCurrentPage(avc + 1)}>{avc + 1}</li>)
                        }
                        {
                            currentPage !== pageCount && <li onClick={() => setCurrentPage(currentPage < pageCount ? currentPage + 1 : currentPage)}><BsChevronDoubleRight /></li>
                        } */}
                        <Stack spacing={2}>
                            <Pagination onChange={(e, pn) => setCurrentPage(pn)} count={pageCount} />
                        </Stack>
                    </ul>
                    <div className="pageRight">
                        <span>Showing {events?.length} of {totalDataCount} items</span>
                    </div>
                </div>
            </div>

        </>
    )
}


const SingleRow = ({ event, onItemCheck }) => {

    /* function isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    let infoString = ""
    if (isJsonString(event?.info)) {
        const eventInfo = JSON.parse(event?.info)
       //
        infoString = `OTA_WEB : ${eventInfo?.ota_webclient?.OTA_WEB}`

        //
         infoString = `commandUid : ${eventInfo?.commandUid} , deviceMacAdress: ${eventInfo?.firmwareUpdateInfo?.deviceMacAdress} , fwVersionDeviceCurrent: ${eventInfo?.firmwareUpdateInfo?.fwVersionDeviceCurrent} , protocolVersion: ${eventInfo?.protocolVersion}`
    } */

    return (
        <tr className={event.selected ? "selected" : ""}>
            <th scope="row">
                <input
                    type="checkbox"
                    checked={event.selected}
                    className="form-check-input"
                    id="rowcheck{user.id}"
                    onChange={(e) => onItemCheck(e, event)}
                />
            </th>
            <td className="td_event-id"><span>{event.id}</span></td>
            <td className="wallboxSerial"><span>{event.timestamp ? event.timestamp : "null"}</span></td>
            <td className="reset_pass td_event-id-user"><span>{event.command || "unknown"}</span></td>
            <td className="FirmwareVersion"><span>{event?.info || "Not Found"}</span></td>
            {/* <td className="FirmwareVersion"><span>{infoString || "Not Found"}</span></td> */}
            <td className="td_event-timestamp"><span>{event.created_at || "Not Found"}</span></td>
            <td className="wallboxSerial"><span><Link to={`/wallbox/${event.mac_address}`}>{event.mac_address || "Not Found"}</Link></span></td>
            <td><span>{event.raw_message_content || "Not Found"}</span></td>
        </tr>
    );
};



export default PowerBrainRows