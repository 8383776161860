import React from "react";

import { Tab, Nav } from "react-bootstrap";
import Activities from "./Activities";
// import Functions from "./Functions";
// import Progress from "./Progress";
import ChartComponent from "./Chart";
import { useParams } from "react-router-dom";

const AllActivities = () => {
  const { wallboxId } = useParams();
  return (
    <>
      <Tab.Container defaultActiveKey="first">
        <Nav variant="pills" className="actibety_btn">
          <Nav.Item>
            <Nav.Link eventKey="first">Charging</Nav.Link>
          </Nav.Item>
          {/* <Nav.Item>
            <Nav.Link eventKey="second">Functions</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="third">Progress</Nav.Link>
          </Nav.Item> */}
          <Nav.Item>
            <Nav.Link eventKey="fourth">Activities</Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="first">
            <ChartComponent id={wallboxId} />
          </Tab.Pane>
          {/* <Tab.Pane eventKey="second">
            <Functions />
          </Tab.Pane>
          <Tab.Pane eventKey="third">
            <Progress />
          </Tab.Pane> */}
          <Tab.Pane eventKey="fourth">
            <Activities id={wallboxId} />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
};

export default AllActivities;
