import { useEffect, useState } from "react"
import axios from "axios"
import Cookies from 'js-cookie'
import { getUserInfo } from './../utils';
import getUserPermissions from "./../utils";


const AuthSystem = () => {
    const [user, setUser] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    const LoginaViaPassword = (inputEmail, inputPassword, history, setIsProcess, setMessage) => {
        setIsLoading(true)
        const loginInfo = new URLSearchParams(Object.entries({
            username: inputEmail,
            password: inputPassword
        })).toString();

        /* http://116.203.191.150:8004 */
        axios.post(`${process.env.REACT_APP_USER_BACKEND_API_URL}/users/login`, loginInfo, {
            headers: {
                is_app: false
            }
        })
            .then(function (response) {
                if (response?.data) {
                    setUser(response.data)
                    Cookies.set('access_token', response.data.access_token) 
                    getUserInfo();
                    getUserPermissions();
                    history.push('/')
                } else {
                    setIsLoading(false)
                }
            })
            .catch(function (error) {
                if (error?.response?.status == 401){
                    setMessage(error?.response?.data?.detail)
                }
                setUser(null)
                console.log(error);
            })
            .then((res) => {
                setIsLoading(false)
                setIsProcess(false)
            })
    }

    const SignUpViaEmail = (firstName, lastName, email, phone, password, setIsProccess, setMessage) => {
        setIsProccess(true)
        const signUpInfo = new URLSearchParams(Object.entries({
            FirstName: firstName,
            LastName: lastName,
            Email: email,
            Phone: phone,
            Password: password
        })).toString();
        axios.post(`${process.env.REACT_APP_USER_BACKEND_API_URL}/users/register`, signUpInfo, {
            headers: {
                Authorization: "Bearer " + Cookies.get("access_token")
            }
        })
            .then(function (response) {
                setMessage(response.data)
            })
            .catch(function (error) {
                if (error === "Error: Request failed with status code 422") {
                    console.log(true)
                }
            })
            .then(() => {
                setIsProccess(false)
            })
    }


    const handleSignOut = (history) => {
        Cookies.remove('access_token')
        Cookies.remove('user_permissions')
        setUser(null)
        history.push('/')
    }


    useEffect(() => {
        setIsLoading(true)
        const access_token = Cookies.get("access_token")
        if (access_token) {
            /* http://116.203.191.150:8004 */
            axios.get(`${process.env.REACT_APP_USER_BACKEND_API_URL}/users/current_user/${access_token}`)
                .then(function (response) {
                    if (response.data) {
                        setUser(response.data)
                    } else {
                        setUser(null)
                    }
                })
                .catch(function (error) {
                    setUser(null)
                    console.log(error);
                })
                .then(function () {
                    setIsLoading(false)
                });
        } else {
            setIsLoading(false)
        }
    }, [])

    return {
        user,
        LoginaViaPassword,
        isLoading,
        handleSignOut,
        SignUpViaEmail
    }
}

export default AuthSystem;