import React, { useState, useEffect } from "react";
import NavBar from "../NavBar/NavBar";
import axios from "axios";
import update from "../../images/icons/update.png";
import Delete from "../../images/icons/bin.png";
import MessageForm from "./MessageForm/AddMessageForm";
import Cookies from 'js-cookie'
import "./CustomersMessages.scss";
import { TailSpin } from "react-loader-spinner";
import {getPermission} from '../../utils'
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

const initState = { id: 0, message: "", times_show: "" };

const CustomersMessages = () => {
  document.title = "CustomersMessages - POWERGrid Cloud";
  const [data, setData] = useState([]);
  const [isFetcing, setIsFetching] = useState(true);
  const [isNotFound, setIsNotFound] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [message, setMessage] = useState(initState);
  const [msg, setMsg] = useState("")

  const checkMessageAdd = getPermission("customer_message_add")
  const checkMessageUpdate = getPermission("customer_message_update")
  const checkMessageDelete = getPermission("customer_message_delete")

  useEffect(() => {
    getMessages();
  }, []);

  const getMessages = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_API_URL}/customers_messages`, {
        headers: {
          Authorization: "Bearer " + Cookies.get("access_token"),
        },
      })
      .then(function (response) {
        if (response?.data?.success) {
          setIsFetching(true);
          setIsNotFound(false);
          setData([...response.data.data]);
          setIsFetching(false);

        } else {
          setData([]);
          setIsFetching(false);
          setIsNotFound(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const deleteRow = (deleteEntry) => {
    const askToConfirm = window.confirm(
      `Are you sure want to Delete this Entry`
    );
    if (askToConfirm) {
      axios
        .delete(
          `${process.env.REACT_APP_BACKEND_API_URL}/customers_messages?id=${deleteEntry}`,
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("access_token"),
            },
          }
        )
        .then(function (response) {
          window.alert(response?.data.message);
          getMessages();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const getId = (id) => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_API_URL}/customers_messages/${id}`, {
        headers: {
          Authorization: "Bearer " + Cookies.get("access_token"),
        },
      })
      .then(function (response) {
        if (response?.data.message) {
          setMessage(response.data.message);
          setModalShow(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const saveAPI = (data) => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_API_URL}/customers_messages`, data, {
        headers: {
          Authorization: "Bearer " + Cookies.get("access_token"),
        },
      })
      .then(function (response) {
        setModalShow(false);
        if (response?.data.message) {
          getMessages();
          toast.success('Message Added Successfuly', {autoClose:3000})
        } else {
          // alert(response?.data.message);
          setMsg(response.data.message);
          toast.error(message, {autoClose:3000})
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const updateAPI = (data) => {
    axios
      .put(`${process.env.REACT_APP_BACKEND_API_URL}/customers_messages`, data, {
        headers: {
          Authorization: "Bearer " + Cookies.get("access_token"),
        },
      })
      .then(function (response) {
        setModalShow(false);
        if (response?.data.message) {
          getMessages();
          toast.success('Message Updated Successfuly', {autoClose:3000})
        } else {
          // alert(response?.data.message);
          setMsg(response.data.message);
          toast.error(message, {autoClose:3000})
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleSubmit = (data) => {
    if (data?.id > 0) {
      updateAPI(data);
    } else {
      saveAPI(data);
    }
  };

  return (
    <>
      <NavBar />
      <main className="wrapper">
        <div className="page-content">
          <div className="wallbox-area">
            <div className="pages-title">
              <div className="h2">Customers Messages</div>
              <span>View all Messages</span>
            </div>
            <div className="search-filter">
              <div style={{ width: "120px" }} scope="col" className="serialT">
                {checkMessageAdd === true ?
                <button
                  className="btn-yellpow"
                  onClick={() => {
                    setMessage(initState);
                    setModalShow(true);
                  }}
                >
                  Add Item
                </button>
                :
                null
                }
              </div>
            </div>
            <div className="table-componet" style={{ display: "contents" }}>
              <div className="col-md-12">
                <div className="col-md-12">
                  <div className="tab__content mt-2">
                    <table className="table box-table">
                      <thead>
                        <tr>
                          <th
                            style={{ width: "150px" }}   

                            scope="col"
                            className="serialT"
                          >
                            <h6>
                              <b> ID</b>
                            </h6>
                          </th>
                          <th scope="col" className="serialT">
                            <h6>
                              <b> Message</b>
                            </h6>
                          </th>
                          <th scope="col" className="serialT">
                            <h6>
                              <b> Times Show</b>
                            </h6>
                          </th>
                          <th scope="col" className="serialT">
                            <h6>
                              <b> Time</b>
                            </h6>
                          </th>
                          {checkMessageUpdate || checkMessageDelete === true ?
                          <th scope="col" className="serialT">
                            <h6>
                              <b> Actions</b>
                            </h6>
                          </th>
                          :
                          null
                          }
                          <th
                            style={{ width: "58px" }}
                            scope="col"
                            className="serialT"
                          ></th>
                        </tr>
                      </thead>
                      <tbody>
                      {data.length > 0 ?
                        (
                          data?.map((item, i) => {
                            return (
                              <tr key={i} style={{ fontSize: "13px" }} className="border-bottom">
                                <td>{item?.id}</td>
                                <td>{item?.message}</td>
                                <td>{item?.times_show}</td>
                                <td>{item?.created_at}</td>
                                <td className="Actions">
                                    {checkMessageUpdate === true ?
                                      <button
                                        className="actionButton"
                                        onClick={() =>
                                          
                                          getId(
                                            item?.id
                                          )
                                        }
                                      >
                                        <img alt="" src={update} /> Update
                                      </button>
                                      :
                                      null
                                    }
                                    
                                    {checkMessageDelete === true ?
                                      <button
                                        className="actionButton"
                                        style={{ margin: "5px" }}
                                        onClick={() => deleteRow(item?.id)}
                                      >
                                        <img alt="" src={Delete} /> Delete
                                      </button>
                                      :
                                      null
                                    }
                                    <button></button>
                                </td>
                              </tr>
                            );
                          })
                        )
                        :
                        isFetcing === true ?
                        (
                          <tr>
                            <td>
                              <div className="router-loading-spinner">
                                <TailSpin width={50} height={50} color="#002060" />
                              </div>
                            </td>
                          </tr>
                        )
                        :
                        <h5 className="text-center mt-5">Result Not found...</h5>
                      }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <MessageForm
          show={modalShow}
          onHide={() => setModalShow(false)}
          data={message}
          callBackSubmit={handleSubmit}
        />
      </main>
    </>
  );
};

export default CustomersMessages;