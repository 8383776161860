import React from 'react';
import { BsChevronDoubleRight } from "react-icons/bs";
import { Link } from 'react-router-dom';


const UsersBox = [
    {
        id: 1,
        name: 'POWERGrid Cloud',
        role: 'Admin',
    },
    {
        id: 2,
        name: 'POJ-103949543129',
        role: 'Admin',
    },
    {
        id: 3,
        name: 'POJ-103949543129',
        role: 'Admin',
    },
    {
        id: 4,
        name: 'POJ-MUSTER',
        role: 'Admin',
    },
    {
        id: 5,
        name: 'POJ-MUSTER',
        role: 'Admin',
    },
    {
        id: 6,
        name: 'POJ-MUSTER',
        role: 'Admin',
    },
    {
        id: 7,
        name: 'POJ-MUSTER',
        role: 'Admin',
    },
    {
        id: 8,
        name: 'POJ-MUSTER',
        role: 'Admin',
    },
    {
        id: 9,
        name: 'POJ-MUSTER',
        role: 'Admin',
    },
    {
        id: 10,
        name: 'POJ-MUSTER',
        role: 'Admin',
    },

]

const UserWallbox = () => {
    return (
        <div className='userBox'>
            <div className='userTable_con'>
                <table className="table box-table">
                    <thead>
                        <tr>
                            <th scope="col" className="user_name">Environment Key</th>
                            <th scope="col" className="roles">Role</th>
                        </tr>
                    </thead>
                    <tbody>

                        {/* {UsersBox.map((user) => (
                            <tr key={user.id} className={user.id}>
                                <td className="name"><span><Link to='#'>{user.name}</Link></span></td>
                                <td className="role"><span>{user.role}</span></td>
                            </tr>
                        ))} */}
                    </tbody>
                </table>
            </div>

            <div className="pagination-content">
                {/*  <ul>
                    <li className="active">1</li>
                    <li>2</li>
                    <li>3</li>
                    <li>4</li>
                    <li><BsChevronDoubleRight /></li>
                </ul> */}
            </div>
        </div>
    )
}

export default UserWallbox