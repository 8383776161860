import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { TailSpin } from "react-loader-spinner";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const FirmwareDashboard = () => {
  document.title = "Firmware Count - POWERGrid Cloud";

  const [data, setData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  const firmwareCount = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_API_URL}/firmware_count`, {
        headers: {
          Authorization: "Bearer " + Cookies.get("access_token"),
        },
      })
      .then(function (response) {
        if (response?.data?.data) {
          setIsFetching(true);
          setData([...response.data.data]);
          setIsFetching(false);
        } else {
          setData([]);
          setIsFetching(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    firmwareCount();
  }, []);
  
  return (
    <>
      <div className="pages-title">
        <div className="h2">Firmware Count</div>
      </div>
      <div className="table-componet mt-20">
        <div className="row">
          <div className="col-md-12">
            <div className="tab__content">
              <table className="table box-table user_table">
                <thead>
                  <tr>
                    <th scope="col" className="ownerT">
                      Sr. No
                    </th>
                    <th scope="col" className="serialT">
                      Version
                    </th>
                    <th scope="col" className="FirmwareT">
                      No. of Wallboxes
                    </th>
                    <th scope="col" className="statusT">
                      Percentage
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 ? (
                    data?.map((item, i) => {
                      return (
                        <tr
                          key={i}
                          style={{ fontSize: "13px" }}
                          className="border-bottom"
                        >
                          <td>{i + 1}</td>
                          <td>{item?.version}</td>
                          <td>{item?.count}</td>
                          <td>{item?.percentage}%</td>
                        </tr>
                      );
                    })
                  ) : isFetching == true ? (
                    <div className="router-loading-spinner">
                      <TailSpin width={50} height={50} color="#002060" />
                    </div>
                  ) : (
                    <h5 className="text-center mt-5">Result Not found...</h5>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FirmwareDashboard;
