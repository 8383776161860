import React, { useState, useEffect } from "react";
import NavBar from "../NavBar/NavBar";
import "./Firmware.scss";
import { useHistory } from "react-router-dom"; 
import axios from "axios";
import Cookies from 'js-cookie'
import { TailSpin } from "react-loader-spinner";
import update from "../../images/icons/update.png";
import Delete from "../../images/icons/bin.png";
import { getPermission } from "../../utils";

const Firmware = () => {
  document.title = "Firmware - POWERGrid Cloud";
  const [data, setData] = useState([]);
  const [isFetcing, setIsFetching] = useState(true);

  const checkAddFirmware = getPermission("add_firmware")
  const checkUpdateFirmware = getPermission("update_firmware")
  const checkDeleteFirmware = getPermission("delete_firmware")

  const getFirmwareData = () => {

    axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/firmware_info`, {
        headers: {
          Authorization: "Bearer " + Cookies.get("access_token"),
        },
      })
      .then(function (response) {
        if (response?.data?.success) {
          setIsFetching(true);
          setData([...response.data.data]);
          setIsFetching(false);

        } else {
          setData([]);
          setIsFetching(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getFirmwareDataById = (id) => {
    firmwarehistory.push("/addfirmware", {update: true, firmwareId: id});
  }


  const deleteFirmware = (deleteEntry) => {
    const askToConfirm = window.confirm(
      `Are you sure want to Delete this Entry`
    );
    if (askToConfirm) {
      axios
        .delete(
          `${process.env.REACT_APP_BACKEND_API_URL}/firmware?id=${deleteEntry}`,
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("access_token"),
            },
          }
        )
        .then(function (response) {
          window.alert(response?.data.message);
          getFirmwareData();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const history = useHistory();
    const Group = () => {
        history.push("/group");
    }
  
  const firmwarehistory = useHistory();
  const AddFirmware = () => {
    firmwarehistory.push("/addfirmware");
  }

  useEffect(() => {
    getFirmwareData();
  }, []);

  return (
    <>
      <NavBar />
      <main className="wrapper">
        <div className="page-content">
          <div className="wallbox-area">
            <div className="pages-title">
              <div className="h2">Firmware</div>
              <span>View all Firmware</span>
            </div>
            <div className="search-filter">
                <div style={{ width: "120px" }} scope="col" className="serialT">
                  {checkAddFirmware == true ?
                    <button
                    className="btn-yellpow" onClick={AddFirmware}
                    >
                    Add Firmware
                    </button>
                    :
                    null
                  }
                </div>
                <div style={{ width: "120px" }} scope="col" className="serialT">
                    <button className="btn-yellpow" onClick={Group}> Group List </button>
                </div>            
            </div>
            <div className="table-componet" style={{ display: "contents" }}>
              <div className="col-md-12">
                <div className="col-md-12">
                  <div className="tab__content mt-2">
                    <table className="table box-table">
                      <thead>
                        <tr>
                          <th
                            style={{ width: "150px" }}   

                            scope="col"
                            className="serialT"
                          >
                            <h6>
                              <b> ID</b>
                            </h6>
                          </th>
                          <th scope="col" className="serialT">
                            <h6>
                              <b> Change Log</b>
                            </h6>
                          </th>
                          <th scope="col" className="serialT">
                            <h6>
                              <b> Created At</b>
                            </h6>
                          </th>
                          <th scope="col" className="serialT">
                            <h6>
                              <b> Version</b>
                            </h6>
                          </th>
                          <th scope="col" className="serialT">
                            <h6>
                              <b> Branch</b>
                            </h6>
                          </th>
                          {checkUpdateFirmware || checkDeleteFirmware == true ?
                          <th scope="col" className="actionsT">
                            <h6>
                              <b> Actions</b>
                            </h6>
                          </th>
                          :
                          null
                          }
                          <th
                            style={{ width: "58px" }}
                            scope="col"
                            className="serialT"
                          ></th>
                        </tr>
                      </thead>
                      <tbody>
                      {data.length > 0 ?
                        (
                          data?.map((item, i) => {
                            return (
                              <tr key={i} style={{ fontSize: "13px" }}>
                                <td>{item?.id}</td>
                                <td>{item?.changelog}</td>
                                <td>{item?.created_at}</td>
                                <td>{item?.version}</td>
                                <td>{item?.branch}</td>
                                <td className="Actions">
                                  <span>
                                    {checkUpdateFirmware == true ?
                                      <button
                                        onClick={() =>
                                          
                                          getFirmwareDataById(
                                            item?.id
                                          )
                                        }
                                      >
                                        <img alt="" src={update}  /> Update
                                      </button>
                                      :
                                      null
                                    }
                                    
                                    {checkDeleteFirmware == true ?
                                      <button
                                        onClick={() => deleteFirmware(item?.id)} 
                                      >
                                        <img alt="" src={Delete} /> Delete
                                      </button>
                                      :
                                      null
                                    }
                                    <button></button>
                                  </span>
                                </td>
                              </tr>
                            );
                          })
                        )
                        :
                        isFetcing == true ?
                        (
                          <tr>
                            <td>
                              <div className="router-loading-spinner">
                                <TailSpin width={50} height={50} color="#002060" />
                              </div>
                            </td>
                          </tr>
                        )
                        :
                        <h5 className="text-center mt-5">Result Not found...</h5>
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Firmware;