import React, { useEffect, useState } from "react";
import NavBar from "../../NavBar/NavBar";
import { AiOutlineSearch } from "react-icons/ai";
import { Link } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";

const Code2Serial = () => {
  document.title = "Code2Serial - POWERGrid Cloud";

  const [code, setSerialNumber] = useState("");
  const [events, setEvents] = useState([]);
  const [currentEmail, setCurrentEmail] = useState("")

  function fetchData() {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_API_URL}/serial_code_wallbox?code=${code}`,
        {
          headers: {
          Authorization: "Bearer " + Cookies.get("access_token"),
        },
        }
      )
      .then((response) => {
        setEvents(response.data);
      })
      .catch((error) => {
        console.error("error found", error);
      });
  }

  useEffect(() => {
    // fetchData();
    setCurrentEmail(Cookies.get("user_email"))
  }, []);

  const submitForm = (e) => {
    e.preventDefault();
    fetchData();
  };
  
  return (
    <>
      <NavBar />
      <main className="wrapper">
        <div className="page-content">
          <div className="wallbox-area">
            <div className="pages-title">
              <div className="h2">All Documents</div>
              <span>View all documents</span>
            </div>
            <div className="user__content">
              <div className="user__data">
                <ul className="panel_section">
                  <li>
                    <span>APIs Selection</span>
                  </li>
                  <li>
                    <Link to="/docs/sim2serial"> Sim2Serial</Link>
                  </li>
                  <li>
                    <Link to="/docs/serial2sim"> Serial2Sim</Link>
                  </li>
                  <li>
                    <Link to="/docs/Serial2Code">Serial2Code</Link>
                  </li>
                  <li>
                    <Link to="/docs/Code2Serial" className="active">Code2Serial</Link>
                  </li>
                  {
                      currentEmail === "christoph.zillner@powerjames.com" || currentEmail === "tarunsengar1987@gmail.com" ?
                      <li>
                      <Link to="/docs/syncWallbox">Sync Wallbox</Link>
                      </li>
                      :
                      null
                  }
                </ul>
              </div>
              <div className="user__data">
                <div className="mt-5 mr-5">
                  <h6 className="text-left mt-5">
                    Get Serial Number by Codes
                  </h6>
                </div>

                <div className="search-filter">
                  <form onSubmit={submitForm} action="">
                    <input
                      type="text"
                      onChange={(e) => setSerialNumber(e.target.value)}
                      placeholder="Search By Codes"
                    />
                    <button type="submit">
                      <AiOutlineSearch />
                    </button>
                  </form>
                </div>
                <div className="wallbox-tabl">
                  {
                    events?.success === true ?
                    (
                      <div className="table-componet">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="tab__content">
                              <table className="table box-table">
                                <thead>
                                  <tr>
                                    <th scope="col">Serial Number</th>
                                    <th scope="col" style={{ paddingLeft: "150px" }}> Owner Code </th>
                                    <th scope="col" style={{ paddingRight: "300px" }}> Installer Code </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td style={{ paddingLeft: "0px" }}>{events.data.serial_number}</td>
                                    <td style={{ paddingLeft: "150px" }}>{events.data.owner_code}</td>
                                    <td style={{ paddingRight: "300px" }}>{events.data.installer_code}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                    :
                    (
                      <h5 className="text-center mt-5">
                        Result Not found...
                      </h5>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Code2Serial;