import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';
import axios from "axios"
import Cookies from 'js-cookie'
import NavBar from "../../NavBar/NavBar";
import filterAdd from "../../../images/icons/filter-add.png";
import userI from "../../../images/icons/user.png";
import { AiOutlineSearch } from "react-icons/ai";
import rolesI from "../../../images/icons/roles.png";
import ppermiI from "../../../images/icons/ppermi.png";



import "../User.scss";
import { Link } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";

import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()


const UserRole = () => {
    document.title = "User - POWERGrid Cloud"
    const [roleList, setRoleList] = useState([])
    const [isFetcing, setIsFetching] = useState(true)
    const [users, setUsers] = useState([])
    const [sorting, setSorting] = useState("ID")
    const [searchValue, setSearchValue] = useState("")
    const [userRoleEdit, setUserRoleEdit] = useState("")
    const [userRoleList, setUserRoleList] = useState([])

    const getUsersList = (roleObjs) => {
        axios.get(`${process.env.REACT_APP_USER_BACKEND_API_URL}/users/user_table?search_string=${searchValue}&order=${sorting}`, {
            headers: {
                Authorization: "Bearer " + Cookies.get("access_token")
            }
        })
            .then(function (response) {
                if (response.data) {
                    setUsers(response.data)

                    // response?.data?.map(user => {
                    //     const obj = roleObjs.map(role => {
                    //         return {
                    //             "roles": {
                    //                 "user": user.ID, "role": role.RoleName, "checked": user.Roles.includes(role.RoleName)
                    //             }
                    //         }
                    //     })
                    //     userRoleList(obj)
                    // })
                }
            })
            .catch(function (error) {
                setIsFetching(false)
                console.log(error);
            })
            .then(() => {
                setIsFetching(false)
            })
    }


    const getRoleList = () => {
        let role_page_url = `${process.env.REACT_APP_USER_BACKEND_API_URL}/roles`
        axios.get(role_page_url, {
            headers: {
                Authorization: "Bearer " + Cookies.get("access_token")
            }
        })
            .then(function (response) {
                if (response.data) {
                    setRoleList(response.data)
                    getUsersList(response.data);
                }
            })
            .catch(function (error) {
                setIsFetching(false)
                console.log(error);
            })
            .then(() => {
                setIsFetching(false)
            })
    }

    const onRoleCheck = (e, user, role) => {
        const checked = e.target.checked
        const objIndex = userRoleList.findIndex((obj => obj.role_id == role.ID && obj.user_id == user.ID));

        if (objIndex > -1){
            userRoleList[objIndex].checked = checked
        } else {
            const addRole = {"user_id": user.ID, "role_id": role.ID, "env_id": "969d4ba6-5d45-4c5d-86b6-09941d2856bf", "checked": checked}
            setUserRoleList(userRole => [...userRole, addRole])
        }
    }

    const onSubmit = () => {
        if (userRoleEdit) {
            const data_dict = {
                "set_role": userRoleList.filter(obj => obj.checked == true),
                "remove_role": userRoleList.filter(obj => obj.checked == false)
            }

            axios.post(`${process.env.REACT_APP_USER_BACKEND_API_URL}/users/bulk_set_role`, data_dict, {
                headers: {
                    Authorization: "Bearer " + Cookies.get("access_token")
                }
            })
            .then(function (response) {
                if (response.data) {
                    setUserRoleEdit(false)
                    getRoleList();
                    toast.success('Role was remove and add successfully to the Users Role', {autoClose:3000})
                }
            })
            .catch(function (error) {
                console.log(error);
            })
        }
    }

    useEffect(() => {
        getRoleList();
    }, [searchValue, sorting])

    return (
        <>
            <NavBar />
            <main className="wrapper">
                <div className="page-content">
                    <div className="wallbox-area">
                        <div className="pages-title">
                            <div className="h2">Permissions</div>
                            <span>View all users in one table</span>
                        </div>
                        <div className="user__content">
                            <div className="user__data">
                                <ul className="panel_section hhh" style={{ padding: "30px 12px" }}>
                                    <li>
                                        <span>Panel Selections</span>
                                    </li>
                                    <li>
                                        <Link to="/rolePermission"><img alt="" src={ppermiI} /> Permission's Roles</Link>
                                    </li>
                                    <li>
                                        <Link to="/userRole" className="active"><img alt="" src={rolesI} />User's Roles </Link>
                                    </li>
                                    <li>
                                        <Link to="/userlogcontrol"><img alt="" src={rolesI} />User Log Control</Link>
                                    </li>
                                    {/* <li className="additional_tt">
                                        <span>Additional Functions</span>
                                    </li>
                                    <li>
                                        <button>Add Permission</button>
                                    </li>
                                    <li>
                                        <button>Add Role</button>
                                    </li> */}
                                    {/* <li>
                                        <button onClick={() => setModalShow(true)}><AiOutlinePlus />Add new User</button>
                                    </li> */}
                                </ul>
                            </div>
                            <div className="user__data">
                                <div className="search-filter">
                                    <form action="">
                                        <input onChange={e => setSearchValue(e.target.value)} type="search" placeholder="Search Permissions..." />
                                        <button type="submit"><AiOutlineSearch /></button>
                                    </form>
                                    <div className="filter">
                                        Filter <img alt="" src={filterAdd} />
                                    </div>
                                    { userRoleEdit ?  <button
                                        className="btn-yellpow"
                                        onClick={() => {
                                            onSubmit();
                                        }}
                                        >Submit
                                    </button> 
                                    : 
                                    <button
                                        className="btn-yellpow"
                                        onClick={() => {
                                            setUserRoleEdit(true);
                                        }}
                                        >Update
                                    </button> }
                                </div>
                                <div className="wallbox-tabl">
                                    <div className="table-componet">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="tab__content">
                                                    <table className="table box-table">
                                                        <thead>
                                                            <tr>
                                                                {/* <th scope="col" className="id">
                                                                    <span className="d-installed">ID </span>
                                                                </th> */}
                                                                <th scope="col" className="serialT">User email</th>
                                                                {
                                                                    roleList.map((role, index) => {
                                                                        return <th key={index} scope="col" className="FirmwareT">{role?.RoleName}</th>
                                                                    })
                                                                }
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {isFetcing ?
                                                            <tr>
                                                                <td>
                                                                    <div className="router-loading-spinner">
                                                                        <TailSpin width={50} height={50} color="#002060" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            :
                                                            users.map((user, index) => (
                                                                <tr key={index}>
                                                                    {/* <td>
                                                                        <label class="form-check-label" for="check1">
                                                                            {permission.ID}
                                                                        </label>
                                                                    </td> */}
                                                                    <td>
                                                                        <label class="form-check-label" for="check1">
                                                                            {user.Email}
                                                                        </label>
                                                                    </td>
                                                                    {
                                                                        roleList.map((role, index) => {
                                                                            return (
                                                                                <td key={index}>
                                                                                    {
                                                                                        userRoleEdit ? <input class="form-check-input" type="checkbox" onClick={(e) => onRoleCheck(e, user, role)} defaultChecked={user?.Roles?.includes(role.RoleName) ? true : false} id="check1"/>
                                                                                        :
                                                                                        <input class="form-check-input" type="checkbox" checked={user?.Roles?.includes(role.RoleName) ? true : false} id="check1"/>
                                                                                    }
                                                                                </td>
                                                                            )
                                                                        })
                                                                    }
                                                                </tr>
                                                            ))}

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pagination-content">
                                            {/* <ul>
                                                <li className="active">1</li>
                                            </ul> */}
                                            {/* <div className="pageRight">
                                                <span>Showing {roles.length} of {roles.length} items</span>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {/* <MyVerticallyCenteredModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                /> */}
            </main>
        </>
    );
};



export default UserRole;
