import React, { useEffect, useState, useRef } from "react";
import Rectangle from "../../images/Rectangle.png";
import NavBar from '../NavBar/NavBar';
import axios from "axios";
import Cookies from "js-cookie";
import { useHistory, useLocation } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
// import Marker from 'google-map-react';
import GoogleMapReact from 'google-map-react';
import Marker from "../Marker/Marker";
import { geocodeByAddress } from 'react-places-autocomplete'


import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

const UpdateWallbox = () => {

    const [isProcess, setIsProcess] = useState(false);
    const [singleWallBox, setSingleWallbox] = useState({});
    const [owner, setOwner] = useState();
    const [data, setData] = useState([]);
    const [message, setMessage] = useState("")
    const [installedAddress, setInstalledAddress] = useState({})
    const [addressLocation, setAddressLocation] = useState({
        center: {
            lat: 10.99835602,
            lng: 77.01502627
        },
        zoom: 11
    })
    const [address, setAddress] = useState("")

    const history = useHistory();
    const getWallboxData = () => {
        history.push("/");
    };

    const location = useLocation();
    const singleWallboxList = location.state;

    const autoCompleteRef = useRef();
    const inputRef = useRef();

    const options = {
        componentRestrictions: { country: ["de", "in"] },
        fields: ["address_components", "geometry", "icon", "name"],
        types: ["establishment"]
    };

    const defaultProps = {
        center: {
            lat: 10.99835602,
            lng: 77.01502627
        },
        zoom: 15
    };

    const getSingleWallbox = () => {
        axios
            .get(`${process.env.REACT_APP_BACKEND_API_URL}/single_wallbox/${singleWallboxList.serial_number}`, {
                headers: {
                    authorization: "Bearer " + Cookies.get("access_token"),
                },
            })
            .then((response) => {
                if (response.data.success) {
                    setSingleWallbox(response.data.data);
                    setOwner(response?.data?.data?.owner_id)
                    getWallboxOwners(response?.data?.data?.owner_id);
                    getReverseGeocodingData(response.data.data.location)
                } else {
                    setMessage(response.data.message);
                    toast.error(message, { autoClose: 3000 })
                }
            })
            .catch((error) => {
                console.error("Got Error", error);
            });
    };

    const updateWallbox = (e) => {
        e.preventDefault();
        let dataInfo = {
            serial_number: singleWallBox.serial_number,
            hardware_info: singleWallBox.hardware_info,
            sim: singleWallBox.sim,
            owner: owner,
            company_name: singleWallBox.company_name,
            city: singleWallBox.city,
            street: singleWallBox.street,
            postal_code: singleWallBox.postal_code,
            address1: singleWallBox.address1,
            state: singleWallBox.state,
            location: singleWallBox.location,
            phone_number: singleWallBox.phone_number
        }
        if (Object.keys(installedAddress).length > 0){
            dataInfo["city"] = ""
            dataInfo["street"] = ""
            dataInfo["postal_code"] = ""
            dataInfo["address1"] = ""
            dataInfo["state"] = ""
            dataInfo["location"] = ""

            installedAddress.address_components.forEach(element => {
                if (installedAddress.name){
                    dataInfo["address1"] = installedAddress.name
                }
                if (element?.types?.includes('route') || element?.types?.includes('sublocality_level_1')){
                    if (element?.types?.includes('street_number')){
                        dataInfo["street_number"] = element.long_name
                    }
                    if (element?.types?.includes('route')){
                        dataInfo["street"] = element.long_name
                    }
                }
                if (element?.types?.includes('administrative_area_level_3') ? element.types.includes('administrative_area_level_3') : element.types.includes('administrative_area_level_2')){
                    dataInfo["city"] = element.long_name
                }
                if (element?.types?.includes('administrative_area_level_1')){
                    dataInfo["state"] = element.long_name
                }
                if (element?.types?.includes('postal_code')){
                    dataInfo["postal_code"] = element.long_name
                }
            });
    
            dataInfo["location"] = {
                "lat": installedAddress.geometry.location.lat(),
                "lng": installedAddress.geometry.location.lng()
            }

            if (!dataInfo.street){
                if (Object.keys(installedAddress).length > 0){
                    installedAddress.address_components.forEach(element => {
                        if (element?.types?.includes('sublocality_level_1')){
                            dataInfo["street"] = element.long_name
                        }
                    }
                )}
                
            }
    
            if (dataInfo.street_number){
                dataInfo["street"] += `, ${dataInfo.street_number}`
                delete dataInfo["street_number"]
            }
        }


        axios
            .put(`${process.env.REACT_APP_BACKEND_API_URL}/wallbox_update/${singleWallboxList.serial_number}`, dataInfo, {
                headers: {
                    Authorization: "Bearer " + Cookies.get("access_token"),
                    "Content-Type": "multipart/form-data",
                },
            })
            .then(function (response) {
                if (response.data.success) {
                    getWallboxData();
                    toast.success('Wallbox Updated Successfuly', { autoClose: 3000 })
                }
            })
            .catch(function (error) {
                console.log(error);
            })
            .then(function () {
                setIsProcess(false);
            });
    };

    const getWallboxOwners = (owner_id) => {

        axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/wallbox_owners`, {
            headers: {
                Authorization: "Bearer " + Cookies.get("access_token"),
            },
        })
            .then(function (response) {
                if (response?.data?.success) {
                    setData([...response.data.data]);
                    getOwnerDetails(owner_id, response.data.data)
                } else {
                    setData([]);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    function getReverseGeocodingData(latlng) {
        // var latlng = new google.maps.LatLng(lat, lng);
        // This is making the Geocode request
        var geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ 'latLng': latlng },  (results, status) =>{
            if (status !== window.google.maps.GeocoderStatus.OK) {
                alert(status);
            }
            // This is checking to see if the Geoeode Status is OK before proceeding
            if (status == window.google.maps.GeocoderStatus.OK) {
                // var address = (results[0].formatted_address);
                setAddress(results[0].formatted_address)
                setAddressLocation({
                    center: {
                        lat: results[0].geometry.location.lat(),
                        lng: results[0].geometry.location.lng(),
                    },
                    zoom: 15
                })
            }
        });
    }

    const getOwnerDetails = (id, owners) => {
        const owner = owners.find(owner => owner.id == id)
        setSingleWallbox(singleWallBox => ({
            ...singleWallBox,
            ["email"]: owner ? owner.email : null,
            ["company_name"]: owner ? owner.company_name : null,
        })
        );
    }

    const changeAddress = () => {
        setAddress('')
    }

    useEffect(() => {
        getSingleWallbox();

        autoCompleteRef.current = new window.google.maps.places.Autocomplete(
            inputRef.current,
            options
        );

        autoCompleteRef.current.addListener("place_changed", async function () {
            const place = await autoCompleteRef.current.getPlace();
            setAddress('')
            console.log({
                "lat": place.geometry.location.lat(),
                "lng": place.geometry.location.lng(),
            })

            setInstalledAddress(place)
            setAddressLocation({
                center: {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                },
                zoom: 15
            })
        });
    }, []);
    return (
        <>
            <NavBar />
            <main className="wrapper">
                <div className="page-content">
                    <div className="wallbox-area">
                        <div className="pages-title-list">
                            <ul>
                                <li>
                                    <span>
                                        <Link to="/">
                                            All Wallbox
                                        </Link>
                                    </span>
                                </li>
                                <li>
                                    <span>Update Wallboxes</span>
                                </li>
                            </ul>
                        </div>
                        <div className="user__content">

                            <div className="user__data">

                                <div className="wallbox-tabl">
                                    {
                                        // events?.length < 1 ? 
                                        // <h5 h5 className="text-center mt-5">Result Not found...</h5>
                                        <div className="table-componet">
                                            <div className="details_cont">
                                                <div className="wallbox_details" style={{ position: "relative" }}>
                                                    <div className="wallbox_details_content1">
                                                        <form onSubmit={updateWallbox}>
                                                            <div className="box_group">
                                                                <label>Serial Number</label>
                                                                <input value={singleWallBox?.serial_number} type="text" readOnly />
                                                            </div>
                                                            {/* <div className="box_group">
                                                            <label>Certificate</label>
                                                            <input value={singleWallBox?.certificate} type="number" readOnly/>
                                                        </div> */}
                                                            <div className="box_group">
                                                                <label>Hardware Info</label>
                                                                <input value={singleWallBox?.hardware_info}
                                                                    onChange={(e) => {
                                                                        singleWallBox.hardware_info = e.target.value;
                                                                        setSingleWallbox({ ...singleWallBox })
                                                                    }}
                                                                    type="text" />
                                                            </div>

                                                            <div className="box_group">
                                                                <label>Sim</label>
                                                                <input value={singleWallBox?.sim}
                                                                    onChange={(e) => {
                                                                        singleWallBox.sim = e.target.value;
                                                                        setSingleWallbox({ ...singleWallBox })
                                                                    }}
                                                                    type="text" />
                                                            </div>
                                                            {/* <div className="box_group">
                                                            <label>Service Event</label>
                                                            <input value={singleWallBox?.service_event} type="text" required/>
                                                        </div> */}
                                                            <div className="box_group">
                                                                <label>Owner</label>
                                                                {/* <input value={singleWallBox?.owner} 
                                                            onChange={(e) => {
                                                                singleWallBox.owner = e.target.value;
                                                                setSingleWallbox({...singleWallBox})
                                                            }}
                                                            type="text"/> */}
                                                                <Form.Select
                                                                    aria-label="Default select example"
                                                                    onChange={(e) => {
                                                                        setOwner(e.target.value)
                                                                        // singleWallBox.owner = e.target.value;
                                                                        // setSingleWallbox({ ...singleWallBox })
                                                                        getOwnerDetails(e.target.value, data)
                                                                    }}
                                                                    value={owner}
                                                                >
                                                                    {data.map((element, index) => (
                                                                        <option value={element?.id} key={index}>
                                                                            {element?.name}
                                                                        </option>
                                                                    ))}
                                                                </Form.Select>
                                                            </div>
                                                            <div className="box_group">
                                                                <label>Email</label>
                                                                <input defaultValue={singleWallBox.email} type="email" readOnly />
                                                            </div>
                                                            <div className="box_group">
                                                                <label>Company Name</label>
                                                                <input defaultValue={singleWallBox?.company_name}
                                                                    onChange={(e) => {
                                                                        singleWallBox.company_name = e.target.value;
                                                                        setSingleWallbox({ ...singleWallBox })
                                                                    }}
                                                                    type="text" />
                                                            </div>
                                                            <div className="box_group">
                                                                <label>Install Address :</label>
                                                                {
                                                                    address ? <input onChange={changeAddress} value={address} /> : <input ref={inputRef} />
                                                                } 
                                                                {/* <input ref={inputRef} /> */}
                                                            </div>
                                                            <div style={{ height: '300px', width: '500px' }}>
                                                                <GoogleMapReact
                                                                    bootstrapURLKeys={'AIzaSyApcRz24my_H6fqJzHD0OJ_fnau_Ut40Os'}
                                                                    center={addressLocation.center}
                                                                    zoom={addressLocation.zoom}
                                                                >
                                                                    <Marker
                                                                        lat={addressLocation.center.lat}
                                                                        lng={addressLocation.center.lng}
                                                                        color="blue"
                                                                    />
                                                                </GoogleMapReact>
                                                            </div>

                                                            {/* <div className="message">
                                                            <p className="mt-2" style={{ color: "gray", fontWeight: "bold" }}>{message}</p>
                                                        </div> */}
                                                            <button className="btn-yellpow">Submit</button>
                                                        </form>
                                                        <div className="box_right_img">
                                                            <img alt="" src={Rectangle} />
                                                            <span>Status</span>
                                                            <span className="Running">{singleWallBox?.status}</span>
                                                            {/* <Form.Select
                                                            aria-label="Default select example"
                                                            onChange={(e) => {
                                                                singleWallBox.status = e.target.value;
                                                                setSingleWallbox({...singleWallBox})
                                                            }}
                                                            value={singleWallBox?.status}
                                                        >
                                                                <option>active</option>
                                                                <option>inactive</option>
                                                                <option>registered</option>
                                                                <option>running</option>   
                                                        </Form.Select> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default UpdateWallbox;