import React, { useEffect, useState, useRef } from "react";
import NavBar from "../../NavBar/NavBar";
import Cookies from 'js-cookie'
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import pp1 from "../../../images/pp1.png";
import GoogleMapReact from 'google-map-react';
import Marker from "../../Marker/Marker";
import { useHistory } from "react-router-dom";

const EditWallboxUser = () => {
  document.title = "Wallbox User - POWERGrid Cloud"

  const history = useHistory();
    const getWallboxData = () => {
      history.push("/");
  };

  const [user, setUser] = useState({})
  const [installedAddress, setInstalledAddress] = useState({})
  const [addressLocation, setAddressLocation] = useState({
    center: {
      lat: 10.99835602,
      lng: 77.01502627
    },
    zoom: 11
  })
  const [address, setAddress] = useState("")
  const [editUserdata, setEditUserData] = useState({
    name: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    city: "",
    address1: "",
    company: "",
    postal_code: "",
    street: "",
    state: "",
    location: ""
  })
  const param = useParams();
  const {user_id} = param;
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const options = {
    componentRestrictions: { country: ["de", "in"] },
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["establishment"]
  };

  function getReverseGeocodingData(latlng) {
    var geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ 'latLng': latlng },  (results, status) =>{
      if (status !== window.google.maps.GeocoderStatus.OK) {
        alert(status);
      }
      if (status == window.google.maps.GeocoderStatus.OK) {
        setAddress(results[0].formatted_address)
        setAddressLocation({
          center: {
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          },
          zoom: 15
        })
      }
    });
  }

  useEffect(() => {
    if (user_id) {
      axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/user_detail/${user_id}`, {
        headers: {
          authorization: "Bearer " + Cookies.get("access_token")
        }
      })
      .then(response => {
        if (response.data) {
          setUser(response.data.data)
          const data = response.data.data
          editUserdata["name"] = data.name
          editUserdata["first_name"] = data.FirstName
          editUserdata["last_name"] = data.LastName
          editUserdata["phone_number"] = data.PhoneNumber
          editUserdata["email"] = data.Email
          editUserdata["company"] = data.CompanyName
          editUserdata["city"] = data.city
          editUserdata["state"] = data.state
          editUserdata["street"] = data.street
          editUserdata["postal_code"] = data.postal_code
          editUserdata["address1"] = data.address1
          editUserdata["location"] = data.Location
          getReverseGeocodingData(data.Location)         
        }
      })
      .catch(error => {
        console.error("Got Error", error)
      })
    }

    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );

    autoCompleteRef.current.addListener("place_changed", async function () {
      const place = await autoCompleteRef.current.getPlace();
      setAddress('')
      console.log({
        "lat": place.geometry.location.lat(),
        "lng": place.geometry.location.lng(),
      })

      setInstalledAddress(place)
      setAddressLocation({
        center: {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        },
        zoom: 15
      })
    });
  }, [user_id])

  const changeAddress = () => {
    setAddress('')
    }

  const handleChange = (e) =>{
    const {name , value} = e.target;
    setEditUserData({
      ...editUserdata, [name]: value
    })
  }

  const handleSubmit = () => {
    if (Object.keys(installedAddress).length > 0){
      editUserdata["city"] = user.city
      editUserdata["street"] = user.street
      editUserdata["postal_code"] = user.postal_code
      editUserdata["address1"] = user.address1
      editUserdata["state"] = user.state
      editUserdata["location"] = user.Location

      installedAddress.address_components.forEach(element => {
        if (installedAddress.name){
          editUserdata["address1"] = installedAddress.name
        }
        if (element?.types?.includes('route') || element?.types?.includes('sublocality_level_1')){
          if (element?.types?.includes('street_number')){
            editUserdata["street_number"] = element.long_name
          }
          if (element?.types?.includes('route')){
            editUserdata["street"] = element.long_name
          }
        }
        if (element?.types?.includes('administrative_area_level_3') ? element.types.includes('administrative_area_level_3') : element.types.includes('administrative_area_level_2')){
          editUserdata["city"] = element.long_name
        }
        if (element?.types?.includes('administrative_area_level_1')){
          editUserdata["state"] = element.long_name
        }
        if (element?.types?.includes('postal_code')){
          editUserdata["postal_code"] = element.long_name
        }
      });

      editUserdata["location"] = {
        "lat": installedAddress.geometry.location.lat(),
        "lng": installedAddress.geometry.location.lng()
      }

      if (!editUserdata.street){
        if (Object.keys(installedAddress).length > 0){
          installedAddress.address_components.forEach(element => {
            if (element?.types?.includes('sublocality_level_1')){
              editUserdata["street"] = element.long_name
            }
          }
        )}  
      }

      if (editUserdata.street_number){
        editUserdata["street"] += `, ${editUserdata.street_number}`
        delete editUserdata["street_number"]
      }
    }
    axios.put(`${process.env.REACT_APP_BACKEND_API_URL}/edit_user/${user_id}`, editUserdata, {
    headers: {
      Authorization: "Bearer " + Cookies.get("access_token")
    }
    })
    .then(function (response) {
      getWallboxData();
    })
    .catch(function (error) {
      console.log(error)
    })
  }

  return (
    <>
      <NavBar />
      <main className="wrapper">
        <div className="page-content">
          <div className="wallbox-area">
            <div className="pages-title-list">
              <ul>
                <li>
                  <Link to="/users">All Users</Link>
                </li>
                <li>
                  <span>{user?.FullName}</span>
                </li>
              </ul>
            </div>
            <div className="wallbox_single_content">
              <div className="details_cont" style={{ position: "relative" }}>
                <div className="wallbox_details">
                  <div className="h5">User Details</div>
                  <div className="wallbox_details_content">
                    <form action="">
                      <div className="box_group">
                        <label>Name</label>
                        <input type="text" name="name" defaultValue={user.name} onChange={(e) => {handleChange(e)}} />
                      </div>
                      <div className="box_group">
                        <label>First Name</label>
                        <input type="text" name="first_name" defaultValue={user.FirstName} onChange={(e) => {handleChange(e)}} />
                      </div>
                      <div className="box_group">
                        <label>Last Name</label>
                        <input type="text" name="last_name" defaultValue={user.LastName} onChange={(e) => {handleChange(e)}} />
                      </div>
                      <div className="box_group">
                        <label> Phone </label>
                        <input type="text" name="phone_number" defaultValue={user.PhoneNumber} onChange={(e) => {handleChange(e)}} />
                      </div>
                      <div className="box_group">
                        <label>E-mail</label>
                        <input type="text" name="email" defaultValue={user.Email} onChange={(e) => {handleChange(e)}} />
                      </div>
                      <div className="box_group">
                        <label>Company Name</label>
                        <input type="text" name="company" defaultValue={user.CompanyName} onChange={(e) => {handleChange(e)}}  />
                      </div>
                      <div className="box_group">
                        <label>Address :</label>
                        {
                            address ? <input onChange={changeAddress} value={address} /> : <input ref={inputRef} />
                        } 
                      </div>
                      <div style={{ height: '300px', width: '500px' }}>
                        <GoogleMapReact
                        bootstrapURLKeys={'AIzaSyApcRz24my_H6fqJzHD0OJ_fnau_Ut40Os'}
                        center={addressLocation.center}
                        zoom={addressLocation.zoom}
                        >
                          <Marker
                          lat={addressLocation.center.lat}
                          lng={addressLocation.center.lng}
                          color="blue"
                          />
                        </GoogleMapReact>
                      </div>
                    </form>
                    <div className="box_right_img">
                    </div>
                  </div>
                  <button className="btn-yellpow" onClick={() => handleSubmit()}>Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default EditWallboxUser;
