import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';
import Cookies from "js-cookie";


import { AiOutlineSearch } from "react-icons/ai";
// import "./ChargeEvents.scss";
import ChargeEventRow from "./ChargeEventRow";
import axios from "axios";

const ChargeEvents = (props) => {
    document.title = "Charge Event - POWERGrid Cloud"

    const [chargingEvents, setChargingEvents] = useState([])
    const [isFetcing, setIsFetching] = useState(true)
    const [totalChargeEventsCount, setTotalChargeEventsCount] = useState(0)
    const [pageCount, setPageCount] = useState(1)
    const [currentPage, setCurrentPage] = useState(1)
    const [sorting, setSorting] = useState("created_at")
    const [orderWay, setOrderWay] = useState("desc")
    const [displaySize, setDisplaySize] = useState(20)
    const [searchValue, setSearchValue] = useState("")
    const [isNotFound, setIfNotFound] = useState(false)

    const onOrderChange = () => {
        if (orderWay === "desc") {
            setOrderWay("asc")
        } else {
            setOrderWay("desc")
        }
    }

    let cancelToken;

    if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Cancelling the previous req")
    }
    cancelToken = axios.CancelToken.source()
    useEffect(() => {
        setIsFetching(true)
        /*  http://116.203.191.150:5013 */
        axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/box_chargeEvent?serial=${props.id}`, {
            headers: {
          Authorization: "Bearer " + Cookies.get("access_token"),
        },
        }, {
            cancelToken: cancelToken.token
        }
        )
            .then(response => {

                const chargeEvents = response.data.data
                const totalWallbox = response?.data.total_count
                setTotalChargeEventsCount(totalWallbox)
                setPageCount(Math.ceil(totalWallbox / displaySize))

                if (chargeEvents) {

                    if (totalWallbox === 0) {
                        setIfNotFound(true)
                    } else {
                        setIfNotFound(false)
                    }

                    if (chargeEvents.length === 0 && currentPage !== 1) {
                        setCurrentPage(1)
                    }

                    if (chargeEvents) {
                        setIsFetching(false)
                        chargeEvents.forEach(element => {
                            element.selected = false
                        });
                        setChargingEvents(chargeEvents)
                    }
                }
            })
            .catch(error => {
                console.log(error)
            })
    }, [currentPage, sorting, orderWay, displaySize, searchValue])

    return (
        <>
                        <div className="wallbox-tabl">
                            {
                                isNotFound && !isFetcing ?
                                    <h5 h5 className="text-center mt-5">Result Not found...</h5>
                                    :
                                    <ChargeEventRow
                                        isFetcing={isFetcing}
                                        setIsFetching={setIsFetching}
                                        totalChargeEventsCount={totalChargeEventsCount}
                                        setTotalChargeEventsCount={setTotalChargeEventsCount}
                                        pageCount={pageCount}
                                        setPageCount={setPageCount}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        chargingEvents={chargingEvents}
                                        setAllWallBoxes={setChargingEvents}
                                        onOrderChange={onOrderChange}
                                        orderWay={orderWay}
                                        displaySize={displaySize}
                                    />
                            }
                        </div>
        </>
    );
};


export default ChargeEvents;
