import React, { useState, useEffect } from "react";


import { TailSpin } from "react-loader-spinner";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import axios from "axios";
import Cookies from "js-cookie";

// Sorting function


const EventRows = ({ events, pageCount, currentPage, setCurrentPage, isFetching, totalDataCount, displaySize }) => {
    const [List, setList] = useState(events)
    const [MasterChecked, setMasterChecked] = useState(false)
    const [SelectedList, setSelectedList] = useState([])


    function onMasterCheck(e) {
        let tempList = List;
        // Check/ UnCheck All Items
        tempList.map((user) => (user.selected = e.target.checked));

        //Update State
        setMasterChecked(e.target.checked)
        setList(tempList)
        setSelectedList(List.filter((e) => e.selected))
    }

    function onItemCheck(e, item) {
        let tempList = List;
        tempList.map((user) => {
            if (user.id === item.id) {
                user.selected = e.target.checked;
            }
            return user;
        });

        //To Control Master Checkbox State
        const totalItems = List.length;
        const totalCheckedItems = tempList.filter((e) => e.selected).length;

        // Update State

        setMasterChecked(totalItems === totalCheckedItems)
        setList(tempList)
        setSelectedList(List.filter((e) => e.selected))
    }

    /*  function getSelectedRows() {
         setSelectedList(List.filter((e) => e.selected))
     } */



    return (
        <>
            <div className="table-componet">
                <div className="row">
                    <div className="col-md-12">
                        <div className="tab__content">
                            <table className="table box-table user_table">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={MasterChecked}
                                                id="mastercheck"
                                                onChange={(e) => onMasterCheck(e)}
                                            />
                                        </th>{/* 
                                        <th onClick={onSortChange} scope="col" className="id">
                                            <span className="d-installed">  <BsArrowUp /></span>
                                        </th> */}
                                        {/* <th scope="col" className="serialT">ID</th> */}
                                        <th scope="col" className="ownerT">Timestamp</th>
                                        <th scope="col" className="serialT">Component Name</th>
                                        <th scope="col" className="FirmwareT">User</th>
                                        <th scope="col" className="statusT">Event Category</th>
                                        <th scope="col" className="Energy_T">Event Text</th>
                                        <th scope="col" className="ownerT">Event Level</th>
                                        <th scope="col" className="ownerT">Event Origin Path</th>
                                        <th scope="col" className="ownerT">Event Origin Line</th>
                                        <th scope="col" className="ownerT">Event Metadata</th>
                                        <th scope="col" className="ownerT">Component ID</th>
                                        <th scope="col" className="ownerT">Environment ID</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        events.length && !isFetching ?
                                            events.map((event, index) => <SingleRow key={index} onItemCheck={onItemCheck} event={event} />) :
                                            <tr className="router-loading-spinner">
                                                <td>
                                                    <div className="router-loading-spinner">
                                                        <TailSpin width={50} height={50} color="#002060" />
                                                    </div>
                                                </td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="pagination-content">
                    <ul>

                        {/*  {
                            [...Array(pageCount).keys()].map(avc => <li className={currentPage === avc && "active"} onClick={() => setCurrentPage(avc)}>{avc + 1}</li>)
                        }
                        {
                            currentPage !== pageCount - 1 && <li onClick={() => setCurrentPage(currentPage < pageCount ? currentPage + 1 : currentPage)}><BsChevronDoubleRight /></li>
                        } */}
                        <Stack spacing={2}>
                            <Pagination onChange={(e, pn) => setCurrentPage(pn - 1)} count={pageCount} />
                        </Stack>
                    </ul>
                    <div className="pageRight">
                        <span>Showing {events?.length} of {totalDataCount} items</span>
                    </div>
                </div>
            </div>

        </>
    )
}


const SingleRow = ({ event, onItemCheck }) => {
    return (
        <tr className={event.selected ? "selected" : ""}>
            <th scope="row">
                <input
                    type="checkbox"
                    checked={event.selected}
                    className="form-check-input"
                    id="rowcheck{user.id}"
                    onChange={(e) => onItemCheck(e, event)}
                />
            </th>
            <td className="td_event-timestamp"><span>{event.timestamp}</span></td>
            {/* <td className="td_event-id"><span>{event.ID}</span></td> */}
            <td className="wallboxSerial"><span>{event.Component_Name}</span></td>
            <td className="reset_pass td_event-id-user"><span>{event.User || "unknown"}</span></td>
            <td className="td_event-category"><span>{event.Event_Category || "Not Found"}</span></td>
            <td className="td_event-text"><span>{event.Event_Text || "Not Found"}</span></td>
            <td className="FirmwareVersion"><span>{event.Event_Level || "Not Found"}</span></td>
            <td className="FirmwareVersion"><span>{event.Event_Origin_Path || "Not Found"}</span></td>
            <td className="FirmwareVersion"><span>{event.Event_Origin_Line || "Not Found"}</span></td>
            <td className="FirmwareVersion"><span>{event.Event_Metadata}</span></td>
            <td className="FirmwareVersion"><span>{event.Environment_ID}</span></td>
            <td className="FirmwareVersion"><span>{event.Component_ID || "Not Found"}</span></td>
            {/*  
            
            
            
            <td className="wallboxes_tt">
                <span className="">

                    {
                        user.wallboxes.length === 1 && <button className="box_dot">  {user.wallboxes} </button>
                    }

                    {user.wallboxes.length > 1 && (
                        <button className="box_dot">
                            <img alt="" src={dot3} /> {user.wallboxes.length} Wallboxes
                        </button>
                    )}
                </span>
            </td>
            <td className="konten">
                <span>
                    <button className="konten_btn">Geschäftlich</button>
                    <button className="konten_btn">Privat</button>
                </span>
            </td>
            <td className="Search">
                <span>
                    <button><img alt="" src={search} /></button>
                </span>
            </td> */}
        </tr>
    );
};



export default EventRows