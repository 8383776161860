import React, { useEffect, useState } from "react";
import NavBar from "../NavBar/NavBar";
import "./StatisticsDashboard.scss";
import EventsDashboardRows from "./EventsDashboardRows";
import axios from "axios";
import { TailSpin } from "react-loader-spinner";
import FirmwareDashboard from "./FirmwareDashboard";
import FirmwarePieChart from "./FirmwarePieChart";
import PowerConsuptionGraph from "./PowerConsuptionGraph";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";

const StatisticsDashboard = () => {
  document.title = "Statistics Dashboard - POWERGrid Cloud";

  const history = useHistory();
  const onlineWallboxesSearch = () => {
    history.push("/", {online_wallbox: true});
  }

  const [events, setEvents] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [totalDataCount, setTotalDataCount] = useState();
  const [orderWay, setOrderWay] = useState("desc");
  const [displaySize, setDisplaySize] = useState(20);
  const [searchValue, setSearchValue] = useState("");
  const [isNotFound, setIfNotFound] = useState(false);
  const [eventType, setEventType] = useState(1);
  const [allEventsTotalData, setAllEventsTotalData] = useState();
  const [allAndroidTotalData, setAllAndroidTotalData] = useState();
  const [allIOSTotalData, setAllIOSTotalData] = useState();
  const [eventTypes01, setEventTypes01] = useState(1);
  const [onlineWallboxes, setOnlineWallboxes] = useState();

  let cancelToken;

  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel("Cancelling the previous req");
  }

  cancelToken = axios.CancelToken.source();

  function allEventsData() {
    axios
      .get(
        `http://116.203.191.150:3000/get_events?limit=${displaySize}&order=timestamp.${orderWay}&limit=${displaySize}&offset=${
          currentPage * displaySize
        }&${searchValue && `ID=ilike.*${searchValue}`}`,
        {
          headers: {
            Prefer: "count=exact",
          },
        },
        {
          cancelToken: cancelToken.token,
        }
      )
      .then((response) => {
        if (response.headers) {
          try {
            let totalItems = response?.headers["content-range"]?.split("-")[1];
            totalItems = totalItems.split("/")[1];
            if (eventTypes01 == 1) {
              setPageCount(Math.ceil(totalItems / displaySize));
              setTotalDataCount(totalItems);
            }
            setAllEventsTotalData(totalItems);
          } catch (error) {
            console.log(error);
          }
        }
        const events = response.data;
        if (!events.length) {
          setIfNotFound(true);
        } else {
          setIfNotFound(false);
        }

        if (events) {
          events.forEach((element) => {
            element.selected = false;
          });
          setEvents(events);
        }
      })
      .catch((error) => {
        if (currentPage !== 0) {
          setCurrentPage(0);
        }
        console.error("error found", error);
      })
      .then(() => {
        setIsFetching(false);
      });
  }

  function androidData() {
    axios
      .get(
        `http://116.203.191.150:3000/get_events?Event_Metadata?=not.fts.*iOS&limit=${displaySize}&offset=${
          currentPage * displaySize
        }&order=timestamp.${orderWay}`,
        {
          headers: {
            Prefer: "count=exact",
          },
        },
        {
          cancelToken: cancelToken.token,
        }
      )
      .then((response) => {
        if (response.headers) {
          try {
            let totalItems = response?.headers["content-range"]?.split("-")[1];
            totalItems = totalItems.split("/")[1];
            if (eventTypes01 == 2) {
              setPageCount(Math.ceil(totalItems / displaySize));
              setTotalDataCount(totalItems);
            }
            setAllAndroidTotalData(totalItems);
          } catch (error) {
            console.log(error);
          }
        }
        const events = response.data;
        if (!events.length) {
          setIfNotFound(true);
        } else {
          setIfNotFound(false);
        }

        if (events) {
          events.forEach((element) => {
            element.selected = false;
          });
          setEvents(events);
        }
      })
      .catch((error) => {
        if (currentPage !== 0) {
          setCurrentPage(0);
        }
        console.error("error found", error);
      })
      .then(() => {
        setIsFetching(false);
      });
  }

  function IOSData() {
    axios
      .get(
        `http://116.203.191.150:3000/get_events?Event_Metadata?=fts.*iOS&limit=${displaySize}&offset=${
          currentPage * displaySize
        }&order=timestamp.${orderWay}`,
        {
          headers: {
            Prefer: "count=exact",
          },
        },
        {
          cancelToken: cancelToken.token,
        }
      )
      .then((response) => {
        if (response.headers) {
          try {
            let totalItems = response?.headers["content-range"]?.split("-")[1];
            totalItems = totalItems.split("/")[1];
            if (eventTypes01 == 3) {
              setPageCount(Math.ceil(totalItems / displaySize));
              setTotalDataCount(totalItems);
            }
            setAllIOSTotalData(totalItems);
          } catch (error) {
            console.log(error);
          }
        }
        const events = response.data;
        if (!events.length) {
          setIfNotFound(true);
        } else {
          setIfNotFound(false);
        }

        if (events) {
          events.forEach((element) => {
            element.selected = false;
          });
          setEvents(events);
        }
      })
      .catch((error) => {
        if (currentPage !== 0) {
          setCurrentPage(0);
        }
        console.error("error found", error);
      })
      .then(() => {
        setIsFetching(false);
      });
  }

  const onlineWallbox = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/firmware/device_list`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("access_token"),
      },
    })
    .then(function(response){
        setOnlineWallboxes(response.data.online_devices);
    })
    .catch(function(error){
      console.log(error);
    })
  };

  useEffect(() => {
    allEventsData();
    androidData();
    IOSData();
    onlineWallbox();
  }, [eventTypes01]);

  const getFilterEvents = (eType) => {
    if (eType === 1) {
      allEventsData();
      setEventTypes01(1);
    }
    if (eType === 2) {
      androidData();
      setEventTypes01(2);
    }
    if (eType === 3) {
      IOSData();
      setEventTypes01(3);
    }
  };

  useEffect(() => {
    if (eventType === 1) {
      getFilterEvents(1);
    }
    if (eventType === 2) {
      getFilterEvents(2);
    }
    if (eventType === 3) {
      getFilterEvents(3);
    }
  }, [currentPage, orderWay, displaySize, searchValue]);

  const tabData = ["Overview", "Power Consuption", "Events"];
  const [active, setActive] = useState(tabData[0]);

  return (
    <>
      <NavBar />
      <main className="wrapper">
        <div className="page-content">
          <div className="wallbox-area">
            <div className="pages-title">
              <div className="h2">Statistics Dashboard</div>
              <span>View All IOS/Android Statistics</span>
            </div>
          </div>
          <div>
            <div className="tabModule">
              <ul className="tabModule__head">
                {tabData.map((items, i) => (
                  <li className="tabModule__item" key={i}>
                    <span
                      className={
                        active === items
                          ? "tabModule__link activeTab"
                          : "tabModule__link"
                      }
                      onClick={() => setActive(items)}
                    >
                      {items}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
            {active === "Overview" ? (
              <>
                <div className="buttonGroup mt-60">
                <button onClick={()=>onlineWallboxesSearch()}>Online Wallboxes ({onlineWallboxes})</button>
                </div>
                <div className="graphModule mt-30">
                  <div className="graphModule__innerPart">
                    <div className="graphBlock">
                      <FirmwarePieChart />
                    </div>
                    <div className="graphDataList">
                      <FirmwareDashboard />
                    </div>
                  </div>
                </div>
              </>
            ) : active === "Power Consuption" ? (
              <div className="graphModule mt-60">
                <div className="graphModule__inner graphModule__inner--single">
                  <h6 className="graph-title">Power Consuption</h6>
                  <div className="graphModule__graphBox">
                    <PowerConsuptionGraph />
                    <p className="graphModule__foottext">Date/Time/Month</p>
                  </div>
                </div>
              </div>
            ) : active === "Events" ? (
              <div className="row">
                <div className="wallbox-tabl">
                  {isFetching == true ? (
                    <div className="router-loading-spinner">
                      <TailSpin width={50} height={50} color="#002060" />
                    </div>
                  ) : isNotFound == true ? (
                    <h5 className="text-center mt-5">Result Not found...</h5>
                  ) : (
                    <EventsDashboardRows
                      events={events}
                      pageCount={pageCount}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      isFetching={isFetching}
                      totalDataCount={totalDataCount}
                      displaySize={displaySize}
                      allEventsTotalData={allEventsTotalData}
                      allAndroidTotalData={allAndroidTotalData}
                      allIOSTotalData={allIOSTotalData}
                      eventType={eventType}
                      setEventType={setEventType}
                      getFilterEvents={getFilterEvents}
                    />
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default StatisticsDashboard;
