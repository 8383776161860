import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';
import filterAdd from "../../images/icons/filter-add.png";
import userI from "../../images/icons/user.png";
import rolesI from "../../images/icons/roles.png";
import ppermiI from "../../images/icons/ppermi.png";
import { AiOutlineSearch, AiOutlinePlus } from "react-icons/ai";
import NavBar from "../NavBar/NavBar";
import "./User.scss";
import { Link } from "react-router-dom";
import PermissionsRow from "./PermissionsRow";
import axios from "axios";
import Cookies from 'js-cookie'
import MyVerticallyCenteredModal from "./SignUpModal";
import AddPermissionForm from "./AddPermission";

const Permissions = () => {
    const [permissions, setPermissions] = useState([])
    const [isFetcing, setIsFetching] = useState(true)
    const [modalShow, setModalShow] = useState(false);
    const [sorting, setSorting] = useState("ID")
    const [searchValue, setSearchValue] = useState("")
    const [permissionModalShow, setPermissionModalShow] = useState(false)
    // const [permissionList, getPermissionList] = useState([])

    const getPermissionList = () => {
        axios.get(`${process.env.REACT_APP_USER_BACKEND_API_URL}/permissions/permissions_table?search_string=${searchValue}&order=${sorting}`, {
            headers: {
                Authorization: "Bearer " + Cookies.get("access_token")
            }
        })
            .then(function (response) {
                if (response.data) {
                    setPermissions(response.data)
                }
            })
            .catch(function (error) {
                setIsFetching(false)
                console.log(error);
            })
            .then(() => {
                setIsFetching(false)
            })
    }

    // useEffect(() => {
    //     setIsFetching(true)
    // })

    const handlePermissionSubmit = (permissionData) => {
        const formdata = new FormData();
        formdata.append("App", permissionData.App)
        formdata.append("AddIn", permissionData.AddIn)
        formdata.append("Component", permissionData.Component)
        formdata.append("PermissionName", permissionData.PermissionName)
        formdata.append("PermissionValue", permissionData.PermissionValue)
        axios.post(`${process.env.REACT_APP_USER_BACKEND_API_URL}/permissions`, formdata, {
            headers: {
                Authorization: "Bearer " + Cookies.get("access_token")
            }
        })
            .then(function (response) {
                if (response.data) {
                    getPermissionList();
                    setPermissionModalShow(false);
                }
            })
            .catch(function (error) {
                setIsFetching(false)
                console.log(error);
            })
            .then(() => {
                setIsFetching(false)
            }) 
    }

    useEffect(() => {
        setIsFetching(true);
        getPermissionList();
    }, [sorting, searchValue])

    return (
        <>
            <NavBar />
            <main className="wrapper">
                <div className="page-content">
                    <div className="wallbox-area">
                        <div className="pages-title">
                            <div className="h2">All Permissions</div>
                            <span>View all permissions in one table</span>
                        </div>
                        <div className="user__content">
                            <div className="user__data">
                                <ul className="panel_section">
                                    <li>
                                        <span>Panel Selection</span>
                                    </li>
                                    <li>
                                        <Link to="/users" ><img alt="" src={userI} /> Users</Link>
                                    </li>
                                    <li>
                                        <Link to="/roles"><img alt="" src={rolesI} /> Roles</Link>
                                    </li>
                                    <li>
                                        <Link to="/permissions" className="active"><img alt="" src={ppermiI} /> Permissions</Link>
                                    </li>
                                    <li className="additional_tt">
                                        <span>Additional Functions</span>
                                    </li>
                                    <li>
                                        <button onClick={() => setModalShow(true)}><AiOutlinePlus />Add new User</button>
                                    </li>
                                </ul>
                            </div>
                            <div className="user__data">
                                <div className="search-filter">
                                    <form action="">
                                        <input onChange={e => setSearchValue(e.target.value)} type="search" placeholder="Search Permissions..." />
                                        <button type="submit"><AiOutlineSearch /></button>
                                    </form>
                                    <div className="sort-date">
                                        Sort by:
                                        <Form.Select onChange={(e) => setSorting(e.target.value)} aria-label="Default select example">
                                            <option value="ID">ID</option>
                                            <option value="Name">Name</option>
                                        </Form.Select>
                                    </div>
                                    <div className="filter">
                                        Filter <img alt="" src={filterAdd} />
                                    </div>
                                    <button
                                        className="btn-yellpow"
                                        onClick={() => {
                                            // setChargeTransactionEmail(initState);
                                            setPermissionModalShow(true);
                                        }}
                                        >
                                    Add Permission
                                    </button>
                                    {/*  <ul className="sort_check">
                                        <li>
                                            <div className="check">
                                                <span className="close">&#10006;</span>
                                                <span>Type: create</span>
                                            </div>
                                        </li>
                                    </ul> */}
                                </div>
                                <div className="wallbox-tabl">
                                    <PermissionsRow
                                        permissions={permissions}
                                        isFetching={isFetcing}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <MyVerticallyCenteredModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                />
                <AddPermissionForm
                    show={permissionModalShow}
                    onHide={() => setPermissionModalShow(false)}
                    // data={chargeTransactionEmail}
                    callBackSubmit={handlePermissionSubmit}
                />
            </main>
        </>
    )
}

export default Permissions