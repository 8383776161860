import axios from "axios";
import Cookies from 'js-cookie'


export default function getUserPermissions(){
    axios.get(`${process.env.REACT_APP_USER_BACKEND_API_URL}/users/user_permissions`, {
        headers: {
            Authorization: "Bearer " + Cookies.get("access_token"),
        },
    }).then(function (response) {
        const access_token = Cookies.get("access_token")
        axios.get(`${process.env.REACT_APP_USER_BACKEND_API_URL}/users/current_user/${access_token}`, {
            headers: {
                Authorization: "Bearer " + Cookies.get("access_token"),
            },
        }).then(function(user) {
            let email = user?.data;
            Cookies.set("user_email", email)
            if (email && response?.data){
                if (response?.data[email]){
                    localStorage.setItem("user_permissions", response?.data[email])
                } else {
                    localStorage.setItem("user_permissions", [])
                }
            }
        }).catch(function (error) {
            console.log(error);
            localStorage.setItem("user_permissions", [])
        })
    }).catch(function (error) {
        console.log(error);
        localStorage.setItem("user_permissions", [])
    });
};

export function getPermission(permission){
    return localStorage.getItem("user_permissions")?.split(",")?.includes(permission) ? true : false
}

export function getUserInfo(){
    axios.get(`${process.env.REACT_APP_USER_BACKEND_API_URL}/users/user_info`, {
    headers: {
        authorization: "Bearer " + Cookies.get("access_token")
    }
    })
    .then(response => {
        if (response.data) {
            const user_data = response?.data?.data;
            // Cookies.set("user_image", user_data.image)
            localStorage.setItem("user_image", user_data.image)
        }
    })
    .catch(error => {
        console.error("Got Error", error)
    })
  };