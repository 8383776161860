import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';


import NavBar from "../NavBar/NavBar";
import filterAdd from "../../images/icons/filter-add.png";
import { AiOutlineSearch } from "react-icons/ai";
import "./ChargeEvents.scss";
import ChargeEventRow from "./ChargeEventRow";
import axios from "axios";
import Cookies from "js-cookie";

const ChargeEvents = () => {
    document.title = "Charge Event - POWERGrid Cloud"

    const [chargingEvents, setChargingEvents] = useState([])
    const [isFetcing, setIsFetching] = useState(true)
    const [totalChargeEventsCount, setTotalChargeEventsCount] = useState(0)
    const [pageCount, setPageCount] = useState(1)
    const [currentPage, setCurrentPage] = useState(1)
    const [sorting, setSorting] = useState("created_at")
    const [orderWay, setOrderWay] = useState("desc")
    const [displaySize, setDisplaySize] = useState(20)
    const [searchValue, setSearchValue] = useState("")
    const [isNotFound, setIfNotFound] = useState(false)

    const onOrderChange = () => {
        if (orderWay === "desc") {
            setOrderWay("asc")
        } else {
            setOrderWay("desc")
        }
    }

    let cancelToken;

    if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Cancelling the previous req")
    }
    cancelToken = axios.CancelToken.source()
    useEffect(() => {
        setIsFetching(true)
        /*  http://116.203.191.150:5013 */
        axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/chargeEvent?page=${currentPage}&page_size=${displaySize}&order=${sorting}&order_way=${orderWay}&search_string=${searchValue}`, {
            headers: {
          Authorization: "Bearer " + Cookies.get("access_token"),
        },
        }, {
            cancelToken: cancelToken.token
        }
        )
            .then(response => {

                const chargeEvents = response.data.data
                const totalWallbox = response?.data.total_count
                setTotalChargeEventsCount(totalWallbox)
                setPageCount(Math.ceil(totalWallbox / displaySize))

                if (chargeEvents) {

                    if (totalWallbox === 0) {
                        setIfNotFound(true)
                    } else {
                        setIfNotFound(false)
                    }

                    if (chargeEvents.length === 0 && currentPage !== 1) {
                        setCurrentPage(1)
                    }

                    if (chargeEvents) {
                        setIsFetching(false)
                        chargeEvents.forEach(element => {
                            element.selected = false
                        });
                        setChargingEvents(chargeEvents)
                    }
                }
            })
            .catch(error => {
                console.log(error)
            })
    }, [currentPage, sorting, orderWay, displaySize, searchValue])

    return (
        <>
            <NavBar />
            <main className="wrapper">
                <div className="page-content">
                    <div className="wallbox-area">
                        <div className="pages-title">
                            <div className="h2">Charging Events</div>
                            <span>Alle Ladungen in einer Übersicht </span>
                        </div>
                        <div className="search-filter">
                            <form onSubmit={e => e.preventDefault()}>
                                <input onChange={e => setSearchValue(e.target.value)} type="text" placeholder="Search Charging Events" />
                                <button type="submit"><AiOutlineSearch /></button>
                            </form>
                            <div className="sort-date">
                                Sort by:
                                <Form.Select onChange={(e) => setSorting(e.target.value)} aria-label="Default select example">
                                    <option value="created_at">Created At</option>
                                    <option value="id">ID</option>
                                    {/* <option value="serial_number">Serial Number</option> */}
                                </Form.Select>
                            </div>

                            <div className="sort-date">
                                Display size:
                                <Form.Select defaultValue={20} onChange={e => setDisplaySize(e.target.value)} aria-label="Default select example">
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                </Form.Select>
                            </div>

                            <div className="sort-date">
                                Order way:
                                <Form.Select defaultValue={"Descending"} onChange={onOrderChange} aria-label="Default select example">
                                    <option>Ascending</option>
                                    <option>Descending</option>
                                </Form.Select>
                            </div>
                            <div className="filter">
                                Filter <img alt="" src={filterAdd} />
                            </div>
                            {/* <ul className="sort_check">
                                <li>
                                    <div className="check">
                                        <span className="close">&#10006;</span>
                                        <span>User: Jonas Mossler</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="check">
                                        <span className="close">&#10006;</span>
                                        <span>Konto: Geschäftlich</span>
                                    </div>
                                </li>
                            </ul> */}
                        </div>
                        <div className="wallbox-tabl">
                            {
                                isNotFound && !isFetcing ?
                                    <h5 className="text-center mt-5">Result Not found...</h5>
                                    :
                                    <ChargeEventRow
                                        isFetcing={isFetcing}
                                        setIsFetching={setIsFetching}
                                        totalChargeEventsCount={totalChargeEventsCount}
                                        setTotalChargeEventsCount={setTotalChargeEventsCount}
                                        pageCount={pageCount}
                                        setPageCount={setPageCount}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        chargingEvents={chargingEvents}
                                        setAllWallBoxes={setChargingEvents}
                                        onOrderChange={onOrderChange}
                                        orderWay={orderWay}
                                        displaySize={displaySize}
                                    />
                            }
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};


export default ChargeEvents;
