import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';
import filterAdd from "../../images/icons/filter-add.png";
import userI from "../../images/icons/user.png";
import rolesI from "../../images/icons/roles.png";
import ppermiI from "../../images/icons/ppermi.png";
import { AiOutlineSearch, AiOutlinePlus } from "react-icons/ai";
import NavBar from "../NavBar/NavBar";
import "./User.scss";
import { Link } from "react-router-dom";
import RolesRows from "./RolesRows";
import axios from "axios";
import Cookies from 'js-cookie'
import MyVerticallyCenteredModal from "./SignUpModal";
import { useHistory, useLocation } from "react-router-dom";

const Roles = () => {
    document.title = "User - POWERGrid Cloud"

    const [modalShow, setModalShow] = useState(false);
    const [roles, setRoles] = useState([])
    const [isFetcing, setIsFetching] = useState(true)

    const location = useLocation();
    const permissionData = location.state;

    useEffect(() => {
        let role_page_url = `${process.env.REACT_APP_USER_BACKEND_API_URL}/roles/role_page`
        if (permissionData?.permissionId){
            role_page_url = role_page_url + `?permission_id=${permissionData.permissionId}`
        }
        axios.get(role_page_url, {
            headers: {
                Authorization: "Bearer " + Cookies.get("access_token")
            }
        })
            .then(function (response) {
                if (response.data) {
                    setRoles(response.data)
                }
            })
            .catch(function (error) {
                setIsFetching(false)
                console.log(error);
            })
            .then(() => {
                setIsFetching(false)
            })
    }, [])

    return (
        <>
            <NavBar />
            <main className="wrapper">
                <div className="page-content">
                    <div className="wallbox-area">
                        <div className="pages-title">
                            <div className="h2">All Roles</div>
                            <span>View all roles in one table</span>
                        </div>
                        <div className="user__content">
                            <div className="user__data">
                                <ul className="panel_section">
                                    <li>
                                        <span>Panel Selection</span>
                                    </li>
                                    <li>
                                        <Link to="/users" ><img alt="" src={userI} /> Users</Link>
                                    </li>
                                    <li>
                                        <Link to="/roles" className="active"><img alt="" src={rolesI} /> Roles</Link>
                                    </li>
                                    <li>
                                        <Link to="/permissions" ><img alt="" src={ppermiI} /> Permissions</Link>
                                    </li>
                                    <li className="additional_tt">
                                        <span>Additional Functions</span>
                                    </li>
                                    <li>
                                        <button onClick={() => setModalShow(true)}><AiOutlinePlus />Add new User</button>
                                    </li>
                                </ul>
                            </div>
                            <div className="user__data">
                                <div className="search-filter">
                                    <form action="">
                                        <input type="search" placeholder="Search Roles..." />
                                        <button type="submit"><AiOutlineSearch /></button>
                                    </form>
                                    <div className="sort-date">
                                        Sort by:
                                        <Form.Select aria-label="Default select example">
                                            <option>Date Installed</option>
                                            <option value="1">Start Installed</option>
                                            <option value="2">Timestamp</option>
                                            <option value="3">Start Installed</option>
                                        </Form.Select>
                                    </div>
                                    <div className="filter">
                                        Filter <img alt="" src={filterAdd} />
                                    </div>
                                    {/*  <ul className="sort_check">
                                        <li>
                                            <div className="check">
                                                <span className="close">&#10006;</span>
                                                <span>User: 5</span>
                                            </div>
                                        </li>
                                    </ul> */}
                                </div>
                                <div className="wallbox-tabl">
                                    <RolesRows
                                        roles={roles}
                                        isFetcing={isFetcing}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <MyVerticallyCenteredModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                />
            </main>
        </>
    )
}

export default Roles