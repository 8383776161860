import React, { useState } from 'react';
import search from "../../images/icons/search.png"
import dot3 from "../../images/icons/dot3.png"

import { BsArrowUp, BsChevronDoubleRight } from "react-icons/bs";

import { Link } from "react-router-dom";
import { TailSpin } from 'react-loader-spinner';
/* const Users = [
    {
        id: 100,
        selected: false,
        name: "Admin",
        create: 19,
        read: 17,
        update: 27,
        deletes: 27,
        use: 15,
        userCount: 5,

    },
    {
        id: 101,
        selected: false,
        name: "Admin",
        create: 12,
        read: 17,
        update: 4,
        deletes: 14,
        use: 9,
        userCount: 16,

    },
    {
        id: 102,
        selected: false,
        name: "WEG-Admin",
        create: 19,
        read: 17,
        update: 27,
        deletes: 27,
        use: 15,
        userCount: 5,

    },
    {
        id: 103,
        selected: false,
        name: "Installateur",
        create: 19,
        read: 17,
        update: 27,
        deletes: 27,
        use: 15,
        userCount: 5,

    },
    {
        id: 104,
        selected: false,
        name: "Standard User",
        create: 19,
        read: 17,
        update: 27,
        deletes: 27,
        use: 15,
        userCount: 5,
    },
    {
        id: 105,
        selected: false,
        name: "PV Permissions",
        create: 19,
        read: 17,
        update: 27,
        deletes: 27,
        use: 15,
        userCount: 5,

    },
    {
        id: 106,
        selected: false,
        name: "Advanced Statistics",
        create: 19,
        read: 17,
        update: 27,
        deletes: 27,
        use: 15,
        userCount: 5,
    },
    {
        id: 107,
        selected: false,
        name: "Load Management",
        create: 0,
        read: 17,
        update: 27,
        deletes: 27,
        use: 15,
        userCount: 5,

    },
    {
        id: 108,
        selected: false,
        name: "Admin",
        create: 0,
        read: 17,
        update: 27,
        deletes: 27,
        use: 15,
        userCount: 5,

    },
    {
        id: 109,
        selected: false,
        name: "Admin",
        create: 0,
        read: 17,
        update: 27,
        deletes: 27,
        use: 15,
        userCount: 5,

    },
    {
        id: 110,
        selected: false,
        name: "Admin",
        create: 19,
        read: 17,
        update: 27,
        deletes: 0,
        use: 15,
        userCount: 5,

    },
    {
        id: 111,
        selected: false,
        name: "Admin",
        create: 19,
        read: 17,
        update: 27,
        deletes: 27,
        use: 15,
        userCount: 5,

    },
    {
        id: 112,
        selected: false,
        name: "Admin",
        create: 19,
        read: 17,
        update: 27,
        deletes: 27,
        use: 15,
        userCount: 5,

    },
    {
        id: 113,
        selected: false,
        name: "Admin",
        create: 19,
        read: 17,
        update: 27,
        deletes: 27,
        use: 15,
        userCount: 5,
    },
    {
        id: 114,
        selected: false,
        name: "Admin",
        create: 19,
        read: 17,
        update: 27,
        deletes: 27,
        use: 15,
        userCount: 5,

    },
    {
        id: 115,
        selected: false,
        name: "Admin",
        create: 19,
        read: 17,
        update: 27,
        deletes: 27,
        use: 15,
        userCount: 5,

    },
    {
        id: 116,
        selected: false,
        name: "Admin",
        create: 19,
        read: 17,
        update: 27,
        deletes: 27,
        use: 15,
        userCount: 5,

    },
    {
        id: 117,
        selected: false,
        name: "Admin",
        create: 19,
        read: 17,
        update: 27,
        deletes: 27,
        use: 15,
        userCount: 5,

    },


]; */

const sortedList = (data, order, item) => {
    if (order === 'up') {
        return data.sort((a, b) => (a[item] ? 1 : -1));
    }
    if (order === 'down') {
        return data.sort((a, b) => (a[item] ? -1 : 1));
    }
}

const RolesRows = ({ roles, isFetcing }) => {

    const [List, setList] = useState(roles)
    const [MasterChecked, setMasterChecked] = useState(false)
    const [SelectedList, setSelectedList] = useState([])
    const [currentSort, setCurrentSort] = useState('up')

    function onMasterCheck(e) {
        let tempList = List;
        // Check/ UnCheck All Items
        tempList.map((user) => (user.selected = e.target.checked));

        //Update State
        setMasterChecked(e.target.checked)
        setList(tempList)
        setSelectedList(List.filter((e) => e.selected))
    }

    function onItemCheck(e, item) {
        let tempList = List;
        tempList.map((user) => {
            if (user.id === item.id) {
                user.selected = e.target.checked;
            }
            return user;
        });

        //To Control Master Checkbox State
        const totalItems = List.length;
        const totalCheckedItems = tempList.filter((e) => e.selected).length;

        // Update State
        setMasterChecked(totalItems === totalCheckedItems)
        setList(tempList)
        setSelectedList(List.filter((e) => e.selected))
    }

    function getSelectedRows() {
        setSelectedList(List.filter((e) => e.selected))
    }


    const onSortChange = () => {
        let nextSort;

        if (currentSort === 'down') {
            nextSort = 'up'
        }
        else if (currentSort === 'up') {
            nextSort = 'down';
        }
        setCurrentSort(nextSort)
    };

    return (
        <>
            <div className="table-componet">
                <div className="row">
                    <div className="col-md-12">
                        <div className="tab__content">
                            <table className="table box-table">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={MasterChecked}
                                                id="mastercheck"
                                                onChange={(e) => onMasterCheck(e)}
                                            />
                                        </th>
                                        <th onClick={onSortChange} scope="col" className="id">
                                            <span className="d-installed">ID  <BsArrowUp /></span>
                                        </th>
                                        <th scope="col" className="serialT">Role Name</th>
                                        <th scope="col" className="FirmwareT">Permissions</th>
                                        <th scope="col" className="ownerT">User Count </th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {isFetcing ?
                                    <tr className="router-loading-spinner">
                                        <td>
                                            <div className="router-loading-spinner">
                                                <TailSpin width={50} height={50} color="#002060" />
                                            </div>
                                        </td>
                                    </tr>
                                        :
                                        roles.map((user, index) => (
                                            <SingleRow
                                                key={index}
                                                onItemCheck={onItemCheck}
                                                role={user}
                                            />
                                        ))}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="pagination-content">
                    <ul>
                        {/* <li>1</li> */}
                        <li className="active">1</li>
                        {/* <li>3</li>
                        <li>4</li>
                        <li><BsChevronDoubleRight /></li> */}
                    </ul>
                    <div className="pageRight">
                        <span>Showing {roles.length} of {roles.length} items</span>
                    </div>
                </div>
            </div>

        </>
    );
};


const SingleRow = ({ role, onItemCheck, roles }) => {
    const { Role, Users } = role
    let insertPermissions = 0
    let readPermission = 0
    let deletePermission = 0
    let updatePermission = 0
    let usePermission = 0

    if (role?.Permissions[0]?.Names) {
        role.Permissions[0].Names.forEach(element => {
            switch (element) {
                case "View":
                    readPermission = readPermission + 1
                    break;

                case "Insert":
                    insertPermissions = insertPermissions + 1
                    break;

                case "Delete":
                    deletePermission = deletePermission + 1
                    break;

                case "Update":
                    updatePermission = updatePermission + 1
                    break;

                case "Use":
                    usePermission = usePermission + 1
                    break;

                default:
                    break;
            }
        });
    }

    return (
        <tr className={role.selected ? "selected" : ""}>
            <th scope="row">
                <input
                    type="checkbox"
                    checked={role.selected}
                    className="form-check-input"
                    id="rowcheck{user.id}"
                    onChange={(e) => onItemCheck(e, role)}
                />
            </th>
            <td><span>N/A</span></td>
            <td className="wallboxSerial role_name"><span><Link to="#">{Role}</Link></span></td>
            <td className="permissions">
                <span>
                    <button className="create">{insertPermissions} create permissions</button>
                    <button className="read">{readPermission} read permissions</button>
                    <button className="update">{updatePermission} update permissions</button>
                    <button className="deletes">{deletePermission} delete permissions</button>
                    <button className="use">{usePermission} use permissions</button>
                </span>
            </td>
            <td className="box_dot_tt">
                <span className="">
                    <button className="box_dot">
                        <img alt="" src={dot3} /> {Users[0]?.Names?.length} User
                    </button>
                </span>
            </td>
            <td className="Search">
                <span>
                    <button><img alt="" src={search} /></button>
                </span>
            </td>
        </tr>
    )
}

export default RolesRows;