import React, { useState } from 'react';
import { BsChevronDoubleRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import { TailSpin } from 'react-loader-spinner';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';



const ChargeEventRow = ({ chargingEvents, isFetcing, pageCount, totalChargeEventsCount, currentPage, setCurrentPage, displaySize }) => {
    const [List, setList] = useState(chargingEvents)
    const [MasterChecked, setMasterChecked] = useState(false)
    const [SelectedList, setSelectedList] = useState([])
    const [currentSort, SetCurrentSort] = useState('up')



    function onMasterCheck(e) {
        let tempList = List;
        // Check/ UnCheck All Items
        tempList.map((user) => (user.selected = e.target.checked));

        //Update State
        setMasterChecked(e.target.checked)
        setList(tempList)
        setSelectedList(List.filter((e) => e.selected))
    }

    function onItemCheck(e, item) {
        let tempList = List;
        tempList.map((user) => {
            if (user.id === item.id) {
                user.selected = e.target.checked;
            }
            return user;
        });

        //To Control Master Checkbox State
        const totalItems = List.length;
        const totalCheckedItems = tempList.filter((e) => e.selected).length;

        // Update State
        setMasterChecked(totalItems === totalCheckedItems)
        setList(tempList)
        setSelectedList(List.filter((e) => e.selected))
    }

    function getSelectedRows() {
        setSelectedList(List.filter((e) => e.selected))
    }

    const onSortChange = () => {
        let nextSort;

        if (currentSort === 'down') {
            nextSort = 'up'
        }
        else if (currentSort === 'up') {
            nextSort = 'down'
        }
        SetCurrentSort(nextSort)

    };

    return (
        <>
            <div className="table-componet">
                <div className="row">
                    <div className="col-md-12">
                        <div className="tab__content">
                            <table className="table box-table">
                                <thead>
                                    <tr>
                                        <th scope="col" className="serialT">start</th>
                                        <th onClick={onSortChange} scope="col" className="id">
                                            <span className="d-installed">Energy</span></th>
                                        <th scope="col" className="FirmwareT">Meter Reading</th>
                                        <th scope="col" className="Energy_T">Duration </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        chargingEvents.length && !isFetcing ? chargingEvents.map((event, index) => <SingleRow
                                            key={index}
                                            event={event}
                                            onItemCheck={onItemCheck}
                                        />) :
                                        // <div className="router-loading-spinner">
                                        //     <TailSpin width={50} height={50} color="#002060" />
                                        // </div>
                                        <tr>
                                            <td>
                                                <h4 className="result-text">Result Not Found</h4>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {/* <div className="pagination-content">
                    <ul>
                         {
                            [...Array(pageCount).keys()].map(avc => <li className={currentPage === avc + 1 && "active"} onClick={() => setCurrentPage(avc + 1)}>{avc + 1}</li>)
                        }
                        {
                            currentPage !== pageCount && <li onClick={() => setCurrentPage(currentPage < pageCount ? currentPage + 1 : currentPage)}><BsChevronDoubleRight /></li>
                        }
                        <Stack spacing={2}>
                            <Pagination onChange={(e, pn) => setCurrentPage(pn - 1)} count={pageCount} />
                        </Stack>
                    </ul>

                    <div className="pageRight">
                        <span>Showing {totalChargeEventsCount < displaySize ? totalChargeEventsCount : displaySize} of {totalChargeEventsCount} items</span>
                    </div>

                </div> */}
            </div>

        </>
    );
};


const SingleRow = ({ event, onItemCheck }) => {
    const { start, energy, meter_reading_start, duration } = event;


    return (
        <tr className={event.selected ? "selected" : ""}>

            <td className="eventID"><span>{start}</span></td>
            <td className="wallboxSerial"><span>{energy}</span></td>
            <td className="Date__Time"><span><time>{meter_reading_start}</time></span></td>
            <td className="duration"><span>{duration}</span></td>
        </tr>
    )
}

export default ChargeEventRow;