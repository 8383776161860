import React, { useEffect, useState } from "react";
import ster from "../../../images/icons/ster.png";
import full from "../../../images/icons/full.png";
import { Tab, Nav } from "react-bootstrap";
import PowerBrainActiRows from "./PowerBrainActivitiesRows"
import EventActiRows from "./EventActivitiesRows"
import Cookies from 'js-cookie'
import axios from "axios";


    const Activities = (props) => {

        document.title = "Events - POWERGrid Cloud"

        const [powerBrains, setPowerBrains] = useState([])
        const [pageCount, setPageCount] = useState(1)
        const [pageCountP, setPageCountP] = useState(1)
        const [currentPageE, setCurrentPageE] = useState(0)
        const [currentPageP, setCurrentPageP] = useState(1)
        const [isFetching, setIsFetching] = useState(false)
        const [totalDataCountE, setTotalDataCountE] = useState()
        const [totalDataCountP, setTotalDataCountP] = useState()
        const [searchValue, setSearchValue] = useState("")
        const [displaySize, setDisplaySize] = useState(20)
        const [sorting, setSorting] = useState("created_at")
        const [orderWay, setOrderWay] = useState("desc")
        const [isNotFound, setIfNotFound] = useState(false)
        const [events, setEvents] = useState([]);

        let cancelToken;

        if (typeof cancelToken != typeof undefined) {
            cancelToken.cancel("Cancelling the previous req")
        }

        cancelToken = axios.CancelToken.source()

        const onOrderChange = () => {
            if (orderWay === "desc") {
                setOrderWay("asc")
            } else {
                setOrderWay("desc")
            }
        }
        function fetchEvents () {
            axios.get(`http://116.203.191.150:3000/get_events?limit=${displaySize}&order=timestamp.${orderWay}&limit=${displaySize}&offset=${currentPageE * displaySize}&${searchValue && `ID=ilike.*${searchValue}`}`,
                {
                    headers: {
                        "Prefer": "count=exact"
                    }
                },
                {
                    cancelToken: cancelToken.token
                }
            )
                .then(response => {
                    if (response.headers) {
                        try {
                            let totalItems = response?.headers['content-range']?.split("-")[1]
                            totalItems = totalItems.split("/")[1]
                            setPageCount(Math.ceil(totalItems / displaySize))
                            setTotalDataCountE(totalItems)
                        } catch (error) {
                        }

                    }
                    const events = response.data
                    if (!events.length) {
                        setIfNotFound(true)
                    } else {
                        setIfNotFound(false)
                    }

                    if (events) {
                        events.forEach(element => {
                            element.selected = false
                        });
                        setEvents(events)
                    }
                })
                .catch(error => {
                    if (currentPageE !== 0) {
                        setCurrentPageE(0)
                    }
                    console.error("error found", error)
                })
                .then(() => {
                    setIsFetching(false)
                })
        }
        function fetchPowerBrain (){
            setIsFetching(true)
            axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/box_powergrid_events?order=${sorting}&order_way=${orderWay}&page=${currentPageP}&page_size=${displaySize}&serial=${props.id}`, {
                headers: {
          Authorization: "Bearer " + Cookies.get("access_token"),
        },
            },{
                cancelToken: cancelToken.token
            })
                .then(response => {
                    const events = response.data.data
                    if (events && response.data.total_count) {
                        events.forEach(element => {
                            element.selected = false
                        });
                        setTotalDataCountP(response.data.total_count)
                        setPageCountP(Math.ceil(response.data.total_count / displaySize))
                        setPowerBrains(events)
                    }
                    if (events.length === 0 && currentPageP !== 1) {
                        setCurrentPageP(1)
                    }
                })
                .catch(error => {
                    console.error("error found", error)
                })
                .then(() => {
                    setIsFetching(false)
                })
        }

        useEffect(() => {
            fetchPowerBrain();
            fetchEvents();
        }, [currentPageE, currentPageP, sorting, orderWay, searchValue, displaySize])

        return (
            <>

                <div className='activity_box'>
                    {/* <ul className="activity_LL">
                        <li className="active"><Link to='#'><img src={ster} /> All Events</Link></li>
                        <li><Link to='#'><img src={full} /> Charging Events</Link></li>
                    </ul> */}
                    <Tab.Container defaultActiveKey="first">
                        <Nav variant="pills" className="activity_LL">
                                <Nav.Item>
                                    <Nav.Link eventKey="first"><img alt="" src={ster} /> All Events</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second"><img alt="" src={full} /> Powerbrain Events</Nav.Link>
                                </Nav.Item>
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                                <div className="wallbox-tabl">
                                    {
                                        isNotFound && !isFetching ?
                                        <h5 className="text-center mt-5">Result Not found...</h5>
                                        :
                                        <EventActiRows
                                            events={events}
                                            pageCount={pageCount}
                                            currentPageE={currentPageE}
                                            setCurrentPageE={setCurrentPageE}
                                            isFetching={isFetching}
                                            totalDataCountE={totalDataCountE}
                                            displaySize={displaySize}
                                        />
                                        }
                                    </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                {/* <main className="wrapper">
                                    <div className="page-content">
                                        <div className="wallbox-area"> */}
                                        <div className="wallbox-tabl">
                                        {
                                        isNotFound && !isFetching ?
                                        <h5 className="text-center mt-5">Result Not found...</h5>
                                        :
                                            <PowerBrainActiRows
                                                events={powerBrains}
                                                pageCountP={pageCountP}
                                                setPageCountP={setPageCountP}
                                                currentPageP={currentPageP}
                                                setCurrentPageP={setCurrentPageP}
                                                isFetching={isFetching}
                                                totalDataCountP={totalDataCountP}
                                                setTotalDataCountP={setTotalDataCountP}
                                                displaySize={displaySize}
                                            />
    }
                                        </div>
                                        {/* </div>
                                    </div>
                                </main> */}
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>

                    {/* <div className="pagination-content">
                        <ul>
                            <li>1</li>
                            <li className="active">2</li>
                            <li>3</li>
                            <li>4</li>
                            <li><BsChevronDoubleRight /></li>
                        </ul>
                        <div className="pageRight">
                            <span>Showing 20 of 320 items</span>
                        </div>
                    </div> */}
                </div>


            </>
        );
    };
// }

export default Activities;