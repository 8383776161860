import React, { useState } from 'react';
import search from "../../../images/icons/search.png"
import events from "../../../images/icons/events.png"
import update from "../../../images/icons/update.png"
import { BsChevronDoubleRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import { TailSpin } from 'react-loader-spinner';


const AddGroupForm = ({ isFetcing, totalWallBoxCount, pageCount, currentPage, setCurrentPage, allWallBoxes, setAllWallBoxes, onOrderChange, orderWay, displaySize, onItemCheck, onMasterCheck}) => {
    const [masterChecked, setMasterChecked] = useState(false)
    const [selectedList, setSelectedList] = useState([])

    const getSelectedRows = () => {
        setSelectedList(allWallBoxes.filter((e) => e.selected))
    }

    return (
        <div className="table-componet">
            <div className="row">
                <div className="col-md-12">
                    <div className="tab__content">
                        <table className="table box-table">
                            <thead>
                                <tr>
                                    <th scope="col">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={masterChecked}
                                            id="mastercheck"
                                            onChange={(e) => onMasterCheck(e)}
                                        />
                                    </th>
                                    <th scope="col">ID</th>

                                    <th scope="col" className="serialT">Wallbox Serial</th>

                                    <th scope="col" className="FirmwareT">Firmware Version</th>

                                    <th scope="col" className="id">
                                        <span className="id">Date installed</span>
                                    </th>

                                    <th scope="col" className="functionsT">Functions </th>
                                    <th scope="col" className="ownerT">Owner</th>
                                    <th scope="col" className="statusT">Status</th>
                                    <th scope="col" className="actionsT">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    allWallBoxes.length && !isFetcing ? allWallBoxes.map((wallbox, index) => (
                                        <SingleRow onItemCheck={onItemCheck} key={index} wallBox={wallbox} />
                                    ))
                                        :
                                        <tr>
                                            <td>
                                                <div className="router-loading-spinner">
                                                    <TailSpin width={50} height={50} color="#002060" />
                                                </div>
                                            </td>
                                        </tr>
                                }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="pagination-content">
                <ul>
                    {
                        [...Array(pageCount).keys()].map((avc, index) => <li key={index} className={currentPage === avc + 1 ? "active" : undefined} onClick={() => setCurrentPage(avc + 1)}>{avc + 1}</li>)
                    }
                    {
                        currentPage !== pageCount && <li onClick={() => setCurrentPage(currentPage < pageCount ? currentPage + 1 : currentPage)}><BsChevronDoubleRight /></li>
                    }

                </ul>
                <div className="pageRight">
                    <span>Showing {allWallBoxes?.length} of {totalWallBoxCount} items</span>
                </div>
            </div>
        </div>
    );
};


const SingleRow = ({ onItemCheck, wallBox }) => {

    const { id, serial_number, firmware_version, created_at, owner, status, functions_list } = wallBox



    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }


    let functionList = functions_list ? functions_list.split(",") : []


    return (
        <tr className={wallBox.selected ? "selected" : ""}>
            <th scope="row">
                <input
                    type="checkbox"
                    checked={wallBox.selected}
                    className="form-check-input"
                    id="rowcheck{user.id}"
                    onChange={(e) => onItemCheck(e, wallBox)}
                />
            </th>
            <td><span>{id}</span></td>
            <td className="wallboxSerial"><span><Link to={`/wallbox/${serial_number}`}>{serial_number}</Link></span></td>
            <td className="FirmwareVersion"><span>v {firmware_version}</span></td>
            <td className="Dateinstalled"><span>{created_at}</span></td>
            <td className="Functions">
                <span>
                    {
                        functionList.map((func, index) => <button key={index} className={func}>{func}</button>)
                    }

                </span>
            </td>
            <td className="Owner"><span>{owner || "Unknown User"}</span></td>
            <td className="Stat"><span className={status}>{capitalizeFirstLetter(status)}</span></td>
            <td className="Actions">
                <span>
                    <button><img src={events} alt="" /> Events</button>
                    <button><img src={update} alt="" /> Update</button>
                    <button><img src={search} alt="" /></button>
                </span>
            </td>
        </tr>
    )
}


export default AddGroupForm;