import React, { useState, useEffect } from "react";
import NavBar from "../NavBar/NavBar";
import axios from "axios";
import AddGroupForm from "./AddGroupForm/AddGroupForm";
import Cookies from "js-cookie";
import "./AddGroup.scss";
import { AiOutlineSearch } from "react-icons/ai";
import { useHistory, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

const AddGroup = () => {
  const [isFetcing, setIsFetching] = useState(true);
  const [totalWallBoxCount, setTotalWallBoxCount] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [displaySize, setDisplaySize] = useState(20);
  const [allWallBoxes, setAllWallBoxes] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [isNotFound, setIfNotFound] = useState(false);

  const [masterChecked, setMasterChecked] = useState(false);
  const [selectedList, setSelectedList] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedWallbox, setSelectedWallbox] = useState([]);
  const [message, setMessage] = useState("")

  const history = useHistory();
  const getGroupsList = () => {
    history.push("/group");
  };

  const location = useLocation();
  const groupListState = location.state;

  const handleFormOnSubmit = (e) => {
    e.preventDefault();
  };

  let cancelToken;

  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel("Cancelling the previous req");
  }

  cancelToken = axios.CancelToken.source();

  const getGroupDataById = (id, wallboxes) => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_API_URL}/group/${id}`, {
        headers: {
          Authorization: "Bearer " + Cookies.get("access_token"),
        },
      })
      .then(function (response) {
        if (response?.data.data) {
          const data = response?.data?.data;
          const wallboxList = data?.wallbox_list;
          setSelectedWallbox(data?.wallbox_list);
          setGroupName(data?.name);
          wallboxes.map((wallbox) => {
            if (wallboxList.includes(wallbox.id)) {
              wallbox.selected = wallboxList.includes(wallbox.id);
            }
            return wallbox;
          });
          setAllWallBoxes(wallboxes);
          setSelectedList(allWallBoxes.filter((e) => e.selected));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const addGroups = (id) => {
    if (isUpdate) {
      const updatedList = selectedWallbox.filter(wallbox => wallbox != null)
      const data = {
        name: groupName,
        wallbox_id: updatedList,
      };
      axios
        .put(
          `${process.env.REACT_APP_BACKEND_API_URL}/group/${groupListState.GroupId}`,
          data,
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("access_token"),
            },
          }
        )
        .then(function (response) {
          if (response.data.success) {
            getGroupsList();
            toast.success('Group Updated Successfuly', {autoClose:3000})
          }else{
            setMessage(response.data.message);
            toast.error(message, {autoClose:3000})
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      const idList = selectedList.map((selectedObj) => {
        return selectedObj.id;
      });

      const data = {
        name: groupName,
        wallbox_id: idList,
      };
      axios
        .post(`${process.env.REACT_APP_BACKEND_API_URL}/group`, data, {
          headers: {
            Authorization: "Bearer " + Cookies.get("access_token"),
          },
        })
        .then(function (response) {
          if (response.data.success) {
            getGroupsList();
            toast.success('Group Added Successfuly', {autoClose:3000})
          }else{
            setMessage(response.data.message);
            toast.error(message, {autoClose:3000})
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const getWallboxData = () => {
    /*  http://116.203.191.150:5013 */
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_API_URL}/wallbox_info?page=${currentPage}&page_size=${displaySize}&search_string=${searchValue}`,
        {
          headers: {
            Authorization: "Bearer " + Cookies.get("access_token"),
          },
        }
      )
      .then((response) => {
        const data = response?.data;
        if (data?.success){
          const walloxes = data?.data?.data;
          const totalWallbox = data?.data?.total_count;
          if (totalWallBoxCount) {
            setTotalWallBoxCount(totalWallbox);
            setPageCount(Math.ceil(totalWallbox / displaySize));
          }

          if (walloxes) {
            setIsFetching(false);
            walloxes.forEach((element) => {
              element.selected = false;
            });
            setAllWallBoxes(walloxes);
            setIsUpdate(groupListState?.update ? true : false);
            if (groupListState?.update) {
              getGroupDataById(groupListState.GroupId, walloxes);
            }
          }

          if (walloxes.length === 0 && currentPage !== 1) {
            setCurrentPage(1);
          }

          if (totalWallbox == null) {
            setIfNotFound(true);
          } else {
            setIfNotFound(false);
          }
        } else {
          setAllWallBoxes([])
          setIfNotFound(true)
          setIsFetching(false)  
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onItemCheck = (e, item) => {
    let tempList = allWallBoxes;
    tempList.map((user) => {
      if (user?.id === item?.id) {
        user.selected = e.target.checked;
      }
      return user;
    });
    if (!e.target.checked){
      var index = selectedWallbox.indexOf(item.id);
      delete selectedWallbox[index];
    } else {
      setSelectedWallbox(wallbox => [...wallbox, item.id])
    }


    const totalItems = allWallBoxes.length;
    const totalCheckedItems = tempList.filter((e) => e.selected).length;

    setMasterChecked(totalItems === totalCheckedItems);
    setAllWallBoxes(tempList);
    setSelectedList(allWallBoxes.filter((e) => e.selected));
  };

  const onMasterCheck = (e) => {
    let tempList = allWallBoxes;

    tempList.map((user) => (user.selected = e.target.checked));

    setMasterChecked(e.target.checked);
    setAllWallBoxes(tempList);
    setSelectedList(allWallBoxes.filter((e) => e.selected));
  };

  useEffect(() => {
    setIsFetching(true);
    getWallboxData();
  }, []);

  useEffect(() => {
    setIsFetching(true);
    getWallboxData();
  }, [currentPage]);

  return (
    <>
      <NavBar />
      <main className="wrapper">
        <div className="page-content">
          <div className="wallbox-area">
            <div className="pages-title-list">
              {isUpdate ? <ul style={{marginBottom: "10px"}}>
                <li>
                    <span>
                        <Link to="/group">
                        Groups
                        </Link>
                    </span>
                </li>
                <li>
                    <span>Update Groups</span>
                </li>
              </ul> : <ul style={{marginBottom: "10px"}}>
                <li>
                    <span>
                        <Link to="/group">
                        Groups
                        </Link>
                    </span>
                </li>
                <li>
                    <span>Create Groups</span>
                </li>
              </ul>}
              
              <span>Select wallbox to add into Group</span>
            </div>
            <div className="search-filter">
              <form onSubmit={handleFormOnSubmit}>
                <input
                  onChange={(e) => setSearchValue(e.target.value)}
                  type="text"
                  placeholder="Search Wallboxes"
                />
                <button type="submit">
                  <AiOutlineSearch />
                </button>
              </form>
              <div className="sort-date">
                <form>
                  <input
                    type="text"
                    placeholder="Enter Group Name"
                    value={groupName}
                    onChange={(e) => setGroupName(e.target.value)}
                  />
                </form>
              </div>
              {isUpdate ? (
                <div style={{ width: "120px" }} className="serialT">
                  <button className="btn-yellpow1" onClick={addGroups}>
                    Update
                  </button>
                </div>
              ) : (
                <div style={{ width: "120px" }} className="serialT">
                  <button className="btn-yellpow1" onClick={addGroups}>
                    Submit
                  </button>
                </div>
              )}
            </div>
            <div className="wallbox-tabl">
              {isNotFound && !isFetcing ? (
                <h5 h5 className="text-center mt-5">
                  Result Not found...
                </h5>
              ) : (
                <AddGroupForm
                  isFetcing={isFetcing}
                  setIsFetching={setIsFetching}
                  totalWallBoxCount={totalWallBoxCount}
                  setTotalWallBoxCount={setTotalWallBoxCount}
                  pageCount={pageCount}
                  setPageCount={setPageCount}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  allWallBoxes={allWallBoxes}
                  setAllWallBoxes={setAllWallBoxes}
                  displaySize={displaySize}
                  onItemCheck={onItemCheck}
                  onMasterCheck={onMasterCheck}
                />
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default AddGroup;