import React, { useEffect, useState } from "react";
import NavBar from "../NavBar/NavBar";
import { FaAngleLeft } from "react-icons/fa";
import addRole from "../../images/icons/addRole.png";
import reset from "../../images/icons/update.png";
import billing from "../../images/icons/billing.png";
import Delete from "../../images/icons/delete.png";
import pp1 from "../../images//pp1.png";
import Cookies from 'js-cookie'


import UserWallbox from "./UserSingle/UserWallbox";
import AllActivities from "./UserSingle/AllActivities";

import { Link } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";
import { useParams } from "react-router-dom";
import axios from "axios";
import { TailSpin } from "react-loader-spinner";
import { useHistory } from "react-router-dom";
import MyAddRoleModal from "./UserSingle/AddRoleToUserModal";




const UserSingle = () => {
  document.title = "User - POWERGrid Cloud"

  const [modalShow, setModalShow] = React.useState(false);
  const [user, setUser] = useState({})
  const params = useParams()
  const { userID } = params


  useEffect(() => {
    if (userID) {
      axios.get(`${process.env.REACT_APP_USER_BACKEND_API_URL}/users/single_user/${userID}`, {
        headers: {
          authorization: "Bearer " + Cookies.get("access_token")
        }
      })
        .then(response => {
          if (response.data) {
            setUser(response.data[0])
          }
        })
        .catch(error => {
          console.error("Got Error", error)
        })
    }
  }, [userID])

  const history = useHistory()

  const { FullName, Phone, Email, Confirmed } = user;

  const handleDelete = (e, Email) => {
    e.preventDefault()
    const confirm = window.confirm("Do you want to delete " + Email)
    const signUpInfo = new URLSearchParams(Object.entries({
      email: Email
    })).toString();
    if (confirm) {
      fetch(`${process.env.REACT_APP_USER_BACKEND_API_URL}/users`, {
        method: "DELETE",
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          authorization: "Bearer " + Cookies.get("access_token")
        },
        body: signUpInfo
      })
        .then(res => res.json())
        .then(data => {
          if (data === `User with the Email '${Email}' was deleted successfully`) {
            setTimeout(() => history.push("/users"), 1000)
          }
          window.alert(data)
        })
    }
  }

  const handleReset = (e, Email) => {
    e.preventDefault()
    const askToConfirm = window.confirm(`Are you sure want to Reset password of ${Email}`)
    if (askToConfirm) {

      const resetPasswordInfo = new URLSearchParams(Object.entries({
        Email: Email
      })).toString();
      axios.post(`${process.env.REACT_APP_USER_BACKEND_API_URL}/users/forgot_password`, resetPasswordInfo, {
        headers: {
          Authorization: "Bearer " + Cookies.get("access_token")
        }
      })
        .then(function (response) {
          window.alert(response.data)
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }

  return (
    <>
      <NavBar />
      <main className="wrapper">
        <div className="page-content">
          <div className="wallbox-area">
            <div className="pages-title-list">
              <ul>
                <li>
                  <Link to="/users">All Users</Link>
                </li>
                <li>
                  <span>{FullName}</span>
                </li>
              </ul>
            </div>

            <div className="wallbox_single_content">
              <div className="single_all_list">
                <ul className="box_progress">
                  <li>
                    <Link to="/users">
                      <FaAngleLeft /> Back to all users
                    </Link>
                  </li>
                  <li>
                    <span>Users Functions</span>
                  </li>
                  <li>
                    <Link onClick={(e) => handleReset(e, Email)} to="#">
                      <img alt="" src={reset} /> Reset Password
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => setModalShow(true)} to="#">
                      <img alt="" src={addRole} /> Add Role to User
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <img alt="" src={billing} /> Billing Details
                    </Link>
                  </li>
                  <li>
                    <Link onClick={(e) => handleDelete(e, Email)} to="#">
                      <img alt="" src={Delete} /> Delete User
                    </Link>
                  </li>
                </ul>
                <ul className="box_progress">
                  {/* <li>Wallbox Progress</li> */}
                </ul>
              </div>
              <div className="details_cont" style={{ position: "relative" }}>
                {!user.Email ? <div className="router-loading-spinner">
                  <TailSpin width={25} height={25} color="#002060" />
                </div> :
                  <div className="wallbox_details">
                    <div className="h5">User Details</div>
                    <div className="wallbox_details_content">
                      <form action="">
                        <div className="box_group">
                          <label>Name</label>
                          <input type="text" defaultValue={FullName} readOnly />
                        </div>
                        <div className="box_group">
                          <label> Phone </label>
                          <input type="text" defaultValue={Phone} readOnly />
                        </div>
                        <div className="box_group">
                          <label>E-mail</label>
                          <input type="text" defaultValue={Email} readOnly />
                        </div>
                        <div className="box_group">
                          <label>Address</label>
                          <input type="text" defaultValue="" readOnly />
                        </div>
                        <div className="box_group">
                          <label>Company Name</label>
                          <input type="text" defaultValue="" readOnly />
                        </div>
                        <div className="box_group">
                          <label>Company Address</label>
                          <input
                            type="text"
                            defaultValue=""
                            readOnly
                          />
                        </div>
                      </form>
                      <div className="box_right_img">
                        <img alt="" src={pp1} />
                        <span>Status</span>
                        <span className={Confirmed === "active" ? "Running" : "inactive"}>{Confirmed}</span>
                      </div>
                    </div>
                  </div>
                }
                <div className="user_wallbox">
                  <div className="h5">Roles in Environments</div>
                  <form action="">
                    <input type="search" placeholder="Search Product..." />
                    <button type="submit">
                      <AiOutlineSearch />
                    </button>
                  </form>
                  <UserWallbox />
                </div>
              </div>
              <div className="allActivities">
                <AllActivities />
              </div>
            </div>
          </div>
        </div>
      </main>
      <MyAddRoleModal
        Email={Email}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default UserSingle;
