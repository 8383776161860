import React, { useEffect, useState } from "react";

import NavBar from "../../NavBar/NavBar";
import { AiOutlineSearch } from "react-icons/ai";
import Cookies from 'js-cookie'

// import "./Events.scss"
import { Link } from "react-router-dom";
// import EventRows from "./EventsRows";
import axios from "axios";


const Serial2Sim = () => {
    document.title = "Sim2Serial - POWERGrid Cloud"

    const [simNumber, setSimNumber] = useState("")
    const [events, setEvents] = useState([])
    const [currentEmail, setCurrentEmail] = useState("")

    function fetchData(){
        axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/serial_wallbox?sim=${simNumber}`,
            {
                headers: {
          Authorization: "Bearer " + Cookies.get("access_token"),
        },
            }
        )
        .then(response => {
            setEvents(response.data.serial_number)
        })
            .catch(error => {
                console.error("error found", error)
            })
    }


    useEffect(() => {
        fetchData()
        setCurrentEmail(Cookies.get("user_email"))
    },[])

    const submitForm = (e) => {
        e.preventDefault()
        fetchData()
    }

    return (
        <>
            <NavBar />
            <main className="wrapper">
                <div className="page-content">
                    <div className="wallbox-area">
                        <div className="pages-title">
                            <div className="h2">All Documents</div>
                            <span>View all documents</span>
                        </div>
                        <div className="user__content">
                            <div className="user__data">
                                <ul className="panel_section">
                                    <li>
                                        <span>APIs Selection</span>
                                    </li>
                                    <li>
                                        <Link to="/docs/sim2serial" className="active"> Sim2Serial</Link>
                                    </li>
                                    <li>
                                        <Link to="/docs/serial2sim" > Serial2Sim</Link>
                                    </li>
                                    <li>
                                        <Link to="/docs/Serial2Code" > Serial2Code</Link>
                                    </li>
                                    <li>
                                        <Link to="/docs/Code2Serial">Code2Serial</Link>
                                    </li>
                                    {
                                        currentEmail === "christoph.zillner@powerjames.com" || currentEmail === "tarunsengar1987@gmail.com" ?
                                        <li>
                                        <Link to="/docs/syncWallbox">Sync Wallbox</Link>
                                        </li>
                                        :
                                        null
                                    }
                                </ul>
                            </div>
                            <div className="user__data">
                                <div className="mt-5 mr-5">
                                    <h6 className="text-left mt-5">Get All Serial Number by Sim Number</h6>
                                </div>

                                <div className="search-filter">
                                <form onSubmit={submitForm} action="">
                                    <input type="text" onChange={e => setSimNumber(e.target.value)} placeholder="Search By Sim Number" />
                                    <button type="submit"><AiOutlineSearch /></button>
                                    </form>
                                    
                                </div>
                                <div className="wallbox-tabl">
                                    {       
                                        events?.length < 1 ?
                                            <h5 className="text-center mt-5">Result Not found...</h5>
                                        :
                                        <div className="table-componet">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="tab__content">
                                                    <table className="table box-table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">ID</th>
                                                                <th scope="col" style={{paddingLeft:"150px"}}>Wallbox Serial</th>
                                                                <th scope="col" style={{paddingRight:"300px"}}>Count</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>{
                                                            
                                                            events?.map((data_dict, index) => {
                                                                return(
                                                                    <tr key={index}>
                                                                        <td>{index+1}</td>
                                                                        <td style={{paddingLeft:"150px"}}>{data_dict?.serial}</td>
                                                                        <td style={{paddingRight:"300px"}}>{data_dict?.count}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                            }
                            
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </main>
        </>
    );
};


export default Serial2Sim;
